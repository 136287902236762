/*
 * @Author: zhuchaowei 952698119@qq.com
 * @Date: 2022-06-27 16:07:07
 * @LastEditors: zhuchaowei 952698119@qq.com
 * @LastEditTime: 2022-06-29 14:18:31
 * @FilePath: \platform-pc-front\src\router\modules\BigDataCenter.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/**
 * 大数据中心路由
 */
/**
 * 大屏展示
 */
export const BigScreenDisplay = {
  path: '/BigScreenDisplay',
  name: 'BigScreenDisplay',
  component: () => import('@/views/BigScreenDisplay'),
  meta: {
    label: '大屏展示',
    permId: 'M18',
   //  ignoreKeepAlive:true,
    icon: [
      'icon-dapingzhanshi',
      require('@/assets/images/header/大屏展示.png')
    ],
    affix: true,
  }
};

/**
 * 大屏展示-在线设备
 */
export const BigScreenDisplayEquipment = {
  path: '/BigScreenDisplayEquipment',
  name: 'BigScreenDisplayEquipment',
  component: () => import('@/views/BigScreenDisplay/BigScreenDisplayEquipment'),
  meta: {
    label: '大屏展示',
    permId: 'M18',
   //  ignoreKeepAlive:true,
    icon: [
      'icon-dapingzhanshi',
      require('@/assets/images/header/大屏展示.png')
    ],
    affix: true,
    isShow: false,
  }
};

/**
 * 大屏展示-机械
 */
export const BigScreenDisplayMechanical = {
  path: '/BigScreenDisplayMechanical',
  name: 'BigScreenDisplayMechanical',
  component: () => import('@/views/BigScreenDisplay/BigScreenDisplayMechanical'),
  meta: {
    label: '大屏展示',
    permId: 'M18',
   //  ignoreKeepAlive:true,
    icon: [
      'icon-dapingzhanshi',
      require('@/assets/images/header/大屏展示.png')
    ],
    affix: true,
    isShow: false,
  }
};

/**
 * 大屏展示-保单
 */
export const BigScreenDisplayPolicy = {
  path: '/BigScreenDisplayPolicy',
  name: 'BigScreenDisplayPolicy',
  component: () => import('@/views/BigScreenDisplay/BigScreenDisplayPolicy'),
  meta: {
    label: '大屏展示',
    permId: 'M18',
   //  ignoreKeepAlive:true,
    icon: [
      'icon-dapingzhanshi',
      require('@/assets/images/header/大屏展示.png')
    ],
    affix: true,
    isShow: false,
  }
};

/**
 * 大屏展示-服务
 */
export const BigScrennDisplayService = {
  path: '/BigScrennDisplayService',
  name: 'BigScrennDisplayService',
  component: () => import('@/views/BigScreenDisplay/BigScrennDisplayService'),
  meta: {
    label: '大屏展示',
    permId: 'M18',
   //  ignoreKeepAlive:true,
    icon: [
      'icon-dapingzhanshi',
      require('@/assets/images/header/大屏展示.png')
    ],
    affix: true,
    isShow: false,
  }
};

/**
 * 大屏展示-风控
 */
export const BigScreenDisplayRisk = {
  path: '/BigScreenDisplayRisk',
  name: 'BigScreenDisplayRisk',
  component: () => import('@/views/BigScreenDisplay/BigScreenDisplayRisk'),
  meta: {
    label: '大屏展示',
    permId: 'M18',
   //  ignoreKeepAlive:true,
    icon: [
      'icon-dapingzhanshi',
      require('@/assets/images/header/大屏展示.png')
    ],
    affix: true,
    isShow: false,
  }
};

/**
 * 大屏展示-风控-视频
 */
export const BigScreenDisplayRiskVideo = {
  path: '/BigScreenDisplayRiskVideo',
  name: 'BigScreenDisplayRiskVideo',
  component: () => import('@/views/BigScreenDisplay/BigScreenDisplayRiskVideo'),
  meta: {
    label: '大屏展示',
    permId: 'M18',
   //  ignoreKeepAlive:true,
    icon: [
      'icon-dapingzhanshi',
      require('@/assets/images/header/大屏展示.png')
    ],
    affix: true,
    isShow: false,
  }
};

/**
 * 大屏展示-保险产品
 */
export const BigScreenDisplayProducts = {
  path: '/BigScreenDisplayProducts',
  name: 'BigScreenDisplayProducts',
  component: () => import('@/views/BigScreenDisplay/BigScreenDisplayProducts'),
  meta: {
    label: '大屏展示',
    permId: 'M18',
   //  ignoreKeepAlive:true,
    icon: [
      'icon-dapingzhanshi',
      require('@/assets/images/header/大屏展示.png')
    ],
    affix: true,
    isShow: false,
  }
};

/**
 * 大屏展示-机械设备
 */
export const BigScreenDisplayNewMechanical = {
  path: '/BigScreenDisplayNewMechanical',
  name: 'BigScreenDisplayNewMechanical',
  component: () => import('@/views/BigScreenDisplay/BigScreenDisplayNewMechanical'),
  meta: {
    label: '大屏展示',
    permId: 'M18',
   //  ignoreKeepAlive:true,
    icon: [
      'icon-dapingzhanshi',
      require('@/assets/images/header/大屏展示.png')
    ],
    affix: true,
    isShow: false,
  }
};

/**
 * 大屏展示-客户信息
 */
export const BigScreenDisplayCustomerInfo = {
  path: '/BigScreenDisplayCustomerInfo',
  name: 'BigScreenDisplayCustomerInfo',
  component: () => import('@/views/BigScreenDisplay/BigScreenDisplayCustomerInfo'),
  meta: {
    label: '大屏展示',
    permId: 'M18',
   //  ignoreKeepAlive:true,
    icon: [
      'icon-dapingzhanshi',
      require('@/assets/images/header/大屏展示.png')
    ],
    affix: true,
    isShow: false,
  }
};

/**
 * 大屏展示-设备信息
 */
export const BigScreenDisplayEquipmentInfo = {
  path: '/BigScreenDisplayEquipmentInfo',
  name: 'BigScreenDisplayEquipmentInfo',
  component: () => import('@/views/BigScreenDisplay/BigScreenDisplayEquipmentInfo'),
  meta: {
    label: '大屏展示',
    permId: 'M18',
   //  ignoreKeepAlive:true,
    icon: [
      'icon-dapingzhanshi',
      require('@/assets/images/header/大屏展示.png')
    ],
    affix: true,
    isShow: false,
  }
};

/**
 * 大屏展示-设备档案
 */
export const BigScreenDisplayEquipmentInfoRecord = {
  path: '/BigScreenDisplayEquipmentInfoRecord',
  name: 'BigScreenDisplayEquipmentInfoRecord',
  component: () => import('@/views/BigScreenDisplay/BigScreenDisplayEquipmentInfoRecord'),
  meta: {
    label: '大屏展示',
    permId: 'M18',
   //  ignoreKeepAlive:true,
    icon: [
      'icon-dapingzhanshi',
      require('@/assets/images/header/大屏展示.png')
    ],
    affix: true,
    isShow: false,
  }
};

/**
 * 大屏展示-合作保司
 */
export const BigScreenDisplayCooperation = {
  path: '/BigScreenDisplayCooperation',
  name: 'BigScreenDisplayCooperation',
  component: () => import('@/views/BigScreenDisplay/BigScreenDisplayCooperation'),
  meta: {
    label: '大屏展示',
    permId: 'M18',
   //  ignoreKeepAlive:true,
    icon: [
      'icon-dapingzhanshi',
      require('@/assets/images/header/大屏展示.png')
    ],
    affix: true,
    isShow: false,
  }
};

/**
 * 大屏展示-客户档案
 */
export const BigScreenDisplayCustomerRecord = {
  path: '/BigScreenDisplayCustomerRecord',
  name: 'BigScreenDisplayCustomerRecord',
  component: () => import('@/views/BigScreenDisplay/BigScreenDisplayCustomerRecord'),
  meta: {
    label: '大屏展示',
    permId: 'M18',
   //  ignoreKeepAlive:true,
    icon: [
      'icon-dapingzhanshi',
      require('@/assets/images/header/大屏展示.png')
    ],
    affix: true,
    isShow: false,
  }
};

/**
 * 商车布控地图
 */
export const SCDeploymentControl = {
  path: '/SCDeploymentControl',
  name: 'SCDeploymentControl',
  component: () => import('@/views/OperationBigScreen/DeploymentControl/index'),
  meta: {
    label: '商车监控(布控地图)',
    permId: 'M18-OperationBigScreen',
    icon: ['icon-dapingzhanshi'],
    affix: true,
    isShow: false,
  },
};

/**
 * 商车事件地图
 */
export const SCMapEvents = {
  path: '/SCMapEvents',
  name: 'SCMapEvents',
  component: () => import('@/views/OperationBigScreen/MapEvents/index'),
  meta: {
    label: '商车监控(事件地图)',
    permId: 'M18-OperationBigScreen',
    icon: ['icon-dapingzhanshi'],
    affix: true,
    isShow: false,
  },
};

/**
 * 运维监控
 */
export const projectHall = {
  path: '/SCProjectHall',
  name: 'SCProjectHall',
  component: () => import('@/views/OperationBigScreen/projectHall/index'),
  meta: {
    label: '运维监控',
    permId: 'M18-OperationBigScreen',
   //  ignoreKeepAlive:true,
    icon: [
      'icon-dapingzhanshi',
      require('@/assets/images/header/大屏展示.png')
    ],
    affix: true,
  }
};

/**
 * 运维监控项目配置
 */
export const maintenanceConfiguration = {
  path: '/maintenanceConfiguration',
  name: 'maintenanceConfiguration',
  component: () => import('@/views/OperationBigScreen/maintenanceConfiguration/index'),
  meta: {
    label: '数据配置',
    permId: 'M18-OperationBigScreen',
    icon: ['icon-dapingzhanshi'],
    affix: true,
    isShow: false,
  },
};


