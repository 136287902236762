export const StandardAudit = {
  path: '/StandardAudit',
  name: 'StandardAudit',
  component: () => import('@/views/AuditManagement/StandardAudit'),
  meta: {
    label: '验标审核',
    permId: 'M27-standard',
    icon: 'icon-a-yonghuguanli1',
  }
};
export const FixedAudit = {
  path: '/FixedAudit',
  name: 'FixedAudit',
  component: () => import('@/views/AuditManagement/FixedAudit'),
  meta: {
    label: '定损审核',
    permId: 'M27-fixed',
    icon: 'icon-a-yonghuguanli1',
  }
};
export const SettlementAudit = {
  path: '/SettlementAudit',
  name: 'SettlementAudit',
  component: () => import('@/views/AuditManagement/SettlementAudit'),
  meta: {
    label: '理算审核',
    permId: 'M27-settlement',
    icon: 'icon-a-yonghuguanli1',
  },
};
export const ReopenAudit = {
  path: '/ReopenAudit',
  name: 'ReopenAudit',
  component: () => import('@/views/AuditManagement/ReopenAudit/index.vue'),
  meta: {
    label: '重开案件审核',
    permId: 'M27-reopen',
    icon: 'icon-a-yonghuguanli1',
  }
};