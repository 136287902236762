<template>
  <transition
    name="dialog-fade">
    <div
      v-if="visible"
      class="el-dialog__wrapper" style="z-index: 3001"
      @click.self="handleWrapperClick">
      <div
        role="dialog"
        :key="key"
        aria-modal="true"
        :aria-label="'dialog'"
        :class="['el-dialog', customClass]"
        ref="dialog"
        :style="style">
        <div class="el-dialog__body">
          <components :is="template" :message="message" @cancel="handleCancel" @save="handleSave" @operate="handleOperate" :title="title" />
          <!-- <window /> -->
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  // import Popup from 'element-ui/src/utils/popup';
  // import Migrating from 'element-ui/src/mixins/migrating';
  // import emitter from 'element-ui/src/mixins/emitter';
  import window from './window.vue';
  import alarmWindow from './alarmWindow.vue';

  export default {
    name: 'WinDialog',
    components: { window, alarmWindow },
    // mixins: [Popup, emitter, Migrating],
    data() {
      return {
        visible: true,
        closed: false,
        closeOnClickModal: false,
        customClass: '',
        title: '',
        message: '',
        template: 'window',
        style: {},
        key: 0
      };
    },

    watch: {
      visible(val) {
        if (val) {
        }
      }
    },

    computed: {
    },

    methods: {
      handleCancel() {
        this.visible = false;
        // console.log('返回');
        // this.callback('12313')
      },
      handleSave() {
        this.visible = false;
        this.callback(true);
      },
      handleOperate() {
        this.operate(true);
      },
      handleWrapperClick() {
        console.log('点击蒙层');
        if (this.closeOnClickModal) {
          this.handleCancel();
        }
      }
    },

    mounted() {
    },

    destroyed() {
    }
  };
</script>
<style lang="scss" scoped>
.el-dialog {
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  margin: 0;
  // width: 827px;
  border-radius: 8px;
  overflow: hidden;
  background-color: transparent;
}
.el-dialog__wrapper {
  background-color: rgba(0 ,0 ,0 , 0.4);
}
.el-dialog__body {
  padding: 0;
  border-radius: 8px;
  overflow: hidden;
}
</style>
