/**
 * 封装命令接受,发送
 */

import cmdWebSocket from './cmdWebSocket';
import { uuid } from '@/utils/uuid';
import { isString, isFunction } from 'lodash';


class cmdRequest {
  constructor(config) {
    const { baseURL, username, userkey, open, close, register, heart, message } = config;
    // 用于验证用户是否登录
    this._userkey = userkey;
    // 发送命令的用户
    this._username = username;
    // 连接成功回调
    this._opencb = open;
    // 连接断开回调
    this._closecb = close;
    // 注册回调
    this._registercb = register;
    // 心跳回调
    this._heartcb = heart;
    // websocket消息回调
    this._messagecb = message;
    this._token = uuid();

    // 建立websocket连接
    this._request = new cmdWebSocket({
      wsid: 'cmd_real_info',
      token: this._token,
      baseURL,
      username,
      open: this._onRequestOpen.bind(this),
      close: this._onRequestClose.bind(this),
      heart: this._onRequestHeart.bind(this),
      message: this._onRequestMessage.bind(this)
    });

    // 消息回调函数Map Map<cmdType, Array<callback>>
    this._messageCallbackMap = new Map();
  }


  // 注册回调
  addMsg(cmdType = '*', msgCb = null) {

    // 如果没有指定cmdType类型, 将注册为全局的所有的事件, 即收到的任何消息都会触发此回调
    if (!isString(cmdType)) {
      msgCb = cmdType;
      cmdType = '*';
    }

    if (!isFunction(msgCb)) return;

    if (!this._messageCallbackMap.has(cmdType)) {
      this._messageCallbackMap.set(cmdType, []);
    }

    this._messageCallbackMap.get(cmdType).push(msgCb);
  }
  // 注销回调
  delMsg(cmdType = '*', msgCb = null) {

    if (!isString(cmdType)) {
      msgCb = cmdType;
      cmdType = '*';
    }

    if (!this._messageCallbackMap.has(cmdType)) return;

    if (!isFunction(msgCb)) {
      this._messageCallbackMap.delete(cmdType);
      return;
    }

    const msgCbs = this._messageCallbackMap.get(cmdType).filter(cb => cb !== msgCb);

    this._messageCallbackMap.set(cmdType, msgCbs);
  }
  // 清除所有回调
  clearMsg() {
    this._messageCallbackMap.clear();
  }
  // 销毁, 销毁后链路将不再可用
  destroy() {
    this._messageCallbackMap.clear();
    if (this._request) {
      this._request.destroy();
      this._request = null;
    }
  }


  /**
   * 发送命令, 后端只能单次回复
   */
  sendCmdAsync(cmdType, data) {
    return new Promise((resolve, reject) => {
      this.sendBuffer(cmdType, data, (res) => {
        resolve(res);
      });
    });
  }

  /**
   * 发送命令, 回调模式, 如果后端可分多次回复可用
   */
  sendBuffer(cmdType, data, cb) {
    this._request.sendBuffer(cmdType,
      {
        userName: this._username,
        ...data
      },
      res => {
        if (cb) {
          cb(res.data);
        }
      });
  }

  /**
   * 注册
   */
  register(params, cb) {
    this._request.sendBuffer(
      'register',
      params,
      res => {
        if (cb) cb(res.data);
      }
    );
  }

  /**
   * 心跳命令
   */
  cmdReqHeart(params, cb) {
    //心跳
    this._request.get('v3/cmdReqHeart.do', {
      params: { ...params }
    }, res => {
      if (cb) cb(res.data);
    });
  }

  _onRequestOpen(e) {
    if (e && e.flag == 1) {
      // websocket连接成功后, 发送注册指令
      this.register({
        param: {
          username: this._username,
          userkey: this._userkey
        }
      }, res => {
        if (this._registercb) this._registercb(res);
      });
    }
    if (this._opencb) this._opencb(e);
  }

  _onRequestClose(e) {
    if (this._closecb) this._closecb(e);
  }

  _onRequestMessage(data) {

    const { cmdType } = data;

    // 先把cmdType解析出来, 如果有注册改cmdType类型的事件, 则触发改事件回调
    if (cmdType
      && this._messageCallbackMap.has(cmdType)) {

      this._messageCallbackMap
        .get(cmdType)
        .forEach(cb => cb && cb(data));
    }

    // 触发所有类型事件
    if (this._messageCallbackMap.has('*')) {
      this._messageCallbackMap
        .get('*')
        .forEach(cb => cb && cb(data));
    }
  }

  _onRequestHeart() {
    this.sendBuffer('cmdReqHeart', {
      username: this._username,
      userkey: this._userkey
    });
    //if (this._heartcb) this._heartcb()

  }
}


export default cmdRequest;