/*
 * @Date: 2023-11-01 14:07:07
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/**
 * 外接页面展示中心路由
 */

/**
 * 风险减量监控 riskHomeManagement 回滚 oldRiskHomeManagement
 */
export const riskHomeManagement = {
  path: "/riskHomeManagement",
  name: "riskHomeManagement",
  // component: () =>
  //   import("@/views/RiskDecrement/riskHomeManagement/riskHomeManagement.vue"),
  component: () =>
    import("@/views/RiskDecrement/demoRiskHomeManagement/riskHomeManagement.vue"),
  meta: {
    label: "风险减量",
    permId: "FXJLJK",
    ignoreKeepAlive: true,
    icon: ["icon-dapingzhanshi", require("@/assets/images/header/风控.png")],
    affix: true,
  },
};
export const riskWorkTimes = {
  path: "/riskHomeManagement/riskWorkTimes",
  name: "riskWorkTimes",
  ignoreKeepAlive: true,
  component: () =>
    import("@/views/RiskDecrement/riskHomeManagement/subPages/workTimes.vue"),
  meta: {
    label: "工时分布",
    permId: "FXJLJK",
    icon: ["icon-dapingzhanshi", require("@/assets/images/header/风控.png")],
  },
};

export const caseList = {
  path: "/riskHomeManagement/caseList",
  name: "caseList",
  ignoreKeepAlive: true,
  component: () =>
    import(
      "@/views/RiskDecrement/riskHomeManagement/subPages/caseDataList.vue"
    ),
  meta: {
    label: "案件列表",
    permId: "FXJLJK",
    icon: ["icon-dapingzhanshi", require("@/assets/images/header/风控.png")],
  },
};

export const riskTrainList = {
  path: "/riskHomeManagement/riskTrainList",
  name: "riskTrainList",
  ignoreKeepAlive: true,
  component: () =>
    import(
      "@/views/RiskDecrement/demoRiskHomeManagement/subPages/riskTrainList.vue"
    ),
  meta: {
    label: "培训列表",
    permId: "FXJLJK",
    icon: ["icon-dapingzhanshi", require("@/assets/images/header/风控.png")],
  },
};

export const alarmInterveneRecord = {
  path: "/riskHomeManagement/alarmInterveneRecord",
  name: "alarmInterveneRecord",
  ignoreKeepAlive: true,
  component: () =>
    import(
      "@/views/RiskDecrement/riskHomeManagement/subPages/alarmInterveneRecord.vue"
    ),
  meta: {
    label: "告警列表",
    permId: "FXJLJK",
    icon: ["icon-dapingzhanshi", require("@/assets/images/header/风控.png")],
  },
};
