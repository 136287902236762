
class cmdWebSocket {
  constructor(config) {
    // 存储请求数组
    this._requestData = [];
    // setInterval 重连WebSocket标识符
    this._ith = null;
    // 请求超时检测
    this._ithot = null;
    // 是否有重连机制
    this._isReconnect = !config.hasOwnProperty('isReconnect') ? true : config.isReconnect;
    // websocket ID, 无实际意义
    this._wsid = config.wsid;
    // 链路地址
    this._baseURL = config.baseURL;
    // 用户名
    this._username = config.username;
    // websocket连接成功回调
    this._openCallback = config.open;
    // websocket连接断开回调
    this._closeCallback = config.close;
    // 心跳回调
    this._heartCallback = config.heart;
    // 接受websocket消息回调 WebSocket.onmessage
    this._messageCallback = config.message;
    // token
    this._token = config.token;
    // 重连次数, 重连次数超过5次, 将不再重连
    this._reconnectCount = 0;
    this.debug = false;
    this._connectWebSocket();
  }
  destroy() {
    if (this._ith) {
      clearInterval(this._ith);
    }
    if (this.connecting || this.connected) {
      console.log(`${this._wsid} ${this._baseURL} 主动断开`);
      this._ws.close();
      this._ws.onopen = null;
      this._ws.onclose = null;
      this._ws.onmessage = null;
      this._ws.onerror = null;
    }
    this._ws = null;
  }

  //     视频服务响应状态码(收到响应后根据session清除请求信息)：
  //     请求成功：1
  //     请求失败：0
  //     响应视频地址成功：2  //请求信息 不清除
  //     设备备没有响应，网关返回设备响应超时， 返回-1
  //    网关没有响应，服务器返回设备响应超时，返回-2
  //    设备录像查询，当前有用户在查，返回-3
  //    设备回放有其他高级级别用户再看，返回-4
  //     WebSocket未连接：-101
  //     请求超时：-102

  /**
   * 发起请求, 期望响应请求的并满足结构的可以调用这个接口
   */
  get(url, config, cb) {
    config.params.username = this._username;
    config.params.clientType = 1;
    config.params.token = this._token;
    config.params.session = this._uuid();
    config.timeout = config.timeout ? config.timeout : 1000 * 30;//默认超时时间
    let wssenttime = new Date().getTime(); //记录发出请求时间
    this._requestData.push({ url, config, wssenttime, cb });
    let data = url[0] === "/" ? url : "/" + url;
    if (config && config.params) {
      // data += "?" + encodeURIComponent(Object.entries(config.params).map(([key, val]) => `${key}=${val}`).join('&'))
      data += "?" + Object.entries(config.params).map(([key, val]) => `${key}=${val}`).join('&');
    }
    // console.log(`${this._wsid} send ${data}`);
    if (this.connected) {
      this._ws.send(data);
      return config.params.session;
    } else {
      this._wsResponse(config.params.session, { status: -101, info: this._baseURL + '未连接' });
      console.log(this._baseURL + '未连接');
    }
    return null;
  }

  /**
   * 用户下发指令, cmdType必须
   */
  sendBuffer(cmdType, data, cb) {
    let config = { params: {} };
    config.params.cmdType = cmdType;
    config.params.token = this._token;
    config.params.session = this._uuid();
    config.params.data = data;
    config.timeout = config.timeout ? config.timeout : 1000 * 120;//默认超时时间
    let wssenttime = new Date().getTime(); //记录发出请求时间
    this._requestData.push({ cmdType, config, wssenttime, cb });
    if (cmdType != "cmdReqHeart") {
      console.log(`${this._wsid} send ${JSON.stringify(config.params)}`);
    }
    if (this.connected) {
      this._ws.send(JSON.stringify(config.params));
      return config.params.session;
    } else {
      this._wsResponse(config.params.session, { status: -101, info: this._baseURL + '未连接' });
      console.log(this._baseURL + '未连接');
    }
    return null;
  }

  /**
   * 发送消息, 无数据结构限制
   */
  send(buffer) {
    return new Promise((reslove, reject) => {
      if (this.connected) this._ws.send(buffer);
      else {
        reslove({ status: -101, info: this._baseURL + '未连接' });
        console.log(this._baseURL + '未连接');
      }
    });
  }

  /**
   * 删除请求
   */
  killRequest(requestId) {
    let i = this._requestData.findIndex(p => p.config.params.session == requestId);
    if (i > -1) {
      this._requestData.splice(i, 1);
    }
  }


  get connected() {
    return this._ws && this._ws.readyState === 1;
  }

  get connecting() {
    return this._ws && this._ws.readyState === 0;
  }

  /**
   * 通过请求Id响应请求
   */
  _wsResponse(requestId, data) {
    let i = this._requestData.findIndex(p => p.config.params.session === requestId);
    if (i > -1) {
      let reqObj = this._requestData[i];
      // if (!data.status ==2 ) {
      //除了响应状态为2，其他响应状态都清除对应的请求
      this._requestData.splice(i, 1);
      // }
      if (reqObj.cb) {
        reqObj.cb({ data });
      }
    }
  }

  /**
   * 请求超时响应
   */
  _requestOvertime() {
    clearInterval(this._ithot);
    this._ithot = setInterval(() => {
      let t_requestData = this._requestData.map(p => p);
      t_requestData.forEach(request => {
        if (request.config && request.config.timeout && (new Date().getTime() - request.wssenttime > request.config.timeout)) {
          this._wsResponse(request.config.params.session, {
            status: -102,
            info: '响应超时'
          });
        }
      });
      t_requestData = null;
    }, 2000);
  }
  /**
   * 建立webscoket连接
   */
  _connectWebSocket() {
    this.destroy();
    let ws = this._ws = new WebSocket(this._baseURL);
    ws.onopen = this._onWebSocketOpen.bind(this);
    ws.onclose = this._onWebSocketClose.bind(this);
    ws.onmessage = this._onWebSocketMessage.bind(this);
    ws.onerror = this._onWebSocketError.bind(this);
    // 开始心跳, 重连检测, websocket未连接,则重连; 已连接,则是心跳
    this._ith = setInterval(() => {
      if (this._isReconnect) {
        if (!(this.connecting || this.connected)) {
          if (this._reconnectCount < 5) this._connectWebSocket();
          this._reconnectCount++;
        } else {
          //this._ws.send(`/v3/cmdReqHeart.do?username=${this._username}&token=${this._token}&clientType=1&session=${this._uuid()}`);
          if (this._heartCallback) this._heartCallback();
        }
      }
    }, 1000 * 60);
  }

  /**
   * 接受websocket消息
   */
  _onWebSocketMessage(e) {
    if (this.debug) {
      console.log(`${this._wsid} ${this._baseURL} recv: ${e.data}`);
    }
    if (typeof e.data === 'string') {
      try {
        let obj = JSON.parse(e.data);

        // 响应请求
        this._wsResponse(obj.session, obj);
        if (obj.cmdType != "register") {
          if (this._messageCallback) this._messageCallback(obj);
        }


      } catch (err) {
        console.log(err);
      }
    }
    if (e.data instanceof ArrayBuffer) {
      let buffer = evt.data;
    }
  }

  /**
   * websocket连接成功回调
   */
  _onWebSocketOpen(e) {
    console.log(`${this._wsid} ${this._baseURL} 连接成功`);
    console.log(e);
    this._reconnectCount = 0;
    if (this._openCallback) {
      this._openCallback({
        flag: 1,
        msg: `${this._baseURL}连接成功`
      });
    }
    this._requestOvertime();
  }

  /**
   * websocket连接断开回调
   */
  _onWebSocketClose(e) {
    console.log(`${this._wsid} ${this._baseURL} 断开连接`);
    console.log(e);
    if (this._reconnectCount > 2 && this._closeCallback) {
      this._closeCallback({
        flag: 1,
        msg: `${this._baseURL}断开连接`
      });
    }
  }

  /**
   * websocket发生异常回调
   */
  _onWebSocketError(e) {
    console.log(`${this._baseURL} ${this._wsid} 发生异常`);
    console.log(e);
  }

  /**
   * 生产UUID
   */
  _uuid() {
    let r = ['xxxxxxxx', 'xxxx', '4xxx', 'yxxx', 'xxxxxxxxxxxx'];
    let r2 = [];
    let p = '';
    let len = 16;
    while (p.length < len * 2) {
      let j = Math.floor(Math.random() * r.length);
      j = j > r.length - 1 ? 0 : j;
      p += r[j];
      r2.push(r.splice(j, 1));
      if (r.length === 0) {
        r = r2;
        r2 = [];
      }
    }
    //p=p.substring(0,p.length - 1)
    let d = new Date().getTime();
    let s = p.replace(/[xy]/g, function (c) {
      let r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return s;
  }
}

export default cmdWebSocket;