/** 
 * 升级管理路由
 */

/**
 * 升级包管理
 */
 export const UpgradePackage = {
  path: '/UpgradePackage',
  name: 'UpgradePackage',
  component: () => import('@/views/UpgradePackage'),
  meta: {
    label: '升级包管理',
    permId: 'M11SM01',
    icon: 'icon-quyupeizhi1',
  }
};

/**
 * 升级任务管理
 */
 export const UpgradeTask= {
  path: '/UpgradeTask',
  name: 'UpgradeTask',
  component: () => import('@/views/UpgradeTask'),
  meta: {
    label: '升级任务管理',
    permId: 'M11SM01',
    icon: 'icon-quyupeizhi1',
  }
};

/**
 * 升级历史查询
 */
 export const UpgradeHistory= {
  path: '/UpgradeHistory',
  name: 'UpgradeHistory',
  component: () => import('@/views/UpgradeHistory'),
  meta: {
    label: '升级历史查询',
    permId: 'M11SM01',
    icon: 'icon-quyupeizhi1',
  }
};