/**
 * 处理查询中心子路由
 */


import { addSubRoutes } from '@/router/helper';
import * as ReportChildren from '../modules/ReportQuery';

/**
 * 
 * @param {}} QueryCenter 查询中心
 */
export default function handle(QueryCenter) {
  // 给查询报表添加子路由
  addSubRoutes(QueryCenter.ReportQuery, Object.values(ReportChildren));
}