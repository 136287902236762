export const defaultRouteMeta = {
    /** 是否不需要授权 */
    ignoreAuth: false,
    /** 不需要缓存页面 */
    ignoreKeepAlive: false,
    /** 是否禁用 */
    disabled: false,
    /** 是否隐藏 */
    hideMenu: false,
    /** 是否固定 */
    affix: false,
}