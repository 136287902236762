/**
 * 处理系统中心子路由
 */


import { addSubRoutes } from '@/router/helper';
import * as SystemChildren from '../modules/SystemManagement';

/**
 * 
 * @param {}} SystemCenter 系统中心
 */
export default function handle(SystemCenter) {
  // 给系统设置添加子路由
  addSubRoutes(SystemCenter.SystemManagement, Object.values(SystemChildren));
}