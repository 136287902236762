/**
 * 全局状态管理
 */

import Vue from "vue";
import Vuex from "vuex";
import router from "@/router";
import { isObject, uniqWith, merge } from "lodash";
import {
  accountLogin,
  sessionIdLogin,
  signout,
  addUpgradeFile,
} from "@/api/getData";
import {
  bladeAuth,
  ownMenus,
  listTenant,
  getGrantedDataScope,
  secondVerify,
} from "@/api/SystemData";
import { bladeAuthZSY } from "@/api/foreign";
import { addUserConfig, addUserAuthority } from "@/api/getManagementData";
import { appConfig } from "@/config/appConfig";
import VersionWindow from "@/components/VersionWindow";
import { Loading } from "element-ui";
import createPersistedState from 'vuex-persistedstate';
import localforage from 'localforage';


import {
  setLocalStorage,
  getLocalStorage,
  cacheExpired,
  rmLocalStorage,
} from "@/utils/cache";

import { tryJson } from "@/utils/tryJson";

import { sleep } from "@/utils/sleep";
import { encrypt, decrypt } from "@/utils/aes-ecb";
import cmdWsObj from "@/utils/cmdWebSocket";
import { flatTree } from "@/utils/treeHelper";
import { roterData } from "@/config/roterData";

// 导入默认头部菜单项
import { defaultHeaderMenus } from "@/router/route/defaultHeaderMenus";

// 导入默认配置
import {
  videoPollingConfig,
  trackConfig,
  vodConfig,
  realTimeConfig,
} from "./props";

Vue.use(Vuex);

// 缓存 key 登录token(sid)
const tokenCacheKey = "appToken";
// 缓存key, 用户记录头部的菜单项, 刷新时用到
const headerMenusKey = "appHeaderMenus";
// 登录信息key
const loginInfoKey = "yilikkkk";

const store = new Vuex.Store({
  plugins: [createPersistedState()],
  // 路由问题不使用
  state: {
    // 头部LOGO图片地址
    headerLogoUrl: require("@/assets/images/header/LOGO.png"),
    // 登录Token(sid)
    appToken: "",
    // 新token
    BladeAuth: "",
    // 租户代码
    tenantId: "",
    // 登录用户信息
    userInfo: null,
    // 登录用户配置信息
    userConfig: null,
    // 登录用户权限信息
    userPermissions: [],
    userDataPermissions: {},
    // 登录用户列表
    userRouter: {},
    // 头部导航`RouteMenu`, 页面缓存
    headerMenus: [],
    // 已经缓存的页面
    // cachePages: [],
    searchLoading: false,
    // 定位IP
    realIP: "",
    vehicleVideo: "",
    tenantList: [],
    pathVehicle: null, //跳转实时定位携带选中车辆对象
    diyRespList: {
      list: [],
      obj: {},
    },
    deptId: "",
    isFullscreen: false, // 全屏
    detailsVehicleList: '', // 实时定位跳转车辆详情携带id数据
    secondaryData: {}, // 二次登录存储数据
  },
  mutations: {
    setVehicleVideo(state, vehicleVideo) {
      state.vehicleVideo = vehicleVideo;
    },
    SetDetailsVehicleList(state, vehicleList) {
      state.detailsVehicleList = vehicleList;
    },
    setLoading(state, searchLoading) {
      state.searchLoading = searchLoading;
    },
    /**
     * 设置登录token
     */
    setAppToken(state, appToken) {
      state.appToken = appToken;
    },
    /**
     * 设置新登录token
     */
    SetBladeAuth(state, BladeAuth) {
      state.BladeAuth = BladeAuth;
    },
    /**
     * 设置登录用户信息
     */
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo;
    },
    /**
     * 设置登录用户配置信息
     */
    setUserConfig(state, userConfig) {
      state.userConfig = userConfig;
    },
    /**
     * 设置登录用户的权限列表 ['M01','M02',....]
     */
    setUserPermissions(state, permissions) {
      permissions.push("home");
      state.userPermissions = permissions;
    },
    /**
     * 设置登录用户的数据权限权限列表{ 权限code+功能： 隐藏的权限 }
     */
    setUserDataPermissions(state, permissions) {
      state.userDataPermissions = permissions;
    },
    /**
     * 设置登录用户的路由列表
     */
    setUserRouter(state, router) {
      state.userRouter = router;
    },
    /**
     * 设置头部菜单项
     */
    setHeaderMenus(state, menus) {
      // 默认的菜单项始终会写入, 并去重
      const _menus = uniqWith(
        [...defaultHeaderMenus, ...menus],
        (a, b) => a.name === b.name
      );
      state.headerMenus = _menus;
      setLocalStorage(headerMenusKey, _menus);
    },
    /**
     * 设置需要缓存的页面
     */
    setCachePages(state, menus) {
      // 去重并设置
      const _menus = uniqWith([...menus], (a, b) => a.name === b.name);
      console.log(_menus);
      // state.cachePages = _menus;
    },
    REALIP(state, data) {
      console.log(data);
      state.realIP = data;
    },
    setTenantId(state, data) {
      state.tenantId = data;
    },
    GetTenantList(state, data) {
      listTenant().then((res) => {
        let tenantList = res.data.map((val) => {
          return {
            value: val.tenantId,
            text: val.tenantName,
          };
        });
        tenantList.unshift({
          text: "全部",
          value: "",
        });
        state.tenantList = tenantList;
      });
    },
    SetSecondaryCheck(state, data) {
      state.secondaryData = data;
    },
    SetPathVehicle(state, data) {
      state.pathVehicle = data;
    },
    SetDiyRespList(state, data) {
      let objData = {};
      if (data) {
        data.map((val, ind) => {
          objData[val.tableKey] = {
            index: ind,
            value:
              val.tableValue && val.tableValue != "{}"
                ? JSON.parse(val.tableValue)
                : "",
          };
        });
        console.log(objData);
        state.diyRespList = {
          list: data,
          obj: objData,
        };
      }
    },
    SetFullscreen(state, data) {
      state.isFullscreen = !state.isFullscreen;
    },
  },
  actions: {
    /**
     * 登录
     */
    async login({ commit, state }, params) {
      let result;
      const { sessionId, ...othParams } = params;
      // let loading = Loading.service({
      //   lock: true,
      //   text: 'Loading',
      //   spinner: 'el-icon-loading',
      //   background: 'rgba(0, 0, 0, 0.7)'
      // })

      // if (sessionId) {
      //   // 通过`sessionId`登录, TODO: 暂时未实现
      //   result = await sessionIdLogin({ sessionId });
      // } else {
      //   // 账号密码登录
      //   result = await accountLogin(othParams);
      // }
      let data = new FormData();
      // data.append('account', 'admin');
      data.append("account", othParams.userName);
      data.append("grantType", "password");
      // data.append('password', 'admin');
      data.append("password", othParams.password);
      // data.append('tenantId', '000000');
      data.append("tenantId", othParams.tenantId);
      result = await bladeAuth(data);
      // loading.close();
      // 登录成功
      if (
        result?.success === true &&
        isObject(result.data?.platformLoginInfo) && !result.data.secondVerify
      ) {
        const {
          authority,
          config: configStr,
          sid,
          ...userInfo
        } = result.data.platformLoginInfo;
        commit("setTenantId", othParams.tenantId);
        if (result.data.extend && result.data.extend.loginCurrentIp) {
          // 写入实时定位IP
          commit("REALIP", result.data.extend.loginCurrentIp);
        }
        if (
          result.data.platformLoginInfo &&
          result.data.platformLoginInfo.terminalUpdates &&
          result.data.platformLoginInfo.terminalUpdates.length > 0
        ) {
          // 版本升级弹窗
          VersionWindow({
            title: "新版本诚邀体验!",
            message: result.data.platformLoginInfo.terminalUpdates.map(
              (val, ind) => val.documentContent
            ),
            // message: [1,2,3],
            style: { width: "540px" },
          }).then((resobj) => {
            resobj.Promise.then((res) => {
              if (res) {
                addUpgradeFile({
                  platform: "PC",
                  userId: result.data.userId,
                  fileName: result.data.platformLoginInfo.terminalUpdates
                    .map((val, ind) => val.fileName)
                    .toString(),
                });
              }
            });
          });
        }
        // 解析用户配置(obj.config)
        const config = isObject(configStr) ? configStr : tryJson(configStr);

        // 用户配置, 使用默认配置合并用户的配置
        const userConfig = merge(
          {},
          {
            realTimeConfig,
            vodConfig,
            trackConfig,
            videoPollingConfig,
          },
          config || {}
        );

        // 解析用户权限 Array<string>
        // let permissions = [];
        if (userInfo.userId === 1 && !authority) {
          // NOTE: 总账号(userId === 1)拥有所有权限,
          // 当前权限有变化时(新增,删除总的权限列表), 总帐号的权限应从数据库中删除, 此时总账号登录时会默认给所有权限
          permissions = flatTree(roterData).map((obj) => obj.permId);

          try {
            // 总账号获取到所有权限, 重新写入数据库
            addUserAuthority(permissions.join(","), 1);
          } catch (err) {
            //
          }
        } else {
          // 解析权限
          // permissions = Array.isArray(authority) ? authority : (authority && authority.split(','));
        }

        // 写token
        commit("setAppToken", sid);
        // 写新token
        commit(
          "SetBladeAuth",
          result.data.tokenType + " " + result.data.accessToken
        );
        // 写入登录用户信息
        commit("setUserInfo", {
          ...userInfo,
          name: result.data.account,
          MJUserId: result.data.userId,
          picture: result.data.avatar,
          deptId: result.data.deptId,
          myAgentConcat: result.data.myAgentConcat,
          myAgentRank: result.data.myAgentRank,
          loginIdentity: result.data.loginIdentity,
          referrerUid: result.data.referrerUid,
          referrerName: result.data.referrerName,
          standingKey: result.data.standingKey,
        });
        // 写入登录用户配置
        commit("setUserConfig", userConfig);
        let menus = await ownMenus();
        let dataPermissions = {};
        let permissions = await DealWithChiledren(
          menus.data.children || [],
          [],
          1,
          dataPermissions
        );
        // 写入登录用户权限
        commit("setUserPermissions", permissions);
        // 写入用户数据权限
        commit("setUserDataPermissions", dataPermissions);
        commit("setUserRouter", menus.data);
        // 写入表头个性化
        commit("SetDiyRespList", result.data.diyRespList);
        // 已经写了缓存, 不再重复写入
        if (!getLocalStorage(tokenCacheKey)) {
          // 过期时间
          const expiredMs = 1000 * 60 * 600;
          setLocalStorage(tokenCacheKey, sid, expiredMs);
        }

        // 用户登录参数加密写入缓存, 刷新时会解析改换成,并使用改参数重新登录
        setLocalStorage(loginInfoKey, encrypt(JSON.stringify(othParams)));

        // 连接命令链路
        cmdWsObj.init({
          username: userInfo.name,
          userkey: sid,
          baseURL:
            router.app._route.name == "foreignBigScreenDisplayVideoPolling" ||
            router.app._route.name == "foreignVideoPolling"
              ? "wss://120.78.185.121:8899"
              : appConfig.base2GCmdUrl,
        });

        // 登录超时处理
        timeoutLogout();

        // 默认头部菜单写入缓存
        const _menus = getLocalStorage(headerMenusKey);
        if (_menus) {
          commit("setHeaderMenus", _menus);
        } else {
          commit("setHeaderMenus", defaultHeaderMenus);
        }
      }else if(result?.success === true && result.data?.secondVerify) {
        commit("SetSecondaryCheck", {
          verifyPhone: result.data?.verifyPhone,
          verifyKey: result.data?.verifyKey,
        });
      }
      commit("GetTenantList");

      return result;
    },
    async secondaryLogin({ commit, state }, params) {
      try {
      let result;
      result = await secondVerify(params);
      // loading.close();
      // 登录成功
      if (
        result?.success === true &&
        isObject(result.data?.platformLoginInfo)) {
        const {
          authority,
          config: configStr,
          sid,
          ...userInfo
        } = result.data.platformLoginInfo;
        // commit("setTenantId", othParams.tenantId);
        if (result.data.extend && result.data.extend.loginCurrentIp) {
          // 写入实时定位IP
          commit("REALIP", result.data.extend.loginCurrentIp);
        }
        if (
          result.data.platformLoginInfo &&
          result.data.platformLoginInfo.terminalUpdates &&
          result.data.platformLoginInfo.terminalUpdates.length > 0
        ) {
          // 版本升级弹窗
          VersionWindow({
            title: "新版本诚邀体验!",
            message: result.data.platformLoginInfo.terminalUpdates.map(
              (val, ind) => val.documentContent
            ),
            // message: [1,2,3],
            style: { width: "540px" },
          }).then((resobj) => {
            resobj.Promise.then((res) => {
              if (res) {
                addUpgradeFile({
                  platform: "PC",
                  userId: result.data.userId,
                  fileName: result.data.platformLoginInfo.terminalUpdates
                    .map((val, ind) => val.fileName)
                    .toString(),
                });
              }
            });
          });
        }
        // 解析用户配置(obj.config)
        const config = isObject(configStr) ? configStr : tryJson(configStr);

        // 用户配置, 使用默认配置合并用户的配置
        const userConfig = merge(
          {},
          {
            realTimeConfig,
            vodConfig,
            trackConfig,
            videoPollingConfig,
          },
          config || {}
        );

        // 解析用户权限 Array<string>
        // let permissions = [];
        if (userInfo.userId === 1 && !authority) {
          // NOTE: 总账号(userId === 1)拥有所有权限,
          // 当前权限有变化时(新增,删除总的权限列表), 总帐号的权限应从数据库中删除, 此时总账号登录时会默认给所有权限
          permissions = flatTree(roterData).map((obj) => obj.permId);

          try {
            // 总账号获取到所有权限, 重新写入数据库
            addUserAuthority(permissions.join(","), 1);
          } catch (err) {
            //
          }
        } else {
          // 解析权限
          // permissions = Array.isArray(authority) ? authority : (authority && authority.split(','));
        }

        // 写token
        commit("setAppToken", sid);
        // 写新token
        commit(
          "SetBladeAuth",
          result.data.tokenType + " " + result.data.accessToken
        );
        // 写入登录用户信息
        commit("setUserInfo", {
          ...userInfo,
          name: result.data.account,
          MJUserId: result.data.userId,
          picture: result.data.avatar,
          deptId: result.data.deptId,
          myAgentConcat: result.data.myAgentConcat,
          myAgentRank: result.data.myAgentRank,
          loginIdentity: result.data.loginIdentity,
          referrerUid: result.data.referrerUid,
          referrerName: result.data.referrerName,
          standingKey: result.data.standingKey,
        });
        // 写入登录用户配置
        commit("setUserConfig", userConfig);
        let menus = await ownMenus();
        let dataPermissions = {};
        let permissions = await DealWithChiledren(
          menus.data.children || [],
          [],
          1,
          dataPermissions
        );
        // 写入登录用户权限
        commit("setUserPermissions", permissions);
        // 写入用户数据权限
        commit("setUserDataPermissions", dataPermissions);
        commit("setUserRouter", menus.data);
        // 写入表头个性化
        commit("SetDiyRespList", result.data.diyRespList);
        // 已经写了缓存, 不再重复写入
        if (!getLocalStorage(tokenCacheKey)) {
          // 过期时间
          const expiredMs = 1000 * 60 * 600;
          setLocalStorage(tokenCacheKey, sid, expiredMs);
        }
        // 用户登录参数加密写入缓存, 刷新时会解析改换成,并使用改参数重新登录
        // setLocalStorage(loginInfoKey, encrypt(JSON.stringify(othParams)));

        // 连接命令链路
        cmdWsObj.init({
          username: userInfo.name,
          userkey: sid,
          baseURL:
            router.app._route.name == "foreignBigScreenDisplayVideoPolling" ||
            router.app._route.name == "foreignVideoPolling"
              ? "wss://120.78.185.121:8899"
              : appConfig.base2GCmdUrl,
        });

        // 登录超时处理
        timeoutLogout();

        // 默认头部菜单写入缓存
        const _menus = getLocalStorage(headerMenusKey);
        if (_menus) {
          commit("setHeaderMenus", _menus);
        } else {
          commit("setHeaderMenus", defaultHeaderMenus);
        }
      }
      commit("GetTenantList");

      return result;
        
    } catch (error) {
      console.log(error);
    }
    },
    /**
     * 链接对外登录
     */
    async foreignLogin({ commit, state }, params) {
      let result;
      const { sessionId, ...othParams } = params;
      // let loading = Loading.service({
      //   lock: true,
      //   text: 'Loading',
      //   spinner: 'el-icon-loading',
      //   background: 'rgba(0, 0, 0, 0.7)'
      // })

      // if (sessionId) {
      //   // 通过`sessionId`登录, TODO: 暂时未实现
      //   result = await sessionIdLogin({ sessionId });
      // } else {
      //   // 账号密码登录
      //   result = await accountLogin(othParams);
      // }
      let data = new FormData();
      // data.append('account', 'admin');
      data.append("account", othParams.userName);
      data.append("grantType", "password");
      // data.append('password', 'admin');
      data.append("password", othParams.password);
      // data.append('tenantId', '000000');
      data.append("tenantId", othParams.tenantId);
      result = await bladeAuthZSY(data);
      // loading.close();
      // 登录成功
      if (
        result?.success === true &&
        isObject(result.data?.platformLoginInfo)
      ) {
        const {
          authority,
          config: configStr,
          sid,
          ...userInfo
        } = result.data.platformLoginInfo;
        commit("setTenantId", othParams.tenantId);
        // 解析用户配置(obj.config)
        const config = isObject(configStr) ? configStr : tryJson(configStr);

        // 用户配置, 使用默认配置合并用户的配置
        const userConfig = merge(
          {},
          {
            realTimeConfig,
            vodConfig,
            trackConfig,
            videoPollingConfig,
          },
          config || {}
        );

        // 写token
        commit("setAppToken", sid);
        // 写新token
        commit(
          "SetBladeAuth",
          result.data.tokenType + " " + result.data.accessToken
        );
        // 写入登录用户信息
        commit("setUserInfo", {
          ...userInfo,
          name: result.data.account,
          MJUserId: result.data.userId,
          picture: result.data.avatar,
          deptId: result.data.deptId,
          myAgentConcat: result.data.myAgentConcat,
          myAgentRank: result.data.myAgentRank,
          loginIdentity: result.data.loginIdentity,
          referrerUid: result.data.referrerUid,
          referrerName: result.data.referrerName,
          standingKey: result.data.standingKey,
        });
        // 写入登录用户配置
        commit("setUserConfig", userConfig);
        // 用户登录参数加密写入缓存, 刷新时会解析改换成,并使用改参数重新登录
        setLocalStorage(loginInfoKey, encrypt(JSON.stringify(othParams)));

        // 连接命令链路
        cmdWsObj.init({
          username: userInfo.name,
          userkey: sid,
          baseURL:
            router.app._route.name == "foreignBigScreenDisplayVideoPolling" ||
            router.app._route.name == "foreignVideoPolling"
              ? "wss://120.78.185.121:8899"
              : appConfig.base2GCmdUrl,
        });

        // 登录超时处理
        timeoutLogout();

        // 默认头部菜单写入缓存
        const _menus = getLocalStorage(headerMenusKey);
        if (_menus) {
          commit("setHeaderMenus", _menus);
        } else {
          commit("setHeaderMenus", defaultHeaderMenus);
        }
      }
      return result;
    },

    // 刷新重新登录
    async relogin({ state, dispatch }) {
      const appToken = getLocalStorage(tokenCacheKey);
      // 缓存有`token`, 但`store`没有, 表示可以自动登录
      if (appToken && !state.appToken) {
        // 获取缓存登录参数
        const loginParam = tryJson(decrypt(getLocalStorage(loginInfoKey)));

        if (loginParam) {
          // 登录
          await dispatch("login", loginParam);
        }

        // 使用sessionId登录, NOTE:未实现
        // await dispatch('login', { sessionId: appToken });
      }
      return;
    },

    /**
     * 退出登录
     */
    async logout({ commit, state }) {
      // 销毁命令链路
      cmdWsObj.destroy();

      // 调用退出登录接口
      if (state.appToken) {
        signout();
        await sleep(0);
      }

      // 清空状态, 及缓存
      state.headerMenus = [];
      state.cachePages = [];
      commit("setAppToken", "");
      commit("setUserInfo", null);
      commit("setUserConfig", null);
      commit("setUserPermissions", []);
      commit("setUserDataPermissions", {});
      rmLocalStorage(tokenCacheKey);
      rmLocalStorage(headerMenusKey);
      rmLocalStorage(loginInfoKey);
    },

    /**
     * 修改用户配置
     */
    async dispatchUserConfig({ commit, state }, config) {
      const { userInfo } = state;
      commit("setUserConfig", config);
      const result = await addUserConfig(config, userInfo.userId);
      return result;
    },
  },
  modules: {},
});

// relogin();

export default store;

/**
 * 超时退出
 */
function timeoutLogout() {
  cacheExpired(tokenCacheKey).then(async (timeout) => {
    console.log("超时退出");
    await store.dispatch("logout");
    router.push("/login");
    location.reload();
  });
}

function DealWithChiledren(params, list, index, dataPermissions) {
  params.map((val) => {
    list.push(val.code);
    dataPermissions[val.code + "-Add"] = val.hiddenFieldOfAdd
      ? val.hiddenFieldOfAdd.split(",")
      : "";
    dataPermissions[val.code + "-Edit"] = val.hiddenFieldOfEdit
      ? val.hiddenFieldOfEdit.split(",")
      : "";
    dataPermissions[val.code + "-View"] = val.hiddenFieldOfView
      ? val.hiddenFieldOfView.split(",")
      : "";
    if (val.children && val.children.length) {
      DealWithChiledren(val.children, list, 1, dataPermissions);
    }
  });
  return list;
}
