/**
 * 测算
 */

export const SchemeRecommendation = {
   path: '/SchemeRecommendation',
   name: 'SchemeRecommendation',
   component: () => import('@/views/MeasurementManagement/SchemeRecommendation/index'),
   meta: {
      label: '推荐方案',
      permId: 'M30-SchemeRecommendation',
      icon: require('@/assets/images/claimCase.png'),
   }
};

export const TypeEquipment = {
   path: '/TypeEquipment',
   name: 'TypeEquipment',
   component: () => import('@/views/MeasurementManagement/TypeEquipment/index'),
   meta: {
      label: '设备种类',
      permId: 'M30-TypeEquipment',
      icon: require('@/assets/images/claimCase.png'),
   }
};

export const BrandManagement = {
   path: '/BrandManagement',
   name: 'BrandManagement',
   component: () => import('@/views/MeasurementManagement/BrandManagement/index'),
   meta: {
      label: '品牌管理',
      permId: 'M30-BrandManagement',
      icon: require('@/assets/images/claimCase.png'),
   }
};

export const BannerManagement = {
   path: '/BannerManagement',
   name: 'BannerManagement',
   component: () => import('@/views/MeasurementManagement/BannerManagement/index'),
   meta: {
      label: '测算banner管理',
      permId: 'M30-BannerManagement',
      icon: require('@/assets/images/claimCase.png'),
   }
};

export const Record = {
   path: '/Record',
   name: 'Record',
   component: () => import('@/views/MeasurementManagement/Record/index'),
   meta: {
      label: '测算记录',
      permId: 'M30-Record',
      icon: require('@/assets/images/claimCase.png'),
   }
};