<template>
  <ResizeObserver @resize="handleWrapperResize">
    <div ref="wrapRef" class="VirtualTableWrapper">
      <UTable
        ref="plTable"
        :data="data"
        :height="tableHeight"
        use-virtual
        showBodyOverflow="title"
        showHeaderOverflow="title"
        :row-height="rowHeight"
        border
        size="mini"
        v-bind="$attrs"
        v-on="$listeners"
      >
        <slot></slot>
      </UTable>
    </div>
  </ResizeObserver>
</template>

<script>
/**
 * 封装umy-table, 根据外部容器自动计算table高度
 */

import ResizeObserver from '@/components/ResizeObserver';

export default {
  name: 'VirtualTable',
  components: {
    ResizeObserver,
  },
  model: {
    prop: 'tableObj',
  },
  props: {
    /**
     * umy-table 的ref
     * eg. v-model="umyTableRef"
     */
    tableObj: {
      type: Object,
      default: null,
    },
    /**
     * 数据
     */
    data: {
      type: Array,
      default() {
        return [];
      }
    },
    /**
     * 行高
     */
    rowHeight: {
      type: Number,
      default: 20
    }
  },
  data() {
    return {
      tableHeight: 300,
    };
  },
  mounted() {
    this.handleWrapperResize();

    // 设置`tableObj`
    this.$emit('input', this.$refs.plTable);
  },
  methods: {
    handleWrapperResize() {
      const { wrapRef } = this.$refs;
      if (wrapRef) {
        this.tableHeight = Math.max(1, wrapRef.clientHeight - 5);
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.VirtualTableWrapper {
  position: relative;
  width: 100%;
  height: 100%;
}
</style>