import { appConfig } from "@/config/appConfig";
import { createHttp } from "@/api/HttpHelper";

const axios = createHttp(appConfig.base2GUrl);
const axios2 = createHttp(appConfig.base2GUrl2);
import { MIME } from "@/api/MIME";

/**
 * 添加用户配置
 */
export const addUserConfig = (config, userId) =>
  axios.post("user/addUserConfig", {
    config: JSON.stringify(config),
    userId,
  });

/**
 *获取指定用户权限
 * @param {*} userId 用户Id
 */
export const findUserConfig = (userId) =>
  axios.get("user/findUserConfig", { params: { userId } });

/**
 * 修改用户权限
 * @param {string} authority - 权限, 英文逗号分隔
 * @param {number} userId - 用户Id
 */
export const addUserAuthority = (authority, userId) =>
  axios.post("user/addUserAuthority", { authority, userId });

/**
 *获取指定用户权限
 * @param {*} userId 用户Id
 */
export const findUserAuthority = (userId) =>
  axios
    .get("user/findUserAuthority", { params: { userId } })
    .then((resObj) => {
      if (resObj && resObj.obj) {
        const { authority } = resObj.obj;

        return authority.split(",");
      }
      return [];
    })
    .catch(() => []);

/**
 * 获取用户信息
 * @param {JSON} val
 */
export const getManageUserInfo = (val) =>
  axios.get("user/getManageUserInfo.do", { params: val }).then((res) => {
    // if (res.flag && res.obj) {
    //    // accountType = 2：监管用户 不显示在资料管理
    //    res.obj.userList = res.obj.userList.filter(p => p.accountType !== 2);
    // }
    return res;
  });

/**
 * 获取用户信息
 * @param {JSON} val
 */
export const queryCustomUser = (val) => {
  return axios.get("user/queryCustomUser.do", { params: val });
};

/**
 * 获取当前用户车辆状态(在线/离线)
 * 使用地方 用户管理    充值管理
 */
export const addUpVehicleStatusCount = () => {
  return axios.get("monitor/addUpVehicleStatusCount.do");
};

/**
 * 获取用户信息
 * @param {JSON} val
 */
export const getUserInfo = (val) => {
  return axios.get("user/getUserInfo.do", { params: val });
};

/**
 * 删除用户
 * @param {JSON} val
 */
export const delUser = (val) => {
  return axios.get("user/delUser.do", { params: val });
};

/**
 * 获取用户绑定的车组
 * @param {JSON} val
 */
export const getUserBindGroup = (val) => {
  return axios.get("vehicleGroup/getUserBoundGroup.do", { params: val });
};

// /**
//  * 绑定用户车组
//  * @param {JSON} val
//  */
// export const boundGroups = (val) => { return axios.get('user/boundGroups.do', { params: val }) }

/**
 * 绑定用户车组
 * @param {FormData} formData
 */
export const boundGroups = (formData) => {
  return axios.post("user/boundGroups.do", formData, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
  });
};

/**
 * 添加车组
 * @param {JSON} val
 */
export const addVehGroup = (val) => {
  return axios.get("vehicleGroup/addVehGroup.do", { params: val });
};

/**
 * 更新车组
 * @param {*} params
 */
export const updateVehicleGroup = (params) =>
  axios.get("vehicleGroup/updateVehicleGroup.do", { params });

/**
 * 添加用户
 * @param {JSON} val
 */
export const addClientUser = (params) => {
  return axios.post("user/addClientUser.do", params, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
  });
};

// Object.entries(params).map(([key, val]) => `${key}=${val}`).join('&')

/**
 * 修改用户
 * @param {JSON} val
 */
export const updateUser = (params) => {
  return axios.post(
    "user/updateUser.do",
    {
      jsonData: JSON.stringify(params.user),
      permissions: JSON.stringify(params.permissions),
    },
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    }
  );
};

/**
 * 通过车组ID获取车组下车辆信息 *
 * @param {JSON} val
 */
export const getVehiclesByGroupId = (val) => {
  return axios.get("vehicle/getVehiclesByGroupId.do", { params: val });
};

/**
 * 通过车组ID获取车组资料信息 *
 * @param {JSON} val
 */
export const getVehGroupInfo = (val) => {
  return axios.get("vehicleGroup/getVehGroupInfo.do", { params: val });
};

/**
 *
 * flags=true删除单个车组  flags=false删除车组已经车组下的车组
 * @param {JSON} val
 * @param {Boolean} flags
 */
export const delVehicleGroup = (val, flags) => {
  let url = "vehicleGroup/deleteVehicleGroup.do";
  if (flags) {
    url = "vehicleGroup/delSubVchicleGroup.do";
  }
  return axios.get(url, { params: val });
};

/**
 * flags=true添加车组  flags=false更新车组
 * @param {JSON} val
 * @param {Boolean} flags
 */
export const addOrUpdateVehGroup = (val, flags) => {
  return flags ? updateVehicleGroup(val) : addVehGroup(val);
};

/**
 * 多个车辆转移 *
 * @param {JSON} val
 */
export const batchTransfer = (val) => {
  return axios.get("vehicle/batchTransfer.do", { params: val });
};

/**
 * 单个车辆转移 *
 * @param {JSON} val
 */
export const singleVehTransfer = (val) => {
  return axios.get("vehicle/singleVehTransfer.do", { params: val });
};

/**
 * 根据车辆Id获取车辆信息 *
 * @param {JSON} val
 */
export const getVehicleInfo = (val) => {
  return axios.get("vehicle/getVehicleInfo.do", { params: val });
};

/**
 * 删除车辆信息 *
 * @param {JSON} val
 */
export const delteVehicle = (val) => {
  return axios.get("vehicle/delteVehicle.do", { params: val });
};

/**
 *激活车辆信息 *
 * @param {JSON} val
 */
export const activeVehicle = (val) => {
  return axios.get("systemutil/activeVehicle.do", { params: val });
};

/**
 * flags=true添加车辆  flags=false更新车辆
 * @param {JSON} val
 * @param {Boolean} flags
 */
export const addOrUpdateVehicle = (val, flags) => {
  let url = "vehicle/updateVehicle.do";
  if (flags) {
    url = "vehicle/directAddVehicle.do";
  }
  return axios.get(url, { params: val });
};

/**
 * 模糊搜索所有车*
 * @param {JSON} val
 */
export const searchVehicle = (val) => {
  return axios.get("vehicle/searchVehicle.do", { params: val });
};

/**
 * 模糊搜索上线车*
 * @param {JSON} val
 */
export const searchVehicleApp = (val) => {
  return axios.get("vehicle/searchVehicleApp.do", { params: val });
};

/**
 * 模糊搜索所有车(一立)
 * @param {JSON} val
 */
export const searchMsg = (val) => {
  return axios.get("vehicle/searchMsg", { params: val });
};

/**
 * 获取轨迹数据*
 * @param {JSON} val
 */
export const getVehTrackMongo = (val) => {
  return axios.get("vehicle/getVehTrackMongo.do", { params: val });
};

/**
 * 获取轨迹数据*
 * @param {JSON} val
 */
export const findVehicleInfo = (val) => {
  return axios.get("vehicle/findVehicleInfo.do", { params: val });
};

/** 查询平台报警通知配置* */
export const getalarmPushRule = (val) => {
  return axios2.get("/alarmPushRule/getRule", { params: val });
};

/** 保存配置* */
export const saveAlarmPushRule = (val) => {
  return axios2.post("alarmPushRule/saveRule", val, {
    transRequest: false,
    headers: {
      "Content-Type": "application/json",
    },
  });
};

/**
 * 查询微信公众号信息*
 * @param {JSON} val
 */
export const getWeChartList = (val) => {
  return axios2.get("weixinOfficialAccount/list", { params: val });
};

/**
 * 模糊搜索公众号名称 *
 */
export const getWeChartSearch = (val) => {
  return axios2.get("weixinOfficialAccount/search", { params: val });
};

/** 查询微信公众号详情* */
export const getWeChartListByid = (val) => {
  return axios2.get("weixinOfficialAccount/selectOne", { params: val });
};

/** 新增微信公众号信息 */
export const addWeChart = (val) => {
  return axios2.post("weixinOfficialAccount/insert", val, {
    transRequest: false,
    headers: {
      "Content-Type": "application/json",
    },
  });
};

/** 修改微信公众号信息 */
export const upadteWeChart = (val) => {
  return axios2.post("weixinOfficialAccount/update", val, {
    transRequest: false,
    headers: {
      "Content-Type": "application/json",
    },
  });
};

/**
 * 删除微信公众号信息*
 * @param {JSON} val
 */
export const deleteWeChart = (val) => {
  return axios2.get("weixinOfficialAccount/delete", { params: val });
};

/** 查询微信公众推送配置表* */
export const getWeChartConfigList = (val) => {
  return axios2.get("weixinPushConfig/list", { params: val });
};

/** 删除微信公众推送配置表* */
export const deleteWeChartConfig = (val) => {
  return axios2.get("weixinPushConfig/delete", { params: val });
};

/** 查询微信公众推送配置表详情* */
export const getWeChartConfigListByid = (val) => {
  return axios2.get("weixinPushConfig/selectOne", { params: val });
};

/** 新增微信公众推送配置表 */
export const addWeChartConfig = (val) => {
  return axios2.post("weixinPushConfig/insert", val, {
    transRequest: false,
    headers: {
      "Content-Type": "application/json",
    },
  });
};

/** 修改微信公众推送配置表 */
export const upadteWeChartConfig = (val) => {
  return axios2.post("weixinPushConfig/update", val, {
    transRequest: false,
    headers: {
      "Content-Type": "application/json",
    },
  });
};

/** 查询公众号绑定用户* */
export const getUserBindList = (val) => {
  return axios2.get("userWeixinMap/list", { params: val });
};

/** 解绑(删除)公众号绑定用户* */
export const deleteUserBindList = (val) => {
  return axios2.get("userWeixinMap/delete", { params: val });
};

/** 查询微信公众号id和名称列表 * */
export const getOfficialList = (val) => {
  return axios2.get("weixinOfficialAccount/selectOfficialIdAndName", {
    params: val,
  });
};

/** 获取品牌列表 * */
export const getFactoryNo = (val) => {
  return axios.get("vehicle/getFactoryNo.do", { params: val });
};

/**
 * 获取轨迹数据 一立*
 * @param {JSON} val
 */
export const getTrackInfo = (val) => {
  return axios.get("track/getTrackInfo.do", { params: val });
};

/**
 * 字典类型-列表 获取产品类型 *
 * @param {JSON} val
 */
export const getDictList = (val) => {
  return axios2.get("dict/type/list", { params: val });
};

/**
 * 字典数据-列表（根据字典类型查询） 获取设备类型*
 * @param {JSON} val
 */
export const getDictType = (val) => {
  return axios2.get("dict/data/type", { params: val });
};

// 获取车辆二维码
export const getVehicleQrCode = (val) => {
  return axios.get("vehicle/getVehicleCode", {
    params: val,
    responseType: "blob",
  });
};
