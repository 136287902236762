export const blobType = {
   xls: 'application/vnd.ms-excel',
   xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
   csv: 'text/csv',
   doc: 'application/msword',
   docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
   pdf: 'application/pdf',
   ppt: 'application/vnd.ms-powerpoint',
   pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
   png: 'image/png',
   // gif: 'image/gif', // 没有该类型图片
   jpeg: 'image/jpeg',
   jpg: 'image/jpeg',
   mp3: 'audio/mpeg',
   aac: 'audio/aac',
   html: 'text/html',
   css: 'text/css',
   js: 'text/javascript',
   json: 'application/json',
   abw: 'application/x-abiword',
   arc: 'application/x-freearc',
   avi: 'video/x-msvideo',
   azw: 'application/vnd.amazon.ebook',
   bin: 'application/octet-stream',
   bmp: 'image/bmp',
   bz: 'application/x-bzip',
   bz2: 'application/x-bzip2',
   csh: 'application/x-csh',
   eot: 'application/vnd.ms-fontobject',
   epub: 'application/epub+zip',
   htm: 'text/html',
   ico: 'image/vnd.microsoft.icon',
   ics: 'text/calendar',
   jar: 'application/java-archive',
   jsonld: 'application/ld+json',
   mid: 'audio/midi audio/x-midi',
   midi: 'audio/midi audio/x-midi',
   mjs: 'text/javascript',
   mpeg: 'video/mpeg',
   mpkg: 'application/vnd.apple.installer+xml',
   odp: 'application/vnd.oasis.opendocument.presentation',
   ods: 'application/vnd.oasis.opendocument.spreadsheet',
   odt: 'application/vnd.oasis.opendocument.text',
   oga: 'audio/ogg',
   ogv: 'video/ogg',
   ogx: 'application/ogg',
   otf: 'font/otf',
   rar: 'application/x-rar-compressed',
   rtf: 'application/rtf',
   sh: 'application/x-sh',
   svg: 'image/svg+xml',
   swf: 'application/x-shockwave-flash',
   tar: 'application/x-tar',
   tif: 'image/tiff',
   tiff: 'image/tiff',
   ttf: 'font/ttf',
   txt: 'text/plain',
   vsd: 'application/vnd.visio',
   wav: 'audio/wav',
   weba: 'audio/webm',
   webm: 'video/webm',
   webp: 'image/webp',
   woff: 'font/woff',
   woff2: 'font/woff2',
   xhtml: 'application/xhtml+xml',
   xml: 'text/xml',
   xul: 'application/vnd.mozilla.xul+xml',
   zip: 'application/zip',
};

export const blobType2 = {
   "other": "other",
   "application/vnd.ms-excel": "xls",
   "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "xlsx",
   "text/csv": "csv",
   "application/msword": "doc",
   "application/vnd.openxmlformats-officedocument.wordprocessingml.document": "docx",
   "application/pdf": "pdf",
   "application/vnd.ms-powerpoint": "ppt",
   "application/vnd.openxmlformats-officedocument.presentationml.presentation": "pptx",
   "image/png": "png",
   "image/gif": "gif",
   "image/jpeg": "jpg",
   "audio/mpeg": "mp3",
   "audio/aac": "aac",
   "text/html": "htm",
   "text/css": "css",
   "text/javascript": "mjs",
   "application/json": "json",
   "application/x-abiword": "abw",
   "application/x-freearc": "arc",
   "video/x-msvideo": "avi",
   "application/vnd.amazon.ebook": "azw",
   "application/octet-stream": "bin",
   "image/bmp": "bmp",
   "application/x-bzip": "bz",
   "application/x-bzip2": "bz2",
   "application/x-csh": "csh",
   "application/vnd.ms-fontobject": "eot",
   "application/epub+zip": "epub",
   "image/vnd.microsoft.icon": "ico",
   "text/calendar": "ics",
   "application/java-archive": "jar",
   "application/ld+json": "jsonld",
   "audio/midi audio/x-midi": "midi",
   "video/mpeg": "mpeg",
   "application/vnd.apple.installer+xml": "mpkg",
   "application/vnd.oasis.opendocument.presentation": "odp",
   "application/vnd.oasis.opendocument.spreadsheet": "ods",
   "application/vnd.oasis.opendocument.text": "odt",
   "audio/ogg": "oga",
   "video/ogg": "ogv",
   "application/ogg": "ogx",
   "font/otf": "otf",
   "application/x-rar-compressed": "rar",
   "application/rtf": "rtf",
   "application/x-sh": "sh",
   "image/svg+xml": "svg",
   "application/x-shockwave-flash": "swf",
   "application/x-tar": "tar",
   "image/tiff": "tiff",
   "font/ttf": "ttf",
   "text/plain": "txt",
   "application/vnd.visio": "vsd",
   "audio/wav": "wav",
   "audio/webm": "weba",
   "video/webm": "webm",
   "image/webp": "webp",
   "font/woff": "woff",
   "font/woff2": "woff2",
   "application/xhtml+xml": "xhtml",
   "text/xml": "xml",
   "application/vnd.mozilla.xul+xml": "xul",
   "application/zip": "zip"
};