/**
 * 安全监控路由
 */

/**
 * 车辆驾驶行为统计
 */
// export const VehicleDriveBehavior = {
//   path: '/VehicleDriveBehavior',
//   name: 'VehicleDriveBehavior',
//   component: () => import('@/views/VehicleDriveBehavior'),
//   meta: {
//     label: '车辆驾驶行为统计',
//     permId: 'M09SM01',
//     icon: 'icon-cheliangjiashihangweitongji1',
//   }
// };

// /**
//  * 报警总览
//  */
// export const AlarmOverview = {
//   path: '/AlarmOverview',
//   name: 'AlarmOverview',
//   component: () => import('@/views/AlarmOverview'),
//   meta: {
//     label: '报警总览',
//     permId: 'M09SM02',
//     icon: 'icon-baojingzonglan',
//   }
// };

// /**
//  * 报警处理报表
//  */
// export const AlarmHandle = {
//   path: '/AlarmHandle',
//   name: 'AlarmHandle',
//   component: () => import('@/views/AlarmHandle'),
//   meta: {
//     label: '报警处理',
//     permId: 'M09SM03',
//     icon: 'icon-baojingchuli',
//   }
// };

/**
 * 统计报表
 */
export const StatisticalReport = {
  path: "/StatisticalReport",
  name: "StatisticalReport",
  component: () =>
    import("@/views/SecurityMonitoring/StatisticalReport/index.vue"),
  meta: {
    label: "告警统计",
    permId: "M09SM04",
    icon: "icon-baojingchuli",
  },
};
export const commercialVehicleStatisticalReport = {
  path: "/commercialVehicleStatisticalReport",
  name: "commercialVehicleStatisticalReport",
  component: () =>
    import("@/views/SecurityMonitoring/comVehicleStatisticalReport/index.vue"),
  meta: {
    label: "商车报警统计",
    permId: "M09SM05",
    icon: "icon-baojingchuli",
  },
};
