// //安责保单列表
// export const safeDutyManagement = {
//   path: "/safeDutyManagement",
//   name: "safeDutyManagement",
//   component: () => import("@/views/safeDutyInsuredManagement/sdPolicy.vue"),
//   meta: {
//     label: "安责保单",
//     permId: "M20-sd",
//     icon: require("@/assets/images/safeDuty/menu/安责险保单管理.png"),
//   },
// };
// /**
//  * 安责保单 理赔案件
//  */
// export const safeDutyClaimManagement = {
//   path: "/safeDutyClaimManagement",
//   name: "safeDutyClaimManagement",
//   component: () => import("@/views/safeDutyInsuredManagement/sdClaim.vue"),
//   meta: {
//     label: "安责保单-理赔案件",
//     permId: "M20-sd",
//     icon: require("@/assets/images/safeDuty/menu/安责险理赔管理.png"),
//   },
// };

// //安责保单详情
// export const safeDutyManagementDetail = {
//   path: "/safeDutyManagementDetail",
//   name: "safeDutyManagementDetail",
//   component: () =>
//     import("@/views/safeDutyInsuredManagement/subPage/sdPolicyFormIndex.vue"),
//   meta: {
//     hideMenu: true,
//     label: "安责保单详情",
//     permId: "M20-sd",
//   },
// };
// //安责理赔详情
// export const safeDutyClaimDetail = {
//   path: "/safeDutyClaimDetail",
//   name: "safeDutyClaimDetail",
//   component: () =>
//     import("@/views/safeDutyInsuredManagement/subPage/sdClaimFormIndex.vue"),
//   meta: {
//     hideMenu: true,
//     label: "安责理赔详情",
//     permId: "M20-sd",
//   },
// };
//数据看板
export const safeDutyDataBoardIndex = {
  path: "/safeDutyDataBoardIndex",
  name: "safeDutyDataBoardIndex",
  component: () =>
    import("@/views/safeDutyInsuredManagement/dataBoardIndex.vue"),
  meta: {
    label: "数据看板",
    permId: "M20-sd-index",
    redirect: true,
    icon: require("@/assets/images/safeDuty/menu/数据看板.png"),
  },
};
//安责保险
export const safeDutyManagementIndex = {
  path: "/safeDutyManagementIndex",
  name: "safeDutyManagementIndex",
  component: () =>
    import("@/views/safeDutyInsuredManagement/sdPolicyIndex.vue"),
  meta: {
    label: "安责保险",
    permId: "M20-sd-index",
    icon: require("@/assets/images/safeDuty/menu/安责险保单管理.png"),
  },
};
//服务管理
export const safeDutyServiceManagementIndex = {
  path: "/safeDutyServiceManagementIndex",
  name: "safeDutyServiceManagementIndex",
  component: () =>
    import("@/views/safeDutyInsuredManagement/sdServiceIndex.vue"),
  meta: {
    label: "服务管理",
    permId: "M20-sd",
    icon: require("@/assets/images/safeDuty/menu/服务管理.png"),
  },
};
// //服务管理详情
// export const safeDutyServiceManagementDetail = {
//   path: "/safeDutyServiceManagement/safeDutyServiceManagementDetail",
//   name: "safeDutyServiceManagementDetail",
//   component: () =>
//     import("@/views/safeDutyInsuredManagement/subPage/serviceFormIndex.vue"),
//   meta: {
//     hideMenu: true,
//     label: "服务管理详情",
//     permId: "M20-sd",
//   },
// };
// //服务项目字典
// export const safeDutyServiceItem = {
//   path: "/safeDutyServiceItem",
//   name: "safeDutyServiceItem",
//   component: () =>
//     import("@/views/safeDutyInsuredManagement/sdServiceDict.vue"),
//   meta: {
//     label: "服务项目字典",
//     permId: "M20-sd",
//     icon: require("@/assets/images/safeDuty/menu/服务字典.png"),
//   },
// };
//企业管理
export const safeDutyEnterpriseManagement = {
  path: "/safeDutyEnterpriseManagement",
  name: "safeDutyEnterpriseManagement",
  component: () => import("@/views/safeDutyInsuredManagement/sdEnterprise.vue"),
  meta: {
    label: "企业管理",
    permId: "M20-sd-index",
    icon: require("@/assets/images/safeDuty/menu/企业管理.png"),
    ignoreKeepAlive: true,
  },
};
//企业管理详情
export const safeDutyEnterpriseManagementDetail = {
  path: "/safeDutyEnterpriseManagementDetail",
  name: "safeDutyEnterpriseManagementDetail",
  component: () =>
    import("@/views/safeDutyInsuredManagement/subPage/enterpriseFormIndex.vue"),
  meta: {
    label: "企业管理详情",
    permId: "M20-sd-index",
    hideMenu: true,
    ignoreKeepAlive: false,
  },
};
// //保司管理
// export const safeDutyComManagement = {
//   path: "/safeDutyComManagement",
//   name: "safeDutyComManagement",
//   component: () =>
//     import("@/views/safeDutyInsuredManagement/sdComInsurance.vue"),
//   meta: {
//     label: "保司管理",
//     permId: "M20-sd",
//     icon: require("@/assets/images/safeDuty/menu/保司管理.png"),
//   },
// };
// //保司管理详情
// export const safeDutyComManagementDetail = {
//   path: "/safeDutyComManagementDetail",
//   name: "safeDutyComManagementDetail",
//   component: () =>
//     import("@/views/safeDutyInsuredManagement/subPage/comFormIndex.vue"),
//   meta: {
//     label: "保司管理详情",
//     permId: "M20-sd",
//     hideMenu: true,
//   },
// };
// 专家管理
export const safeDutyExpertManagement = {
  path: "/safeDutyExpertManagement",
  name: "safeDutyExpertManagement",
  component: () => import("@/views/safeDutyInsuredManagement/sdExpert.vue"),
  meta: {
    label: "专家管理",
    permId: "M20-sd-expert",
    icon: require("@/assets/images/safeDuty/menu/专家管理.png"),
  },
};
//专家管理详情
export const safeDutyExpertManagementDetail = {
  path: "/safeDutyExpertManagementDetail",
  name: "safeDutyExpertManagementDetail",
  component: () =>
    import("@/views/safeDutyInsuredManagement/subPage/expertFormIndex.vue"),
  meta: {
    label: "专家管理详情",
    permId: "M20-sd-expert",
    hideMenu: true,
  },
};

//交通安责险
export const safeDutyTrafficIndex = {
  path: "/safeDutyTrafficIndex",
  name: "safeDutyTrafficIndex",
  component: () =>
    import("@/views/safeDutyInsuredManagement/traffic/index.vue"),
  meta: {
    label: "交通安责险",
    permId: "M20-sd-index",
    icon: require("@/assets/images/safeDuty/menu/交通安责.png"),
  },
};
