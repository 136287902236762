/**
 * 默认路由
 */

import handleManagementCenter from "./handleManagementCenter";
import handleQueryCenter from "./handleQueryCenter";
import handleMonitoringCenter from "./handleMonitoringCenter";
import handleSystemManagement from "./handleSystemManagement";
//import handleRiskDecrement from "./handleRiskDecrement";
import { myHome } from "@/router/modules/home";

import * as MonitoringCenter from "../modules/MonitoringCenter";
import * as ManagementCenter from "../modules/ManagementCenter";
import * as UpgradeManagement from "../modules/UpgradeManagement";

import * as QueryCenter from "../modules/QueryCenter";
import * as SystemCenter from "../modules/SystemCenter";
import * as BigDataCenter from "../modules/BigDataCenter";
import * as ExternalManagement from "../modules/ExternalManagement";
import * as RiskDecrement from "../modules/RiskDecrement";
import * as RiskControlDecrement from "../modules/RiskControlDecrement";
import * as PartsRetrieval from "../modules/PartsRetrieval";

// 添加子路由
handleMonitoringCenter(MonitoringCenter);
handleManagementCenter(ManagementCenter);
handleQueryCenter(QueryCenter);
handleSystemManagement(SystemCenter);
//handleRiskDecrement(RiskDecrement);

export const RootRoute = {
  path: "/",
  name: "Root",
  redirect: "/myHome",
  // TODO: 添加布局组件
  component: () => import("@/layout"),
  children: [
    ...Object.values(MonitoringCenter),
    ...Object.values(ManagementCenter),
    ...Object.values(UpgradeManagement),
    ...Object.values(QueryCenter),
    ...Object.values(SystemCenter),
    ...Object.values(BigDataCenter),
    ...Object.values(ExternalManagement),
    ...Object.values(RiskDecrement),
    ...Object.values(RiskControlDecrement),
    ...Object.values(PartsRetrieval),
    myHome,
  ],
};
