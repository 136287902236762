import loadScript from '@/utils/loadScript';
import ElIcon from './ElIcon.vue';
import Iconfont from './Iconfont.vue';

// 更新iconfont.cn `Symbol` 地址 
const iconfontUrl = '//at.alicdn.com/t/font_2926246_095wyhbdbqte.js';

// 加载`iconfont`图标
// https://iconfont.cn/help/detail?spm=a313x.7781069.1998910419.d8cf4382a&helptype=code
loadScript(iconfontUrl);


export {
  ElIcon,
  Iconfont,
};
