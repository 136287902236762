/**
 * 路由帮助
 */

/**
 * 添加子路由
 * 为`routeMenu`添加`subRoutes`子路由
 */
export function addSubRoutes(routeMenu, subRoutes) {
  if (!(routeMenu.children && Array.isArray(routeMenu.children))) {
    routeMenu.children = [];
  }
  subRoutes.forEach(route => {
    routeMenu.children.push(route);
  });
}


/**
 * 路由扁平化
 * @param {Array<RouteMenu>} routes 
 * @returns 
 */
export function flatRoutes(routes) {
  if (!Array.isArray(routes)) return [];
  return routes.flatMap(route => [
    route,
    ...flatRoutes(route.children),
  ]);
}

/**
 * 遍历路由表
 * @param {Array<RouteMenu>} routes 
 * @param {*} callback 
 * @returns 
 */
export function forEachRoutes(routes, callback, parent) {
  if (!Array.isArray(routes)) return;
  routes.forEach(route => {
    if (callback) callback(route, parent);
    forEachRoutes(route.children, callback, route);
  });
}

/**
 * 遍历路由表
 * @param {Array<RouteMenu>} routes 
 * @param {*} callback 
 * @returns 
 */
export function forEachRoutes2(routes, callback) {
  if (!Array.isArray(routes)) return;
  routes.forEach(route => {
    forEachRoutes(route.children);
    if (callback) callback(route);
  });
}

/**
 * 合并两个路由表
 * @param {*} targets 
 * @param {*} sources 
 * @returns 
 */
export function mergeRoutes(targets, sources = []) {
  if (sources.length === 0) return targets;

  const sourceArr = flatRoutes(sources);

  flatRoutes(targets)
    .forEach(route => {
      const route2 = sourceArr.find(p => p.name === route.name);
      if (!route2) return;
      const {
        meta,
        ...other
      } = route2;

      // 合并Meta
      route.meta = {
        ...route.meta,
        ...(meta || {}),
      };

      for (let key of Object.keys(other)) {
        // 排除 `name` `children` `component`, 不可改变
        if (['name', 'children', 'component'].includes(key)) continue;
        route[key] = other[key];
      }

    });
  return targets;
}
