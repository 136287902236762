/*
 * @Date: 2023-11-01 14:07:07
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/**
 * 外接页面展示中心路由
 */

/**
 * 零配件检索
 */
export const partsHomeManagement = {
   path: '/partsHomeManagement',
   name: 'partsHomeManagement',
   component: () => import('@/views/PartsRetrieval/partsHomeManagement'),
   meta: {
      label: '首页',
      name: 'partsHomeManagement',
      permId: 'LPJJS-home',
      // FXJLJK MTJGL
      icon: require('@/assets/images/header/零配件检索.png'),
   }
};
/**
 * 列表页
 */
export const partsRetrievalListPage = {
   path: '/partsRetrievalListPage',
   name: 'partsRetrievalListPage',
   component: () => import('@/views/PartsRetrieval/partsRetrievalListPage'),
   meta: {
      label: '列表页',
      name: 'partsRetrievalListPage',
      permId: 'LPJJS-list',
      // FXJLJK MTJGL
      icon: require('@/assets/images/header/零配件检索.png'),
   }
};
/**
 * 配件详情
 */
export const partsRetrievalDetails = {
   path: '/partsRetrievalDetails',
   name: 'partsRetrievalDetails',
   component: () => import('@/views/PartsRetrieval/partsRetrievalDetails'),
   meta: {
      label: '配件详情',
      name: 'partsRetrievalDetails',
      permId: 'LPJJS-details',
      hideMenu: true,
      // FXJLJK MTJGL
      icon: require('@/assets/images/header/零配件检索.png'),
   }
};

/**
 * 后台列表页
 */
export const partsRetrievalBackstageList = {
   path: '/partsRetrievalBackstageList',
   name: 'partsRetrievalBackstageList',
   component: () => import('@/views/PartsRetrieval/partsRetrievalBackstageList'),
   meta: {
      label: '后台页',
      name: 'partsRetrievalBackstageList',
      permId: 'LPJJS-background',
      // FXJLJK MTJGL
      icon: require('@/assets/images/header/零配件检索.png'),
   }
};

/**
 * 操作日志
 */
export const partsRetrievalOperationLog = {
   path: '/partsRetrievalOperationLog',
   name: 'partsRetrievalOperationLog',
   component: () => import('@/views/PartsRetrieval/partsRetrievalOperationLog'),
   meta: {
      label: '操作日志',
      name: 'partsRetrievalOperationLog',
      permId: 'LPJJS-operationLog',
      // FXJLJK MTJGL
      icon: require('@/assets/images/header/零配件检索.png'),
   }
};

/**
 * 供应商管理
 */
export const partsRetrievalSupplierList = {
   path: '/partsRetrievalSupplierList',
   name: 'partsRetrievalSupplierList',
   component: () => import('@/views/PartsRetrieval/partsRetrievalSupplierList'),
   meta: {
      label: '供应商管理',
      name: 'partsRetrievalSupplierList',
      permId: 'LPJJS-supplier',
      // FXJLJK MTJGL
      icon: require('@/assets/images/header/零配件检索.png'),
   }
};
