/**
 * 权限验证
 */

import store from "@/store";

/**
 * perms: 指定的权限，单个或多个
 * isAnd: 0：是否有perms中的一个权限；1:是否有perms中所有权限
 */
export const hasPerms = (perms = [], isAnd = 0) => {
  try {
    const { state } = store;
    const user = state.userInfo;
    const userPermList = state.userPermissions;
    let m = ["some", "every"][isAnd];
    if (!(perms instanceof Array)) perms = [perms];
    if (!user) return false;
    //accountType 0:平台用户；1：监管用户；
    //平台管理员(userId=1)及拥有客户代码级别的用户(parentId=1)不需要验证权限
    return userPermList && userPermList[m]((p) => perms.includes(p));
  } catch (err) {
    console.error(err);
  }
  return false;
};

/**
 * key: 指定的权限
 * perms: 指定的数据权限
 * isAnd: 0：是否有perms中的一个权限；1:是否有perms中所有权限
 */
export const dataPermissions = (key = null, perms = "", isAnd = 0) => {
  try {
    const { state } = store;
    // const user = state.userInfo;
    const userPermList = state.userDataPermissions;
    if (key && userPermList[key] && perms) {
      return !userPermList[key].includes(perms);
    } else if (userPermList[key] === "") {
      return true;
    } else {
      return false;
    }
  } catch (err) {
    console.error(err);
  }
  return false;
};

//权限生成工具(还没写完)
export const policyPermissionGeneration = (listOrObj, pm) => {
  let resObj = {};
  if (listOrObj instanceof Array) {
    if (listOrObj[0] instanceof Object) {
      policyPermissionGeneration(listOrObj[0], pm);
    } else {
      nameList.forEach((item) => {
        resObj[item] = dataPermissions(pm, item);
      });
    }

    return listOrObj;
  } else if (listOrObj instanceof Object) {
    let res={};
    Object.keys(listOrObj).forEach((key) => {
      res[key] = policyPermissionGeneration(key, pm);
    });
    return { ...resObj, ...res };
  } else {
    resObj.listOrObj = dataPermissions(pm, listOrObj);
    return resObj;
  }
};

// 密码校验
export const inspectionPassword = (password) => {
  const isValid = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*#?&]).{8,}$/.test(password);
  return isValid;
};

// 密码最小长度为8位，密码由数字、字母和特殊字符组合
export const validatePassword = (password) => {
  const minLength = 8;
  const hasNumber = /[0-9]/.test(password);
  const hasLowercaseLetter = /[a-z]/.test(password);
  const hasSpecialChar = /[^0-9a-zA-Z]/.test(password);
  const tszf = /[\`\~\!\@\#\$\%\^\&\*\(\)\_\+\-\=\{\}\|\[\]\:\;\'\<\>\?\,\.]+/.test(password);
 
  const isValid =
    password.length >= minLength &&
    hasNumber &&
    hasLowercaseLetter &&
    hasSpecialChar && tszf;
 
  return isValid;
}

export const  generatePassword = (length = 8) => {
  
      // 定义一个空数组保存我们的密码
      let passArrItem = [];
 
      // 定义获取密码成员的方法
      const getNumber = () => Math.floor(Math.random() * 10); // 0~9的数字
      const getUpLetter = () => String.fromCharCode(Math.floor(Math.random() * 26) + 65); // A-Z
      const getLowLetter = () => String.fromCharCode(Math.floor(Math.random() * 26) + 97); // a-z
      const zfcIndex = Math.floor(Math.random() * 20);
      const getLowLetter2 = () => '@$!%*#?&~`/\=+-_*&^.'.slice(zfcIndex, zfcIndex + 1); // zf
      // 将获取成员的方法保存在一个数组中方便用后面生成的随机index取用
      const passMethodArr = [getNumber, getUpLetter, getLowLetter, getLowLetter2];
 
      // 随机index
      const getIndex = () => Math.floor(Math.random() * 4);
 
      // 从0-9，a-z，A-Z中随机获取一项
      const getPassItem = () => passMethodArr[getIndex()]();
 
      // 不多解释
      Array(length - 4).fill('').forEach(() => {
        passArrItem.push(getPassItem());
      })
 
      const confirmItem = [getNumber(), getUpLetter(), getLowLetter(),getLowLetter2()];
      // 加上我们确认的三项，从而使生成的密码，大写字母、小写字母和数字至少各包含一个
      passArrItem.push(...confirmItem);
 
      // 转为字符串返回
      return passArrItem.join('');
}
