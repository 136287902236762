<script>
/**
 * 封装ResizeObserver组件
 */

import ResizeObserver from 'resize-observer-polyfill';

export default {
  name: 'ResizeObserver',
  data() {
    // type: ResizeObserver
    this.resizeObserver = null;
    this.oldElement = null;
    return {
    };
  },
  mounted() {
    this.createResizeObserver(this.$el);
  },
  beforeUpdate() {
    this.oldElement = this.$el;
  },
  updated() {
    if (this) {
      if (this.$el !== this.oldElement) {
        if (this.resizeObserver) this.destroyResizeObserver();
        this.createResizeObserver(this.$el);
      }
    } else {
      this.destroyResizeObserver();
    }
  },
  beforeDestroy() {
    this.destroyResizeObserver();
  },
  methods: {
    createResizeObserver(target /** HTMLElement */) {
      if (!target) return;

      this.resizeObserver = new ResizeObserver(
        (entries /**  ResizeObserverEntry[] */) => {
          const entry = entries[0];
          this.$emit('resize', entry);
        });
      this.resizeObserver.observe(target);
    },
    destroyResizeObserver() {
      if (this.resizeObserver) {
        this.resizeObserver.disconnect();
        this.resizeObserver = null;
      }
    }
  },
  render() {
    const slots = this.$slots;
    const children = slots.default;
    return children && children.length ? children[0] : null;
  }
}
</script>