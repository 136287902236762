/**
 * myHome
 */
export const myHome = {
  path: '/myHome',
  name: 'myHome',
  component: () => import('@/views/Home'),
  meta: {
    label: '首页',
    permId: 'home',
    icon: [
      'icon-dapingzhanshi',
      require('@/assets/images/header/大屏展示.png')
    ],
    affix: true,
  }
};