/*
 * @Author: zhuchaowei 952698119@qq.com
 * @Date: 2022-06-27 16:07:07
 * @LastEditors: zhuchaowei 952698119@qq.com
 * @LastEditTime: 2022-06-29 14:18:54
 * @FilePath: \platform-pc-front\src\router\modules\ManagementCenter.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/**
 * 管理中心路由
 */

/**
 * 规则管理
 */
export const RuleManagement = {
  path: "/RuleManagement",
  name: "RuleManagement",
  component: () => import("@/views/RuleManagement"),
  meta: {
    label: "规则管理",
    permId: "M11",
    icon: [
      "icon-a-guizeguanli2",
      require("@/assets/images/header/规则管理.png"),
    ],
  },
};

/**
 * 资料管理
 */
export const DataManagement = {
  path: "/DataManagement",
  name: "DataManagement",
  component: () => import("@/views/DataManagement"),
  meta: {
    label: "资料管理",
    permId: "M12",
    icon: ["icon-ziliaoguanli", require("@/assets/images/header/资料管理.png")],
  },
};

/**
 * 升级管理
 */
export const UpgradeManagement = {
  path: "/UpgradeManagement",
  name: "UpgradeManagement",
  component: () => import("@/views/UpgradeManagement"),
  meta: {
    label: "升级管理",
    permId: "M12",
    icon: [
      "https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/upgrade.png",
      require("@/assets/images/header/升级管理.png"),
    ],
  },
};

/**
 * 安全管理
 */
export const safetyManagement = {
  path: "/safetyManagement",
  name: "safetyManagement",
  component: () => import("@/views/safetyManagement"),
  meta: {
    label: "安全管理",
    permId: "M12",
    icon: [
      "icon-anquanguanli2",
      require("@/assets/images/header/升级管理.png"),
    ],
  },
};

/**
 * 安全管理
 */
// export const AuditManagement = {
//   path: '/AuditManagement',
//   name: 'AuditManagement',
//   component: () => import('@/views/AuditManagement'),
//   meta: {
//     label: '审核管理',
//     permId: 'M27',
//     icon: [
//       'icon-anquanguanli2',
//       require('@/assets/images/header/升级管理.png')
//     ],
//   },
// };

/**
 * 案件管理
 */
export const claimManagement = {
  path: "/routerClaimManagement",
  name: "routerClaimManagement",
  component: () => import("@/views/routerClaimManagement"),
  meta: {
    label: "案件管理",
    permId: "M21-View",
    icon: [
      require("@/assets/images/claimManagement.png"),
      require("@/assets/images/header/claimHead.png"),
    ],
    affix: true,
  },
};
/**
 * 保单管理
 */
export const policyManagement = {
  path: "/routerPolicyManagement",
  name: "routerPolicyManagement",
  component: () => import("@/views/routerPolicyManagement"),
  meta: {
    label: "保单管理",
    permId: "M20-View",
    icon: [
      require("@/assets/images/policyManagement.png"),
      require("@/assets/images/header/policyHead.png"),
    ],
    affix: true,
  },
};

/**
 * 测算管理
 */
export const calculateManagement = {
  path: "/routerCalculateManagement",
  name: "routerCalculateManagement",
  component: () => import("@/views/routerCalculateManagement"),
  meta: {
    label: "测算管理",
    permId: "M30",
    icon: [
      require("@/assets/images/policyManagement.png"),
      require("@/assets/images/header/policyHead.png"),
    ],
  },
};

/**
 * 分销中心
 */
export const Distribution = {
  path: "/routerDistribution",
  name: "routerDistribution",
  component: () => import("@/views/routerDistribution"),
  meta: {
    label: "分销中心",
    permId: "M31",
    icon: [
      require("@/assets/images/policyManagement.png"),
      require("@/assets/images/header/policyHead.png"),
    ],
  },
};

/**
 * 安全监控
 */
export const securityMonitoring = {
  path: "/SecurityMonitoring",
  name: "SecurityMonitoring",
  component: () => import("@/views/SecurityMonitoring"),
  meta: {
    label: "安全监控",
    permId: "M09",
    icon: [
      "icon-anquanjiankong",
      require("@/assets/images/header/安全监控.png"),
    ],
    affix: true,
  },
};

/**
 * 零配件检索
 */
export const PartsManagement = {
  path: "/routerPartsRetrieval",
  name: "routerPartsRetrieval",
  component: () => import("@/views/routerPartsRetrieval"),
  meta: {
    label: "配件检索",
    permId: "LPJJS",
    // FXJLJK MTJGL
    icon: [
      "icon-dapingzhanshi",
      require("@/assets/images/header/零配件检索.png"),
    ],
    affix: true,
  },
};

/**
 * 项目管理
 */
export const ProjectManagement = {
  path: "/routerProjectManagement",
  name: "routerProjectManagement",
  component: () => import("@/views/routerProjectManagement"),
  meta: {
    label: "项目管理",
    permId: "project",
    icon: ["icon-dapingzhanshi", require("@/assets/images/header/xmgl.png")],
    affix: true,
  },
};

/**
 * 绩效管理
 */
export const PerformanceManagement = {
  path: "/routerPerformanceManagement",
  name: "routerPerformanceManagement",
  component: () => import("@/views/routerPerformanceManagement"),
  meta: {
    label: "绩效管理",
    permId: "performance",
    icon: ["icon-dapingzhanshi", require("@/assets/images/header/jxgl.png")],
    affix: true,
  },
};

// /**
//  * 运营管理 TODO: 暂时不做
//  */
// export const OperationManagement = {
//   path: '/OperationManagement',
//   name: 'OperationManagement',
//   component: () => import('@/views/OperationManagement'),
//   meta: {
//     label: '运营管理',
//     icon: 'icon-yunyingguanli',
//   }
// };

// 安全责任保险管理
export const safeDutyInsuredManagement = {
  path: "/safeDutyInsuredManagement",
  name: "safeDutyInsuredManagement",
  component: () => import("@/views/safeDutyInsuredManagement/index.vue"),
  meta: {
    label: "安责管理",
    permId: "M20-sd",
    icon: [
      require("@/assets/images/safeDuty/menu/安责险管理.png"),
      require("@/assets/images/header/安责.png"),
    ],
  },
};
