/**
 * 充值页面路由
 * type: RouteMenu
 */
 export const Recharge = {
  path: '/Recharge',
  name: 'Recharge',
  component: () => import('@/views/Recharge'),
  meta: {
    label: '充值',
    permId: 'M19',
  }
};