/**
 * 系统管理路由
 */

export const ClientManagement = {
  path: '/ClientManagement',
  name: 'ClientManagement',
  component: () => import('@/views/SystemManagement/ClientManagement'),
  meta: {
    label: '租户管理',
    permId: 'M22-client',
    icon: require('@/assets/images/资料管理导航/client.png'),
  }
};

export const OrganizationalStructure = {
  path: '/OrganizationalStructure',
  name: 'OrganizationalStructure',
  component: () => import('@/views/SystemManagement/OrganizationalStructure'),
  meta: {
    label: '组织架构',
    ignoreKeepAlive: true,
    permId: 'M22-architecture',
    icon: require('@/assets/images/资料管理导航/architecture.png'),
  }
};
/**
 * 角色管理
 */
export const RoleManagement = {
  path: '/RoleManagement',
  name: 'RoleManagement',
  component: () => import('@/views/SystemManagement/RoleManagement'),
  meta: {
    label: '角色管理',
    permId: 'M22-role',
    icon: require('@/assets/images/资料管理导航/role.png'),
  }
};

export const StaffManagement = {
  path: '/StaffManagement',
  name: 'StaffManagement',
  component: () => import('@/views/SystemManagement/StaffManagement'),
  meta: {
    label: '员工管理',
    permId: 'M22-staff',
    icon: require('@/assets/images/资料管理导航/staff.png'),
  }
};

export const CustomerManagement = {
  path: '/CustomerManagement',
  name: 'CustomerManagement',
  component: () => import('@/views/SystemManagement/CustomerManagement'),
  meta: {
    label: '用户管理',
    permId: 'M22-customer',
    icon: require('@/assets/images/资料管理导航/CustomerManagement.png'),
  }
};

/**
 * 权限设置
 */
export const SetPermissions = {
  path: '/SetPermissions/:id?/:name?/:tenantId?',
  name: 'SetPermissions',
  component: () => import('@/views/SystemManagement/SetPermissions'),
  meta: {
    label: '权限设置',
    permId: 'M22-role',
    icon: 'icon-a-yonghuguanli1',
    hideMenu: true
  }
};

export const DictionariesManagement = {
  path: '/DictionariesManagement/:id?/:code?',
  name: 'DictionariesManagement',
  component: () => import('@/views/SystemManagement/DictionariesManagement'),
  meta: {
    label: '字典管理',
    permId: 'M22-dictionaries',
    icon: require('@/assets/images/资料管理导航/staff.png'),
  }
};

export const AgentManagement = {
  path: '/AgentManagement',
  name: 'AgentManagement',
  component: () => import('@/views/SystemManagement/AgentManagement'),
  meta: {
    label: '代理人管理',
    permId: 'M22-agent',
    icon: require('@/assets/images/资料管理导航/staff.png'),
  }
};

export const LoginLog = {
  path: '/LoginLog',
  name: 'LoginLog',
  component: () => import('@/views/SystemManagement/LogManagement/LoginLog'),
  meta: {
    label: '登录日志',
    permId: 'M22-loginLog',
    icon: require('@/assets/images/资料管理导航/loginLog.png'),
  }
};
export const ActionLog = {
  path: '/ActionLog',
  name: 'ActionLog',
  component: () => import('@/views/SystemManagement/LogManagement/ActionLog'),
  meta: {
    label: '操作日志',
    permId: 'M22-actionLog',
    icon: require('@/assets/images/资料管理导航/actionLog.png'),
  }
};
export const CustomerService = {
  path: '/CustomerService',
  name: 'CustomerService',
  component: () => import('@/views/SystemManagement/CustomerService'),
  meta: {
    label: '客服管理',
    permId: 'M22-customerService',
    icon: require('@/assets/images/资料管理导航/customerService.png'),
  }
};
export const ClaimArea = {
  path: '/ClaimArea',
  name: 'ClaimArea',
  component: () => import('@/views/SystemManagement/ClaimArea'),
  meta: {
    label: '理赔区域管理',
    permId: 'M22-claimArea',
    icon: require('@/assets/images/资料管理导航/customerService.png'),
  }
};

export const information = {
   path: '/information',
   name: 'information',
   component: () => import('@/views/SystemManagement/information'),
   meta: {
     label: '干货资讯',
     permId: 'M22-information',
     icon: require('@/assets/images/资料管理导航/informationList.png'),
   },
   children: [
      {
         path: '/informationType',
         name: 'informationType',
         component: () => import('@/views/SystemManagement/information/informationType.vue'),
         meta: {
         label: '文章分类',
         permId: 'M22-informationType',
         icon: require('@/assets/images/资料管理导航/informationType.png'),
         }
      },
      {
         path: '/informationList',
         name: 'informationList',
         component: () => import('@/views/SystemManagement/information/informationList.vue'),
         meta: {
         label: '文章管理',
         permId: 'M22-informationList',
         icon: require('@/assets/images/资料管理导航/informationList.png'),
         }
      }
   ]
 };
