/**
 * 加密解密
 */


import aesjs from 'aes-js';
import { rmCtrlChar } from '@/utils/crypt';
const iyr = `t,o,k,e,n,=,8,6,d,8,4,d,8,c,-,6`.split(',').join('');

/**
 * aes-ecb加密
 * @param {*} enStr 需要加密的字符串
 */
export const encrypt = (enStr, ek) => {
  enStr += '';
  try {
    const iyrBuffer = aesjs.utils.utf8.toBytes(ek || iyr);
    const inputBuffer = aesjs.padding.pkcs7.pad(aesjs.utils.utf8.toBytes(enStr));
    const escEcb = new aesjs.ModeOfOperation.ecb(iyrBuffer);
    const encryptedBytes = escEcb.encrypt(inputBuffer);
    const encryptedData = Buffer.from(encryptedBytes).toString('base64');
    return encryptedData;
  } catch (err) {
    console.error(err);
  }
  return enStr;
};

/**
 * aes-ecb解密
 * @param {*} deStr 需要解密的字符串
 */
export const decrypt = (deStr, ek) => {
  deStr = decodeURIComponent(deStr || '');
  try {
    const iyrBuffer = aesjs.utils.utf8.toBytes(ek || iyr);
    const escEcb = new aesjs.ModeOfOperation.ecb(iyrBuffer);
    const buf = Buffer.from(deStr, 'base64');
    const decryptedBytes = escEcb.decrypt(buf);
    const decryptedText = aesjs.utils.utf8.fromBytes(decryptedBytes);
    return rmCtrlChar(decryptedText);
  } catch (err) {
    console.error(err);
  }
  return deStr;
};