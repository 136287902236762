export const InsuredScheme = {
   path: '/InsuredSchemeManage',
   name: 'InsuredSchemeManage',
   component: () => import('@/views/InsuredScheme/InsuredSchemeManage/index'),
   meta: {
      label: '承保方案',
      permId: 'M20-scheme',
      icon: require('@/assets/images/claimCase.png'),
   }
};
export const ClauseAllocation = {
   path: '/ClauseAllocation',
   name: 'ClauseAllocation',
   component: () => import('@/views/InsuredScheme/ClauseAllocation/index'),
   meta: {
      label: '条款配置',
      permId: 'M20-scheme',
      icon: require('@/assets/images/claimCase.png'),
   }
};
export const ClauseDocument = {
   path: '/ClauseDocument',
   name: 'ClauseDocument',
   component: () => import('@/views/InsuredScheme/ClauseDocument/index'),
   meta: {
      label: '条款文件',
      permId: 'M20-scheme',
      icon: require('@/assets/images/claimCase.png'),
   }
};

export const AddInsuredScheme = {
   path: '/AddInsuredScheme',
   name: 'AddInsuredScheme',
   component: () => import('@/views/InsuredScheme/InsuredSchemeManage/addInsuredScheme'),
   meta: {
      label: '添加承保方案',
      permId: 'M20-scheme',
      hideMenu: true,
      icon: require('@/assets/images/claimCase.png'),
   }
};