//工程机械保险重复审核
export const ConstructionMachineryAudit = {
   path: '/ConstructionMachineryAudit',
   name: 'ConstructionMachineryAudit',
   component: () => import('@/views/policyManagement/policyAudit/ConstructionMachineryAudit'),
   meta: {
      label: '工程机械保险重复审核',
      permId: 'M20-RepeatAudit-ConstructionMachineryAudit',
      icon: require('@/assets/images/policyInquiry.png'),
      affix: true,
      policyType: 1,
      policyRoute: 'ConstructionMachinery'
   }
};

//车险重复审核
export const carInsuranceAudit = {
   path: '/carInsuranceAudit',
   name: 'carInsuranceAudit',
   component: () => import('@/views/policyManagement/policyAudit/carInsuranceAudit'),
   meta: {
      label: '车险重复审核',
      permId: 'M20-RepeatAudit-carInsuranceAudit',
      icon: require('@/assets/images/policyInquiry.png'),
      affix: true,
      policyType: 2,
      policyRoute: 'CarInsurance'
   }
};

//非设备类财产险重复审核
export const insuranceAudit = {
   path: '/insuranceAudit',
   name: 'insuranceAudit',
   component: () => import('@/views/policyManagement/policyAudit/insuranceAudit'),
   meta: {
      label: '非设备类财产险重复审核',
      permId: 'M20-RepeatAudit-insuranceAudit',
      icon: require('@/assets/images/policyInquiry.png'),
      affix: true,
      policyType: 3,
      policyRoute: 'Insurance'
   }
};

// 保单重复审核设置
export const setPolicyRepeatAudit = {
   path: '/setPolicyRepeatAudit',
   name: 'setPolicyRepeatAudit',
   component: () => import('@/views/SystemManagement/setPolicyRepeatAudit'),
   meta: {
     label: '保单重复审核设置',
     permId: 'M20-RepeatAudit-Set',
     icon: require('@/assets/images/资料管理导航/customerService.png'),
   }
 };