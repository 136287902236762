/**
 * 登录页面路由
 * type: RouteMenu
 */
export const Login = {
  path: '/login',
  name: 'Login',
  component: () => import('@/views/Login'),
  meta: {
    label: '登录',
    ignoreAuth: true,
    ignoreKeepAlive: true,
  }
};

// 中国石油视频轮询
export const foreignVideoPolling = {
  path: '/foreignVideoPolling',
  name: 'foreignVideoPolling',
  component: () => import('@/views/Petroleum/foreignVideoPolling'),
  meta: {
    label: '中国石油视频轮询',
    ignoreAuth: true,
    ignoreKeepAlive: true,
  }
};

// 中国石油-大屏视频轮询
export const foreignBigScreenDisplayVideoPolling = {
  path: '/foreignBigScreenDisplayVideoPolling',
  name: 'foreignBigScreenDisplayVideoPolling',
  component: () => import('@/views/Petroleum/foreignBigScreenDisplayVideoPolling'),
  meta: {
    label: '中国石油视频轮询',
    ignoreAuth: true,
    ignoreKeepAlive: true,
  }
};

