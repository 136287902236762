import { createMJHttp } from '@/api/MJRequest';
import { createHttp } from '@/api/HttpHelper';

const SystemAxios = createMJHttp('http://112.74.168.234:27961/api');
const axios = createHttp('http://112.74.168.234:8088');
const post = (action, data, options) => axios.post(`monitor/${ action }.do`, data, {
   headers: { "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8" },
   ...options
});

// 登录
export const bladeAuthZSY = (data) => {
   return SystemAxios.axios({
      url: '/blade-auth/token',
      method: 'post',
      headers: {
         'Authorization': 'Basic c2FiZXI6c2FiZXJfc2VjcmV0'
      },
      ignoreAuth: true,
      data: data
   });
};

/**
 * 获取车组数据
 */
export const loadGroups = (userId) => {
   return axios.get('vehicleGroup/getGroupsByRds.do', { params: { platform: 'PC', userId } });
};

/**
 * 加载车辆信息
 * @param {*} groupIds
 * @param {*} pmt
 * @param {*} vmt
 */
// export const loadVehicles = (groupIds, pmt = 0, vmt = 0) => get('loadVehicles', { params: { ids: groupIds.join(','), posTime: pmt, vehicleTime: vmt } })
export const loadVehicles = ({ groupIds, pmt = 0, vmt = 0, state = 0, pageNumber = 0, pageSize = 0 }) => post('loadVehicles', `ids=${ groupIds.join(',') }&posTime=${ pmt }&vehicleTime=${ vmt }&state=${ state }&pageNumber=${ pageNumber }&pageSize=${ pageSize }`);