/**
 * 资料管理路由
 */

/**
 * 用户管理
 */
export const UserManagement = {
  path: "/UserManagement",
  name: "UserManagement",
  component: () => import("@/views/UserManagement"),
  meta: {
    label: "用户管理",
    permId: "M12SM01",
    icon: "icon-a-yonghuguanli1",
  },
};

/**
 * 车辆管理
 */
export const VehicleManagement = {
  path: "/VehicleManagement",
  name: "VehicleManagement",
  component: () => import("@/views/VehicleManagement"),
  meta: {
    label: "机械列表",
    permId: "M12SM02_view",
    icon: require("@/assets/images/资料管理导航/机械列表.png"),
  },
};

// 终端管理
export const TerminalManagement = {
  path: "/TerminalManagement",
  name: "TerminalManagement",
  component: () => import("@/views/TerminalManagement"),
  meta: {
    label: "终端管理",
    permId: "M12SM06_view",
    icon: require("@/assets/images/资料管理导航/终端列表.png"),
  },
};

// 产品版本
export const ProductVersion = {
  path: "/ProductVersionManagement",
  name: "ProductVersionManagement",
  component: () => import("@/views/ProductVersionManagement"),
  meta: {
    label: "产品版本",
    permId: "M12SM07_view",
    icon: require("@/assets/images/资料管理导航/产品版本.png"),
  },
};

/**
 * 驾驶员管理
 */
export const DriverManagement = {
  path: "/DriverManagement",
  name: "DriverManagement",
  component: () => import("@/views/DriverManagement"),
  meta: {
    label: "驾驶员管理",
    permId: "M12SM03",
    icon: "icon-jiashiyuanguanli",
  },
};

/**
 * 批量导入
 */
export const BatchImport = {
  path: "/BatchImport",
  name: "BatchImport",
  component: () => import("@/views/BatchImport"),
  meta: {
    label: "批量导入",
    permId: "M12SM04",
    icon: "icon-piliangdaoru",
  },
};

/**
 * 车辆授权
 */
export const VehicleAuthorization = {
  path: "/VehicleAuthorization",
  name: "VehicleAuthorization",
  component: () => import("@/views/VehicleAuthorization"),
  meta: {
    label: "车辆授权",
    permId: "M12SM05",
    icon: "icon-cheliangshouquan",
  },
};

/**
 * 理赔管理
 */
