/**
 * 封装缓存统一函数
 */


import dayjs from 'dayjs';

// 缓存超时timerId s
const timerMap = new Map();

function joinKey(key) {
  return `__yili_${key}`;
}


/**
 * 写缓存
 * @param {*} key 
 * @param {*} value 
 * @param {*} expiredMs 过期时间 毫秒
 */
export function setLocalStorage(key, value, expiredMs = 0) {
  const fullKey = joinKey(key);
  const content = {
    value,
    time: dayjs().format('YYYY-MM-DD HH:mm:ss'),
    expiredMs: expiredMs,
  };
  localStorage.setItem(fullKey, JSON.stringify(content));
}

// 读缓存
export function getLocalStorage(key) {
  const fullKey = joinKey(key);
  const stringContent = localStorage.getItem(fullKey);
  if (!stringContent) return '';
  const { value, time } = JSON.parse(stringContent);
  return value;
}

// 删除缓存
export function rmLocalStorage(key) {
  const fullKey = joinKey(key);
  localStorage.removeItem(fullKey);

  // 移除超时定时器句柄
  if (timerMap.has(fullKey)) {
    timerMap
      .get(fullKey)
      .forEach(timerId => {
        clearTimeout(timerId);
      });
  }
  timerMap.delete(fullKey);
}

/**
 * 缓存超时处理
 * @param {*} key 
 * @param {*} expiredMs 过期时间, 从写入缓存开始计算 毫秒
 * @returns Promise<timeout>
 */
export function cacheExpired(key, expiredMs) {
  const fullKey = joinKey(key);
  const stringContent = localStorage.getItem(fullKey);
  let timerIds = timerMap.get(fullKey) || [];

  return new Promise((resolve, reject) => {
    if (!stringContent) return reject('not found key');

    const cacheObj = JSON.parse(stringContent);
    // 写入缓存时间
    const time = cacheObj.time;
    expiredMs = expiredMs || cacheObj.expiredMs;

    // 不判断过期
    if (expiredMs === 0) return resolve(0);

    // 计算出过期时间
    const expiredTime = dayjs(time).add(expiredMs, 'ms');
    // 超时时间
    const timeout = expiredTime.diff(dayjs());

    const timerId = setTimeout(() => {

      clearTimeout(timerId);
      // 移除已过期的时间句柄
      timerIds = timerIds.filter(id => id !== timerId);
      timerMap.set(fullKey, timerIds);

      resolve(timeout);
    }, timeout);

    timerIds.push(timerId);
    timerMap.set(fullKey, timerIds);
  });
};
