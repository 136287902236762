/**
 * 帮助页面路由
 * type: RouteMenu
 */
export const Help = {
  path: '/Help',
  name: 'Help',
  component: () => import('@/views/Help'),
  meta: {
    label: '帮助',
    ignoreAuth: true,
    ignoreKeepAlive: true,
  }
};