//#region example
// const treeData = genTreeData();
// const list = treeToArray(treeData, -1, { id: 'key', parentId: 'parentKey' });
// const treeData2 = arrayToTree(list, { id: 'key', parentId: 'parentKey', children: 'childs' });
//#endregion


/**
 * 数组结构转为tree结构
 * @param {*} list
 * @param {*} fieldNames.id
 * @param {*} fieldNames.parentId 父Id
 * @param {*} fieldNames.children 子节点属性名称
 */
export function arrayToTree(
  list = [],
  fieldNames = {}) {
  const {
    id = 'id',
    parentId = 'parentId',
    children = 'children',
  } = fieldNames;

  // 存储根节点
  const rootTree = [];
  const treeMap = new Map(list.map(node => [node[id], node]));
  const cloneList = [...list];

  while (cloneList.length) {
    const treeNode = cloneList.shift();

    // 根节点
    if (!treeMap.has(treeNode[parentId])) {
      rootTree.push(treeNode);
      continue;
    }

    const parentNode = treeMap.get(treeNode[parentId]);
    if (!Array.isArray(parentNode[children])) {
      parentNode[children] = [];
    }
    parentNode[children].push(treeNode);
  }

  treeMap.clear();

  return rootTree;
}

/**
 *
 * @param {} treeData
 * @param {*} fieldNames
 */
/**
 * 树结构转为数组
 * @param {*} treeData
 * @param {*} rootParentId 根节点的父节点Id的默认值
 * @param {*} fieldNames
 * @returns
 */
export function treeToArray(treeData, rootParentId = null, fieldNames = {}) {
  const {
    id = 'id',
    parentId = 'parentId',
    children = 'children',
  } = fieldNames;

  function flatTree(tree, parentNode) {
    if (!Array.isArray(tree)) return [];

    return tree.flatMap((treeNode) => {

      const subNodes = treeNode[children];
      delete treeNode[children];

      treeNode[parentId] = parentNode[id];

      return [
        treeNode,
        ...flatTree(subNodes, treeNode),
      ];
    });
  }


  return flatTree(treeData, {
    [id]: rootParentId
  });
}

/**
 * 递归遍历
 */
export function depthTree(data, callback, children = 'children') {

  function recursion(tree, parentNode = null, level = 0) {
    if (!Array.isArray(tree)) return;

    for (let i = 0; i < tree.length; i++) {
      const treeNode = tree[i];

      // 回调返回`true`, 则停止向下递归子节点
      if (callback && callback(treeNode, parentNode, i, level)) continue;

      recursion(treeNode[children], treeNode, level + 1);
    }
  }
  recursion(data, null, 0);
}

/**
 * tree结构筛选
 */
export function filterTree(data, callback, children = 'children') {

  function recursion(tree) {
    if (!Array.isArray(tree)) return [];

    const treeDatas = [];
    for (let i = 0; i < tree.length; i++) {
      const treeNode = tree[i];

      if (callback && callback(treeNode)) {
        treeDatas.push(treeNode);

        const subNodes = recursion(treeNode[children]);

        if (subNodes && subNodes.length) {
          treeNode[children] = subNodes;
        }
      }
    }
    return treeDatas;
  }
  return recursion(data);
}


/**
 * 将tree结构扁平化
 */
export function flatTree(data, children = 'children') {
  if (!Array.isArray(data)) return [];
  return data.flatMap(treeNode => [
    treeNode,
    ...flatTree(treeNode[children]),
  ]);
}


/**
 * 按照根节点子节点排序
 */
export function arrayTreeSort(list = [], rootParentId = null, fieldNames = {}) {
  return treeToArray(arrayToTree(list, fieldNames), rootParentId, fieldNames);
}


/**
 * 生成测试数据
 */
export function genTreeData(path = '1', level = 4) {

  const list = [];
  for (let i = 1; i <= 3; i += 1) {
    const key = `${path}-${i}`;
    const treeNode = {
      label: key,
      key: key,
    };

    if (level > 0) {
      treeNode.children = genTreeData(key, level - 1);
    }

    list.push(treeNode);
  }
  return list;
}

/**
 * 生成测试数据
 */
export function genArrayTree(parentKey = null, path = '1', level = 4, count = 4) {
  const list = [];
  for (let i = 1; i <= count; i += 1) {
    const key = `${path}-${i}`;
    const treeNode = {
      label: key,
      key: key,
      parentKey,
    };

    list.push(treeNode);
    if (level > 0) {
      const children = genArrayTree(key, key, level - 1, count);
      list.push(...children);
    }
  }
  return list;
}


// 通过车组Id, 车辆Id, 通道号组成唯一ID
export function getTreeNodeId(groupId = '', vehicleId = '', channel = '') {
  return ['gvId', groupId, vehicleId, channel].join('-');
}

// 节点类型
export const TreeNodeType = {
  // 车组
  isGroup: 1,
  // 车辆
  isVehicle: 2,
  // 通道
  isChannel: 3,
};