function onInput(el, ele, binding, vnode) {
   function handle() {
      let val = ele.value;
      // modifiers为修饰符对象，传入了float，则其float属性为true
      if (binding.modifiers.float) {
         // 清除"数字"和"."以及"-"以外的字符
         val = val.replace(/[^\-\d.]/g, '');
         // 只保留第一个'-'号
         val = val.replace(/\.{2,}/g, '.').replace(/\-{2,}/g, '-');
         // 将 '-.' 替换成 '-0.'
         val = val.replace(/^\./g, '0.').replace(/^\-\./, '-0.');
         if (typeof binding.value !== 'undefined') {
            // 期望保留的最大小数位数
            let pointKeep = 0;
            if (typeof binding.value === 'string'
               || typeof binding.value === 'number') {
               pointKeep = parseInt(binding.value);
            }
            if (!isNaN(pointKeep)) {
               if (!Number.isInteger(pointKeep)
                  || pointKeep < 0) {
                  pointKeep = 0;
               }
               // 增加'-'号的支持
               const str = '^(\\-)*(\\d+)\\.(\\d\{' + pointKeep + '}).*$';
               const reg = new RegExp(str);
               if (pointKeep === 0) {
                  // 不需要小数点
                  val = val.replace(reg, '$1$2');
               } else {
                  // 通过正则保留小数点后指定的位数
                  val = val.replace(reg, '$1$2.$3');
               }
            }
         } else {
            val = ele.value.replace(/[^\d]/g, '');
         }
         ele.value = val;
      }
      if (vnode.componentInstance) {
         vnode.componentInstance.$emit('input', ele.value);
      } else {
         vnode.elm.dispatchEvent(new CustomEvent('input', ele.value));
      }
   }
   return handle;
}
const numberInput = {
   bind(el, binding, vnode) {
      const ele = el.tagName === 'INPUT' ? el : el.querySelector('input');
      ele.addEventListener('input', onInput(el, ele, binding, vnode), false);
   },
};

export default numberInput

