/*
 * @Author: zhuchaowei 952698119@qq.com
 * @Date: 2022-06-27 16:07:07
 * @LastEditors: zhuchaowei 952698119@qq.com
 * @LastEditTime: 2022-06-29 14:18:31
 * @FilePath: \platform-pc-front\src\router\modules\BigDataCenter.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/**
 * 外接页面展示中心路由
 */

/**
 * 塔机管理系统
 */
export const HangingManagement = {
   path: '/HangingManagement',
   name: 'HangingManagement',
   component: () => import('@/views/ExternalManagement/HangingManagement'),
   meta: {
      label: '塔机管理',
      permId: 'MTJGL',
      icon: [
         'icon-dapingzhanshi',
         require('@/assets/images/header/塔机.png')
      ],
      affix: true,
   }
};
/**
 * 调度系统
 */
export const DispatchingManagementXY = {
   path: '/DispatchingManagementXY',
   name: 'DispatchingManagementXY',
   component: () => import('@/views/ExternalManagement/DispatchingManagementXY'),
   meta: {
      label: '调度系统',
      permId: 'DDXTXY',
      icon: [
         'icon-dapingzhanshi',
         require('@/assets/images/header/dispatchingXY.png')
      ],
      affix: true,
   }
};
/**
 * 排队系统
 */
// export const lineUpManagement = {
//    path: '/lineUpManagement',
//    name: 'lineUpManagement',
//    component: () => import('@/views/ExternalManagement/lineUpManagement'),
//    meta: {
//       label: '排队系统',
//       permId: 'PDXT',
//       icon: [
//          'icon-dapingzhanshi',
//          require('@/assets/images/header/lineUp.png')
//       ],
//       affix: true,
//    }
// };
