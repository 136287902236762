/*
 * @Date: 2023-11-01 14:07:07
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/**
 * 传统项目
 */

/**
* 主项目
*/
export const parentProjectList = {
  path: '/projectManagementList',
  name: 'projectManagementList',
  component: () => import('@/views/routerProjectManagement/components/ProjectList'),
  meta: {
     label: '主项目',
     name: 'projectManagementList',
     permId: 'traditionalProject-list',
     // FXJLJK MTJGL
     icon: require('@/assets/images/navigation/traditionalProjectChildren.png'),
  }
};

/**
* 主项目详情
*/
export const traditionalProjectDetails = {
  path: '/traditionalProjectDetails',
  name: 'traditionalProjectDetails',
  component: () => import('@/views/routerProjectManagement/components/ProjectList/projectDetails'),
  meta: {
     label: '主项目详情',
     name: 'traditionalProjectDetails',
     permId: 'traditionalProject-list',
     hideMenu: true,
     // FXJLJK MTJGL
     icon: require('@/assets/images/header/零配件检索.png'),
  }
};


/**
* 子项目
*/
export const childrenProjectList = {
  path: '/childrenProjectList',
  name: 'childrenProjectList',
  component: () => import('@/views/routerProjectManagement/components/ProjectChildrenList'),
  meta: {
     label: '子项目',
     name: 'childrenProjectList',
     permId: 'traditionalProject-chidrenList',
     // FXJLJK MTJGL
     icon: require('@/assets/images/navigation/traditionalProjectChildren.png'),
  }
};

/**
* 子项目详情
*/
export const traditionalChildrenProjectDetails = {
  path: '/traditionalChildrenProjectDetails',
  name: 'traditionalChildrenProjectDetails',
  component: () => import('@/views/routerProjectManagement/components/ProjectChildrenList/projectDetails'),
  meta: {
     label: '子项目详情',
     name: 'traditionalChildrenProjectDetails',
     permId: 'traditionalProject-chidrenList',
     hideMenu: true,
     // FXJLJK MTJGL
     icon: require('@/assets/images/header/零配件检索.png'),
  }
};

/**
* 子项目 添加编辑
*/
export const addChildrenProject = {
  path: '/addChildrenProject',
  name: 'addChildrenProject',
  component: () => import('@/views/routerProjectManagement/components/ProjectChildrenList/addProject'),
  meta: {
     label: '添加/编辑子项目',
     name: 'addChildrenProject',
     permId: 'traditionalProject-chidrenList',
     hideMenu: true,
     // FXJLJK MTJGL
     icon: require('@/assets/images/header/零配件检索.png'),
  }
};