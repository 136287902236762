/**
 * Promise封装setTimeout, async await可使用
 */

export const sleep = (ms = 0) =>
  new Promise((resolve) => setTimeout(resolve, ms));

/**
 * 带条件的`sleep`,
 * 如果满足`ms`或`cbIf`回调返回`true`, 则停止等待
 * @param {*} ms
 * @param {*} cbIf
 * @param {*} interval
 */
export async function sleepIf(ms = 0, cbIf = null, interval = 500) {
  if (cbIf && cbIf()) return;

  const start = Date.now();
  while (Date.now() - start <= ms) {
    if (cbIf && cbIf()) break;
    await sleep(interval);
  }
}

// promise 轮询函数
export function poll(promiseFn, params, conditionFn, interval = 10000) {
  let promise = null;
  let intervalId = "";
  promise = new Promise((resolve, reject) => {
    intervalId = setInterval(() => {
      promiseFn(params)
        .then((res) => {
          if (conditionFn(res)) {
            clearInterval(intervalId);
            resolve(res);
          }
        })
        .catch(reject);
    }, interval);
  });
  return [promise, intervalId];
}
