/*
 * @Author: zhuchaowei
 * @Date: 2024-05-14 12:10:19
 * @email: 952698119@qq.com
 * @LastEditors: zhuchaowei
 * @LastEditTime: 2024-05-28 14:54:28
 * @Description:
 */
/**
 * 项目列表页
 */
export const regulationManagementList = {
  path: "/regulationManagementList",
  name: "regulationManagementList",
  component: () =>
    import(
      "@/views/routerPerformanceManagement/components/regulationManagementList"
    ),
  meta: {
    label: "规则管理",
    name: "regulationManagementList",
    permId: "performance-rule",
    // FXJLJK MTJGL
    icon: require("@/assets/images/kpi/gzgl.png"),
    redirect: true,
  },
};


export const staffManagementList = {
  path: "/staffManagementList",
  name: "staffManagementList",
  component: () =>
    import(
      "@/views/routerPerformanceManagement/components/staffManagementList"
    ),
  meta: {
    label: "员工管理",
    name: "staffManagementList",
    permId: "performance-staff",
    // FXJLJK MTJGL
    icon: require("@/assets/images/kpi/yglb.png"),
  },
};

export const staffDetails = {
  path: "/staffDetails",
  name: "staffDetails",
  component: () =>
    import(
      "@/views/routerPerformanceManagement/components/staffManagementList/staffDetails"
    ),
  meta: {
    label: "员工详情",
    name: "staffDetails",
    permId: "performance-staff-detail",
    hideMenu: true,
    // FXJLJK MTJGL
    icon: require("@/assets/images/header/零配件检索.png"),
  },
};

export const teamLeaderManagementList = {
  path: "/teamLeaderManagementList",
  name: "teamLeaderManagementList",
  component: () =>
    import(
      "@/views/routerPerformanceManagement/components/staffManagementList/teamLeaderManagementList"
    ),
  meta: {
    label: "团队长管理",
    permId: "performance-leader",
    // FXJLJK MTJGL
    icon: require("@/assets/images/kpi/yglb.png"),
  },
};

export const teamLeaderDetails = {
  path: "/teamLeaderDetails",
  name: "teamLeaderDetails",
  component: () =>
    import(
      "@/views/routerPerformanceManagement/components/staffManagementList/teamLeaderDetails"
    ),
  meta: {
    label: "团队长详情",
    permId: "performance-leader-detail",
    hideMenu: true,
    // FXJLJK MTJGL
    icon: require("@/assets/images/header/零配件检索.png"),
  },
};

export const generalManagementList = {
  path: "/generalManagementList",
  name: "generalManagementList",
  component: () =>
    import(
      "@/views/routerPerformanceManagement/components/generalManagementList"
    ),
  meta: {
    label: "绩效总表",
    name: "generalManagementList",
    permId: "performance-general",
    // FXJLJK MTJGL
    icon: require("@/assets/images/kpi/jxzb.png"),
  },
};

export const receivableManagement = {
  path: "/receivableManagement",
  name: "receivableManagement",
  component: () =>
    import(
      "@/views/routerPerformanceManagement/components/generalManagementList/receivableManagement"
    ),
  meta: {
    label: "应收管理",
    name: "receivableManagement",
    permId: "performance-general",
    // FXJLJK MTJGL
    icon: require("@/assets/images/kpi/sjkb.png"),
  },
};

export const performancePanel = {
  path: "/performancePanel",
  name: "performancePanel",
  component: () =>
    import("@/views/routerPerformanceManagement/components/performancePanel"),
  meta: {
    label: "数据看板",
    name: "performancePanel",
    permId: "performance-panel",
    // FXJLJK MTJGL
    icon: require("@/assets/images/kpi/sjkb.png"),
  },
};

