/**
 * 虚拟table
 * 封装umy-table的table组件
 */

import Vue from 'vue';
import { UTable, UTableColumn } from 'umy-ui';
import VirTable from './VirtualTable.vue';


export function useUTable() {
  Vue.component(UTable.name, UTable);
  Vue.component(UTableColumn.name, UTableColumn);
  Vue.component('VirTable', VirTable);
}