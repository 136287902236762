//服务管理
export const safeDutyServiceManagement = {
  path: "/safeDutyServiceManagement",
  name: "safeDutyServiceManagement",
  component: () => import("@/views/safeDutyInsuredManagement/sdService.vue"),
  meta: {
    label: "服务记录",
    permId: "M20-sd-index",
    icon: require("@/assets/images/safeDuty/menu/服务管理.png"),
  },
};
//服务管理详情
export const safeDutyServiceManagementDetail = {
  path: "/safeDutyServiceManagement/safeDutyServiceManagementDetail",
  name: "safeDutyServiceManagementDetail",
  component: () =>
    import("@/views/safeDutyInsuredManagement/subPage/serviceFormIndex.vue"),
  meta: {
    hideMenu: true,
    label: "服务管理详情",
    permId: "M20-sd-index",
  },
};
//服务项目字典
export const safeDutyServiceItem = {
  path: "/safeDutyServiceItem",
  name: "safeDutyServiceItem",
  component: () =>
    import("@/views/safeDutyInsuredManagement/sdServiceDict.vue"),
  meta: {
    label: "服务项目字典",
    permId: "M20-sd-index",
    icon: require("@/assets/images/safeDuty/menu/服务字典.png"),
  },
};
