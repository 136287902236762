/**
 * 分销中心
 */

/**
 * 分销代理人管理
 */
export const DistributionManagement = {
  path: '/DistributionManagement',
  name: 'DistributionManagement',
  component: () => import('@/views/DistributionManagement/DistributionManagement/index'),
  meta: {
     label: '分销代理人管理',
     permId: 'M31-list',
     icon: require('@/assets/images/claimCase.png'),
  }
};

/**
 * 分销代理人管理 - 代理人
 */
// export const DistributionAgentManagement = {
//   path: '/DistributionAgentManagement',
//   name: 'DistributionAgentManagement',
//   component: () => import('@/views/DistributionManagement/DistributionAgentManagement/index'),
//   meta: {
//      label: '分销代理人管理-代理人',
//      permId: 'M30-Record',
//      icon: require('@/assets/images/claimCase.png'),
//   }
// };

/**
 * 分销代理人管理
 */
export const DistributionAgentAudit = {
  path: '/DistributionAgentAudit',
  name: 'DistributionAgentAudit',
  component: () => import('@/views/DistributionManagement/DistributionAgentAudit/index'),
  meta: {
     label: '分销代理人申请审核',
     permId: 'M31-audit',
     icon: require('@/assets/images/claimCase.png'),
  }
};