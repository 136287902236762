//询价单管理
export const enquiryManagement = {
   path: '/enquiryManagement',
   name: 'enquiryManagement3',
   component: () => import('@/views/EnquiryManagement/index3.vue'),
   meta: {
      label: '询价单管理',
      permId: 'M26_3',
      icon: require('@/assets/images/policyInquiry.png'),
      affix: true,
      policyType: 3,
      policyRoute: 'Insurance'
   }
};
/**
 * 保单管理
 */
export const policyManagement = {
   path: '/policyManagement',
   name: 'policyManagement3',
   component: () => import('@/views/policyManagement/index3.vue'),
   meta: {
      label: '保单管理',
      permId: 'M20_3',
      icon: require('@/assets/images/policyManagement.png'),
      affix: true,
      policyType: 3,
      policyRoute: 'Insurance'
   },
};

//批单创建
export const createCorrectionOrder = {
   path: '/createCorrectionOrder/:orderId?',
   name: 'createCorrectionOrder',
   component: () => import('@/views/CorrectionSheetManagement/pages/CreateCorrection'),
   meta: {
      label: '批单创建',
      permId: 'M25_3',
      icon: 'icon-anquanguanli2',
      hideMenu: true,
      policyType: 3,
      policyRoute: 'Insurance'
   }
};

//批单详情
export const correctionDetails = {
   path: '/correctionDetails/:riderId',
   name: 'correctionDetails',
   //props: true,
   component: () => import('@/views/CorrectionSheetManagement/pages/CorrectionDetails'),
   meta: {
      label: '批单详情',
      permId: 'M25_3',
      icon: 'icon-anquanguanli2',
      hideMenu: true,
      policyType: 3,
      policyRoute: 'Insurance'
   }
};

//批单修改
export const correctionEdit = {
   path: '/correctionEdit/:approvalType/:orderId?/:riderId?',
   name: 'correctionEdit3',
   component: () => import('@/views/CorrectionSheetManagement/pages/CorrectionEdit'),
   meta: {
      label: '批单修改',
      permId: 'M25_3',
      icon: 'icon-anquanguanli2',
      hideMenu: true,
      policyType: 3,
      policyRoute: 'Insurance'
   }
};

//批单管理
export const correctionSheetManagement = {
   path: '/correctionSheetManagement',
   name: 'correctionSheetManagement3',
   component: () => import('@/views/CorrectionSheetManagement/index3.vue'),
   meta: {
      label: '批单管理',
      permId: 'M25_3',
      icon: require('@/assets/images/policyCorrection.png'),
      affix: true,
      policyType: 3,
      policyRoute: 'Insurance'
   }

};

export const enquiryEdit = {
   path: '/enquiryEdit/:id?/:type?',
   name: 'enquiryEdit',
   component: () => import('@/views/EnquiryManagement/enquiryEdit.vue'),
   meta: {
      label: '询价单编辑',
      permId: 'M26_3',
      icon: 'icon-anquanguanli2',
      hideMenu: true,
      policyType: 3,
      policyRoute: 'Insurance'
   }
};

export const enquiryAdd = {
   path: '/enquiryAdd',
   name: 'enquiryAdd',
   component: () => import('@/views/EnquiryManagement/enquiryAdd.vue'),
   meta: {
      label: '询价单添加',
      permId: 'M26_3',
      icon: 'icon-anquanguanli2',
      hideMenu: true,
      policyType: 3,
      policyRoute: 'Insurance'
   }
};

export const enquiryDetails = {
   path: '/enquiryDetails/:id?',
   name: 'enquiryDetails',
   component: () => import('@/views/EnquiryManagement/enquiryDetails.vue'),
   meta: {
      label: '询价单详情',
      permId: 'M26_3',
      icon: 'icon-anquanguanli2',
      hideMenu: true,
      policyType: 3,
      policyRoute: 'Insurance'
   }
};

export const policyDetails = {
   path: '/policyDetails/:id',
   name: 'policyDetails',
   component: () => import('@/views/policyManagement/policyDetails.vue'),
   meta: {
      label: '保单详情',
      permId: 'M20_3',
      icon: 'icon-anquanguanli2',
      hideMenu: true,
      policyType: 3,
      policyRoute: 'Insurance'
   }
};

export const policyEdit = {
   path: '/policyEdit/:id',
   name: 'policyEdit',
   component: () => import('@/views/policyManagement/policyEdit.vue'),
   meta: {
      label: '保单编辑',
      permId: 'M20_3',
      icon: 'icon-anquanguanli2',
      hideMenu: true,
      policyType: 3,
      policyRoute: 'Insurance'
   }
};

//承保方案管理
// export const insuredManagement = {
//    path: '/insuredManagement',
//    name: 'insuredManagement',
//    component: () => import('@/views/InsuredManagement'),
//    meta: {
//       label: '承保方案管理',
//       permId: 'M23',
//       icon: require('@/assets/images/policyScheme.png'),
//       affix: true,
//       policyType: 3,
//       policyRoute: 'Insurance'
//    }
// };

//续保管理
export const renewalManagement = {
   path: '/renewalManagement',
   name: 'renewalManagement3',
   component: () => import('@/views/RenewalManagement/index3.vue'),
   meta: {
      label: '续保管理',
      permId: 'M24_3',
      icon: require('@/assets/images/policyRenewal.png'),
      affix: true,
      policyType: 3,
      policyRoute: 'Insurance'
   }
};