/**
 * 报表查询路由
 */

/**
 * 图片查询
 */
export const ImageQuery = {
  path: '/ImageQuery',
  name: 'ImageQuery',
  component: () => import('@/views/ImageQuery'),
  meta: {
    label: '图片查询',
    permId: 'M13SM01',
    icon: 'icon-tupianchaxun1',
  }
};

/**
 * 视频上传列表
 */
export const VideoUploadList = {
  path: '/VideoUploadList',
  name: 'VideoUploadList',
  component: () => import('@/views/VideoUploadList'),
  meta: {
    label: '视频上传列表',
    permId: 'M13SM02',
    icon: 'icon-shipinshangchuanliebiao1',
  }
};


/**
 * 操作记录
 */
export const OperationRecord = {
  path: '/OperationRecord',
  name: 'OperationRecord',
  component: () => import('@/views/OperationRecord'),
  meta: {
    label: '操作记录',
    permId: 'M13SM03',
    icon: 'icon-caozuojilu',
  }
};

/**
 * 视频操作记录
 */
export const VideoOperationRecord = {
  path: '/VideoOperationRecord',
  name: 'VideoOperationRecord',
  component: () => import('@/views/VideoOperationRecord'),
  meta: {
    label: '视频操作记录',
    permId: 'M13SM04',
    icon: 'icon-shipincaozuojilu',
  }
};


/**
 * 指令查询
 */
export const CommandQuery = {
  path: '/CommandQuery',
  name: 'CommandQuery',
  component: () => import('@/views/CommandQuery'),
  meta: {
    label: '指令查询',
    permId: 'M13SM05',
    icon: 'icon-zhilingchaxun',
  }
};

/**
 * 油耗统计
 */
// export const OilStatistics = {
//   path: '/OilStatistics',
//   name: 'OilStatistics',
//   component: () => import('@/views/OilStatistics'),
//   meta: {
//     label: '油耗统计',
//     permId: 'M13SM06',
//     icon: 'icon-youhao',
//   }
// };


/**
 * 车辆自动授权记录
 */
// export const AutomaticVehicleAuthorizationRecord = {
//   path: '/AutomaticVehicleAuthorizationRecord',
//   name: 'AutomaticVehicleAuthorizationRecord',
//   component: () => import('@/views/Recharge'),
//   meta: {
//     // label: '车辆授权记录',
//     label: '测试充值',
//     icon: 'icon-cheliangshouquanjilu',
//   }
// };

