export const FormData = {
  'Content-Type': 'multipart/form-data'
};

export const FormUrlencoded = {
  'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
};

export const ApplicatonJson = {
  'Content-Type': 'application/json',
};

export const OctetStream = {
  'Content-Type': 'application/octet-stream',
};

export const OctetJson = {
  'Content-Type': 'application/json; application/octet-stream',

};

export const MIME = {
  FormData: 'multipart/form-data',
  FormUrlencoded: 'application/x-www-form-urlencoded; charset=UTF-8',
  JSON: 'application/json; charset=UTF-8',
  OctetStream: 'application/octet-stream',
  OctetJSON: 'application/json; application/octet-stream',
};