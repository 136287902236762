import { appConfig } from "@/config/appConfig";
import { createHttp } from "@/api/HttpHelper";
import { createMJHttp } from "@/api/MJRequest";

const axios = createHttp(appConfig.base2GUrl);
const MJAxios = createMJHttp(appConfig.MJBaseUrl);
const statisticsAxios = createHttp(appConfig.base2GUrl2);
const YWAxios = createHttp(appConfig.YWBaseUrl);

const post = (url, data, options) =>
  axios.post(url, data, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
    ...options,
  });

/** 退出登录 */
export const signout = () => axios.get("login/logout.do").catch(() => null);

/**
 * 账号密码登录
 */
export const accountLogin = (params) =>
  post("login/login", params, {
    ignoreAuth: true,
  });

/** sessionId 登录 */
export const sessionIdLogin = (val) =>
  axios.get("login/loginUserInfo", {
    params: val,
    ignoreAuth: true,
  });

/**
 * 账号激活
 * @param {JSON} params
 */
export const systemutilKey = (params) =>
  post("systemutil/Active.do", params, {
    ignoreAuth: true,
  });

/**
 * 获取key列表
 */
// export const getKeyList = (params) =>
//   get('systemutil/pagekey.do', { params });
export const getKeyList = (params) => {
  return axios.get("systemutil/pagekey.do", { params });
};

/**
 * 生成密钥
 */
export const createKey = (params) => post("systemutil/getkey.do", params);

/**
 * 修改密码
 */
export const changePassword = (params) => post("user/modifyPwd.do", params);

/**
 * 获取车组数据
 */
export const loadGroups = (userId) => {
  return axios.get("vehicleGroup/getGroupsByRds.do", {
    params: { platform: "PC", userId },
  });
  // .then(res => {
  //   if (!(res.flag && res.obj)) return [];
  //   let groups = res.obj;
  //   groups.sort();

  //   let i = groups.findIndex(p => p.groupId === 0);
  //   if (i >= 0) groups.unshift(groups.splice(i, 1)[0]);
  //   return groups;
  // });
};

/**
 * 获取车组数据
 */
export const projectLoadGroups = (params) => {
  return axios.get("vehicleGroup/getGroupsByRds.do", { params });
  // .then(res => {
  //   if (!(res.flag && res.obj)) return [];
  //   let groups = res.obj;
  //   groups.sort();

  //   let i = groups.findIndex(p => p.groupId === 0);
  //   if (i >= 0) groups.unshift(groups.splice(i, 1)[0]);
  //   return groups;
  // });
};

//终端更新
export const devUpdate = (params) => {
  return axios.post("monitor/uploadTerminalNoPag.do", params, {
    transRequest: false,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

/**
 * 获取验证码
 */
export const getCode = (val) =>
  axios
    .get("systemutil/getCode.do", {
      ignoreAuth: true,
      responseType: "blob",
      params: val,
    })
    .then((res) => URL.createObjectURL(res));

/**
 * 嘉羊出货单查询
 * @param {JSON} val
 */
export const getJySalesBillInfo = (params) => {
  return axios.get("jysalesBill/getJySalesBillInfo.do", { params });
};

/**
 * 嘉羊出货单更新
 * @param {JSON} val
 */
export const updateJySalesBill = (params) => {
  return axios.post("jysalesBill/updateJySalesBill.do", params, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

/**
 * 通过id查询指定出货单
 * @param {JSON} val
 */
export const getJySalesBillInfoById = (params) => {
  return axios.post("jysalesBill/getJySalesBillInfoById.do", params);
};

/**
 * 嘉羊出货单删除
 * @param {JSON} val
 */
export const deleteJySalesBill = (params) => {
  return axios.post("jysalesBill/deleteJySalesBill.do", params);
};

//图片下发
export const pictureRelease = (params) => {
  return axios.post("monitor/pictureRelease.do", params, {
    transRequest: false,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

// 版本更新内容已阅
export const addUpgradeFile = (params) => {
  return axios.axios({
    url: "login/addUpgradeFile.do",
    method: "get",
    params,
  });
};

// 品牌查询
export const getBrandList = (params) => {
  return statisticsAxios.axios({
    url: "dict/data/type",
    method: "get",
    params,
  });
};

//车辆状态、品牌、产品线(共用) - 筛选条件
export const groupCount = (params) => {
  return statisticsAxios.axios({
    url: "statisticCount/groupCount",
    method: "get",
    params,
  });
};

//安全管理 - 筛选条件
export const groupCountForAlarm = (params) => {
  return statisticsAxios.axios({
    url: "statisticCount/groupCountForAlarm",
    method: "get",
    params,
  });
};

//安全管理 - 数据查询
export const securityAlarmStatistics = (data) => {
  return statisticsAxios.axios({
    url: "securityAlarm/securityAlarmStatistics.do",
    method: "post",
    data,
  });
};

//油耗管理 - 数据统计
export const oilStatistics = (data) => {
  return statisticsAxios.axios({
    url: "oilReport/get.do",
    method: "post",
    data,
  });
};

//油耗管理 - 表格
export const oilTables = (data) => {
  return statisticsAxios.axios({
    url: "oilReport/listDataTableForOil.do",
    method: "post",
    //   responseType: 'blob',
    data,
  });
};
//油耗管理 - 下载
export const oilDownload = (data) => {
  return statisticsAxios.axios({
    url: "oilReport/listDataTableForOil.do",
    method: "post",
    responseType: "blob",
    fileName: "油耗统计数据报表",
    data,
  });
};
//工时管理 - 下载
export const workDownLoad = (params) => {
  axios.get("accReport/exportStatisticalGrid", {
    params,
    // ignoreAuth: true,
    fileName: "工时统计数据报表",
    responseType: "blob",
  });
};

//工时管理 - 数据查询
export const workStatistics = (params) => {
  return axios.post("accReport/getWHDataList", params, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

//工时管理 - 表格
export const workTables = (params) => {
  return axios.post("accReport/getStatisticalGrid", params, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

//安全管理 - 数据查询-下载
export const securityAlarmStatisticsBlob = (data) => {
  return statisticsAxios.axios({
    url: "securityAlarm/securityAlarmStatistics.do",
    method: "post",
    responseType: "blob",
    fileName: "安全报警数据报表",
    data,
  });
};

//工时管理 - 数据统计
// export const oilStatistics= (params) => {
//    return statisticsAxios.axios({
//      url: 'oilReport/get.do',
//      method: 'get',
//      params
//    });
// };

// 采棉机报警查询
export const getWorkloadAlarm = (data) => {
  return statisticsAxios.axios({
    url: "monitor/getWorkloadAlarm.do",
    method: "get",
    data,
  });
};
// 大屏报警分析
export const largeScreenSecurityAlarm = (params) => {
  return statisticsAxios.axios({
    url: "securityAlarm/largeScreenSecurityAlarm.do",
    method: "get",
    params,
  });
};

// 大屏车辆状态统计
export const getVehicleStart = (params) => {
  return statisticsAxios.axios({
    url: "dataCenter/getVehicleStart.do",
    method: "get",
    params,
  });
};

// 大屏今日+累计油耗
export const oilTotal = (params) => {
  return statisticsAxios.axios({
    url: "oilReport/getOilData",
    method: "get",
    params,
  });
};

// 大屏数据获取 - 全
export const largeScreenInterfice = (params) => {
  return statisticsAxios.axios({
    url: "dataCenter/largeScreenInterfice",
    method: "get",
    params,
  });
};

// 大屏今日+累计油耗
export const getLatLon = (params) => {
  return statisticsAxios.axios({
    url: "dataCenter/getLatLon.do",
    method: "get",
    params,
  });
};

// 大屏今日+累计工时
export const workTotal = (params) => {
  return axios.get("accReport/getWorkingTime", { params });
};

// 保单列表
export const policyList = (data) => {
  return MJAxios.axios({
    url: "/policy/list",
    method: "post",
    data,
  });
};

// 保单导出
export const policyExport = (params) => {
  return MJAxios.axios({
    url: "/policy/export",
    method: "get",
    responseType: "blob",
    fileName: "保单数据报表",
    params,
  });
};

// 保单详情
export const policyDetail = (data) => {
  return MJAxios.axios({
    url: "/policy/detail",
    method: "post",
    data,
  });
};

// 保单删除
export const policyDelByIds = (data) => {
  return MJAxios.axios({
    url: "/policy/delByIds",
    method: "post",
    data,
  });
};

// 保单 添加 / 保存
export const policySave = (data) => {
  return MJAxios.axios({
    url: "/policy/save",
    method: "post",
    data,
  });
};

// 品牌信息 + 出险类型
export const getDictData = (params) => {
  return MJAxios.axios({
    url: "/open/common/dict-data",
    method: "get",
    params,
  });
};

// 保单导入
export const policyImport = (data) => {
  return MJAxios.axios({
    url: "/policy/import",
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data,
  });
};

// 设置索赔信息-用户信息
export const setIdentifyInfoInfo = (data) => {
  return MJAxios.axios({
    url: "/open/pay/setIdentifyInfoInfo",
    method: "post",
    data: data,
  });
};

// 设置索赔信息-收款信息
export const setBankInfo = (data) => {
  return MJAxios.axios({
    url: "/open/pay/setBankInfo",
    method: "post",
    data: data,
  });
};

// 设置索赔信息-补充信息
export const setOtherInfo = (data) => {
  return MJAxios.axios({
    url: "/open/pay/setOtherInfo",
    method: "post",
    data: data,
  });
};

// 获取支付数据
export const getPayDetails = (id) => {
  return MJAxios.axios({
    url: "/open/pay/getPayDetails/" + id,
    method: "get",
  });
};

// 保存支付数据
export const savePayDetails = (data) => {
  return MJAxios.axios({
    url: "/open/pay/savePayDetails",
    method: "post",
    data,
  });
};

// 理赔结案数据
export const claimCloseInfo = (params) => {
  return MJAxios.axios({
    url: "/close/list",
    method: "get",
    params,
  });
};

// 保存理赔结案数据
export const claimCloseSave = (data) => {
  return MJAxios.axios({
    url: "/close/save",
    method: "post",
    data,
  });
};

// 查询索赔信息
export const getMaterialInfo = (id) => {
  return MJAxios.axios({
    url: "/open/pay/getMaterialInfo/" + id,
    method: "get",
  });
};

// 查询支付编辑记录
export const insEditRecordList = (params) => {
  return MJAxios.axios({
    url: "/open/insEditRecord/list",
    method: "get",
    params,
  });
};

// 中止理赔
export const abortSave = (data) => {
  return MJAxios.axios({
    url: "/abort/save",
    method: "post",
    data,
  });
};

// 结案
export const caseClose = (params) => {
  return MJAxios.axios({
    url: "/claim/close",
    method: "get",
    params,
  });
};

// 删除支付信息
export const payDelDetails = (params) => {
  return MJAxios.axios({
    url: "/open/pay/delDetails",
    method: "get",
    params,
  });
};

// 案件重开
export const caseReopen = (params) => {
  return MJAxios.axios({
    url: "/claim/reopen",
    method: "get",
    params,
  });
};

// 角色管理列表
export const roleList = (params) => {
  return statisticsAxios.axios({
    url: "/blade-system/role/list",
    method: "get",
    params,
  });
};

// 编辑个性化列表
export const updateTitleDiy = (data) => {
  return MJAxios.axios({
    url: "/blade-user/titleDiy/subject",
    method: "post",
    data,
  });
};

// 获取告警名称、告警等级、告警来源
export const tblAlarmdefineList = (params) => {
  return statisticsAxios.axios({
    url: "/tblAlarmdefine/list",
    method: "get",
    params,
  });
};

// 获取告警名称
export const tblAlarmdefineListOfWarningLight = (params) => {
  return statisticsAxios.axios({
    url: "/tblAlarmdefine/listOfWarningLight",
    method: "get",
    params,
  });
};

// 获取设备类型、设备类型
export const selectDictData = (params) => {
  return statisticsAxios.axios({
    url: "/dict/type/selectDictData",
    method: "get",
    params,
  });
};

// 告警统计列表
export const safetyManagePage = (data) => {
  return statisticsAxios.axios({
    url: "/safetyManage/page",
    method: "post",
    loading: true,
    data,
  });
};

// 下载附件 告警统计列表
export const safetyManageDam = (params) => {
  return statisticsAxios.axios({
    url: "/safetyManage/dam",
    method: "get",
    responseType: "blob",
    loading: true,
    params,
  });
};

// 导出多个及附件 告警统计列表
export const exportExcelAndAm = (data) => {
  return statisticsAxios.axios({
    url: "safetyManage/exportExcelAndAm",
    method: "post",
    loading: true,
    data,
  });
};

// 导出表格 告警统计列表
export const exportExcel = (data) => {
  return statisticsAxios.axios({
    url: "safetyManage/exportExcel",
    method: "post",
    loading: true,
    data,
  });
};

// 告警统计-告警详情
export const getAttachment = (params) => {
  return statisticsAxios.axios({
    url: "safetyManage/getAttachment",
    method: "get",
    loading: true,
    params,
  });
};

// 商车报警数据统计导出
export const exportMallCarAlarm = (data) => {
  return statisticsAxios.axios({
    url: "/dataSummary/exportExcel",
    method: "post",
    loading: true,
    responseType: "blob",
    fileName: "商车报警统计",
    data,
  });
};
// 商车报警数据统计列表
export const mallCarAlarmList = (data) => {
  return statisticsAxios.axios({
    url: "/dataSummary/page",
    method: "post",
    loading: true,
    data,
  });
};
