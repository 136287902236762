/**
 * 404路由
 */
export const NotFound = {
  path: '/404',
  name: 'NotFound',
  component: () => import('@/views/404.vue'),
  meta: {
    label: '登录',
    ignoreAuth: true,
    ignoreKeepAlive: true,
  }
};