//安责保单列表
export const safeDutyManagement = {
  path: "/safeDutyManagement",
  name: "safeDutyManagement",
  component: () => import("@/views/safeDutyInsuredManagement/sdPolicy.vue"),
  meta: {
    label: "安责保单",
    permId: "M20-sd-index",
    icon: require("@/assets/images/safeDuty/menu/安责险保单管理.png"),
  },
};
/**
 * 安责保单 理赔案件
 */
export const safeDutyClaimManagement = {
  path: "/safeDutyClaimManagement",
  name: "safeDutyClaimManagement",
  component: () => import("@/views/safeDutyInsuredManagement/sdClaim.vue"),
  meta: {
    label: "安责保单-理赔案件",
    permId: "M20-sd-index",
    icon: require("@/assets/images/safeDuty/menu/安责险理赔管理.png"),
  },
};

//安责保单详情
export const safeDutyManagementDetail = {
  path: "/safeDutyManagementDetail",
  name: "safeDutyManagementDetail",
  component: () =>
    import("@/views/safeDutyInsuredManagement/subPage/sdPolicyFormIndex.vue"),
  meta: {
    hideMenu: true,
    label: "安责保单详情",
    permId: "M20-sd-index",
  },
};
//安责理赔详情
export const safeDutyClaimDetail = {
  path: "/safeDutyClaimDetail",
  name: "safeDutyClaimDetail",
  component: () =>
    import("@/views/safeDutyInsuredManagement/subPage/sdClaimFormIndex.vue"),
  meta: {
    hideMenu: true,
    label: "安责理赔详情",
    permId: "M20-sd-index",
  },
};
//保司管理
export const safeDutyComManagement = {
  path: "/safeDutyComManagement",
  name: "safeDutyComManagement",
  component: () =>
    import("@/views/safeDutyInsuredManagement/sdComInsurance.vue"),
  meta: {
    label: "保司管理",
    permId: "M20-sd-index",
    icon: require("@/assets/images/safeDuty/menu/保司管理.png"),
  },
};
//保司管理详情
export const safeDutyComManagementDetail = {
  path: "/safeDutyComManagementDetail",
  name: "safeDutyComManagementDetail",
  component: () =>
    import("@/views/safeDutyInsuredManagement/subPage/comFormIndex.vue"),
  meta: {
    label: "保司管理详情",
    permId: "M20-sd-index",
    hideMenu: true,
  },
};
