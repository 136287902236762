//通用规则
export const generalRuleManagementList = {
  path: "/ruleCommon",
  name: "ruleCommon",
  component: () =>
    import(
      "@/views/routerPerformanceManagement/components/regulationManagementList/ruleCommon"
    ),
  meta: {
    label: "通用规则",
    permId: "performance-rule-common",
    icon: require("@/assets/images/kpi/rjgz.png"),
    // FXJLJK MTJGL
  },
};
//客户规则
export const customerRuleManagementList = {
  path: "/ruleCustomer",
  name: "ruleCustomer",
  component: () =>
    import(
      "@/views/routerPerformanceManagement/components/regulationManagementList/ruleClient"
    ),
  meta: {
    label: "客户规则",
    permId: "performance-rule-client",
    icon: require("@/assets/images/kpi/rjgz.png"),
    // FXJLJK MTJGL
  },
};
//项目规则
export const projectRuleManagementList = {
  path: "/ruleProject",
  name: "ruleProject",
  component: () =>
    import(
      "@/views/routerPerformanceManagement/components/regulationManagementList/ruleProject"
    ),
  meta: {
    label: "项目规则",
    permId: "performance-rule-project",
    icon: require("@/assets/images/kpi/rjgz.png"),
    // FXJLJK MTJGL
  },
};