
/**
 * {
 *  label:路由label
 *  permId: 路由权限ID
 *  children: 子路由
 * }
 */
export const roterData = [
   {
      label: '实时定位',
      permId: 'M01',
      disabled: true,
   },
   {
      label: '轨迹回放',
      permId: 'M02',
   },
   {
      label: '多车轨迹',
      permId: 'M03',
   },
   {
      label: '分组监控',
      permId: 'M04',
   },
   {
      label: '区域监控',
      permId: 'M05',
   },
   {
      label: '实时视频',
      permId: 'M06',
   },
   {
      label: '视频轮询',
      permId: 'M07',
   },
   {
      label: '电子围栏',
      permId: 'M08',
   },
   {
      label: '安全管理',
      permId: 'M09',
      children: [
         {
            label: '车辆驾驶行为统计',
            permId: 'M09SM01',
         },
         {
            label: '报警总览',
            permId: 'M09SM02',
         },
         {
            label: '报警处理',
            permId: 'M09SM03',
         },
      ]
   },
   {
      label: '录像回放',
      permId: 'M10',
   },
   {
      label: '规则管理',
      permId: 'M11',
      children: [
         {
            label: '区域配置',
            permId: 'M11SM01',
         },
         {
            label: '线路配置',
            permId: 'M11SM02',
         },
         {
            label: '终端控制',
            permId: 'M11SM03',
         },
      ]
   },

   {
      label: '资料管理',
      permId: 'M12',
      children: [
         {
            label: '用户管理',
            permId: 'M12SM01',
         },
         {
            label: '车辆管理',
            permId: 'M12SM02',
         },
         {
            label: '驾驶员管理',
            permId: 'M12SM03',
         },
         {
            label: '批量导入',
            permId: 'M12SM04',
         },
         {
            label: '车辆授权',
            permId: 'M12SM05',
         },
         {
            label: '保单管理',
            permId: 'M20',
         },
         {
            label: '理赔管理',
            permId: 'M21',
         },
      ]
   },

   {
      label: '报表查询',
      permId: 'M13',
      children: [
         {
            label: '图片查询',
            permId: 'M13SM01',
         },
         {
            label: '视频上传列表',
            permId: 'M13SM02',
         },
         {
            label: '操作记录',
            permId: 'M13SM03',
         },
         {
            label: '视频操作记录',
            permId: 'M13SM04',
         },
         {
            label: '指令查询',
            permId: 'M13SM05',
         },
         {
            label: '油耗统计',
            permId: 'M13SM06',
         },
      ]
   },

   {
      label: '工时统计',
      permId: 'M14',
   },

   {
      label: '个人信息',
      permId: 'M15',
   },

   {
      label: '安全设置',
      permId: 'M16',
   },
   {
      label: '系统设置',
      permId: 'M17',
   },
   {
      label: '大屏展示',
      permId: 'M18',
   },
   {
      label: '大屏展示',
      permId: 'M18',
   },
   {
      label: '风控管理',
      permId: 'FXJLJK',
   },
   {
      label: '零配件检索',
      permId: 'LPJJS',
   },
   {
      label: '项目管理',
      permId: 'XMGL',
   },
   {
      label: '充值',
      permId: 'M19',
   },
   {
      label: '系统设置',
      permId: 'M22',
      children: [
         {
            label: '角色管理',
            permId: 'M22-role',
            children: [
               {
                  label: '新增',
                  permId: 'M22-role-add',
               },
               {
                  label: '编辑',
                  permId: 'M22-role-edit',
               }
            ]
         },
         {
            label: '租户管理',
            permId: 'M22-client',
            children: [
               {
                  label: '新增',
                  permId: 'M22-client-add',
               },
               {
                  label: '编辑',
                  permId: 'M22-client-edit',
               }
            ]
         },
         {
            label: '组织架构',
            permId: 'M22-architecture',
            children: [
               {
                  label: '新增',
                  permId: 'M22-architecture-add',
               },
               {
                  label: '编辑',
                  permId: 'M22-architecture-edit',
               }
            ]
         },
         {
            label: '员工管理',
            permId: 'M22-staff',
            children: [
               {
                  label: '新增',
                  permId: 'M22-staff-add',
               },
               {
                  label: '编辑',
                  permId: 'M22-staff-edit',
               }
            ]
         },
         {
            label: '操作日志',
            permId: 'M22-actionLog',
         },
         {
            label: '登录日志',
            permId: 'M22-loginLog',
         }
      ]
   }
];