import store from "@/store";
import Router from "@/router";
import { parseTime, getDwellTime } from "./formatDate"
class uma {
    constructor() {
        // 友盟sdk
        const { aplus_queue, location } = window;
        if ((location.href+'').indexOf('foreignBigScreenDisplayVideoPolling') == -1 && (location.href+'').indexOf('foreignVideoPolling') == -1) {
            aplus_queue.push({
                action: 'aplus.sendPV',
                arguments: [{
                    is_auto: false
                }]
            });
        }
        this.enterTime = null;
        this.routerName = null;
        this.purposeArr = {
            RealTimePositioning: '实时定位',
            RealTimeVideo: '实时视频',
            TrackPlayback: '轨迹回放',
            ElectronicFence: '电子围栏',
            VideoPlayback: '录像回放',
            ImageQuery: '报表查询-图片查询',
            VideoUploadList: '报表查询-视频上传列表',
            OperationRecord: '报表查询-操作记录',
            VideoOperationRecord: '报表查询-视频操作记录',
            CommandQuery: '报表查询-指令查询',
            MultiVehicleTrack: '多车轨迹',
        }
    };
    // 自定义登录锚点事件
    umaLogin(data) {
        if (process.env.NODE_ENV === "development") return false;
        // 友盟sdk
        const { aplus_queue } = window;
        data.login_time = parseTime(new Date(),'{yy}-{mm}-{dd} {hh}:{ii}:{ss}');
        aplus_queue.push({
            action: 'aplus.record',
            arguments: ['pc_login', 'CLK', data]
       });
    };
    // 自定义登录失败锚点事件
    umaLoginError(data) {
        if (process.env.NODE_ENV === "development") return false;
        // 友盟sdk
        const { aplus_queue } = window;
        data.login_time = parseTime(new Date(),'{yy}-{mm}-{dd} {hh}:{ii}:{ss}');
        aplus_queue.push({
            action: 'aplus.record',
            arguments: ['pc_login_error', 'CLK', data]
       });
    };
    // 车辆选择触发
    umaVehicleSelection(type, vehInfo) {
        if (process.env.NODE_ENV === "development") return false;
        // 友盟sdk
        const { aplus_queue } = window;
        aplus_queue.push({
            action: 'aplus.record',
            arguments: ['pc_vehicle_selection', 'CLK', {
                group_name: vehInfo.groupName, //组名
                vehicle_name: vehInfo.P, //车辆名称
                vehicle_id: vehInfo.V, //车辆ID
                vehicle_status: vehInfo.vehicleStatus, //车辆状态
                selection_user_name: store.state.userInfo.name, //操作用户
                selection_name: type, //选择方式
                create_time: parseTime(new Date()),
                purpose: this.purposeArr[Router.app.$route.name] || '查询条件', //选择目的
            }]
       });
    };
    // 操作页面进入页面触发
    umaEnterTime() {
        if (process.env.NODE_ENV === "development") return false;
        if (this.enterTime && Math.floor((new Date() - this.enterTime) / 1000) > 5) {
            this.umaDwellTime();
        }else {
            this.enterTime = new Date();
            this.routerName = Router.app.$route.name;
        }
    }
    // 操作页面离开触发
    umaDwellTime() {
        // 友盟sdk
        const { aplus_queue } = window;
        if (!this.purposeArr[Router.app.$route.name]) false;
        aplus_queue.push({
            action: 'aplus.record',
            arguments: ['pc_dwell_time', 'CLK', {
                access_user_namer: store.state.userInfo.name, //访问用户
                duration_name: this.purposeArr[Router.app.$route.name], //停留页面
                duration: getDwellTime(this.enterTime, null), //停留时长
                enterTime: parseTime(this.enterTime), //进入时间
                dwellTime: parseTime(new Date()),  //离开时间
            }]
       });
       this.enterTime = new Date();
       this.routerName = Router.app.$route.name;
    };
};

// Vue.prototype.$umaApi = new uma();
export default uma;