import { appConfig } from "@/config/appConfig";
import { createHttp } from "@/api/HttpHelper";
import { createMJHttp } from "@/api/MJRequest";

const axios = createHttp(appConfig.base2GUrl);
const SystemAxios = createMJHttp(appConfig.SystemBaseUrl);
const RiskAxios = createMJHttp(appConfig.YWBaseUrl);
const statisticsAxios = createHttp(appConfig.base2GUrl2);

// 登录
export const bladeAuth = (data) => {
  return SystemAxios.axios({
    url: "/blade-auth/token",
    method: "post",
    headers: {
      Authorization: "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
    },
    ignoreAuth: true,
    data: data,
  });
};

// 角色管理列表
export const roleList = (params) => {
  return SystemAxios.axios({
    url: "/blade-system/role/page",
    method: "get",
    loading: true,
    params,
  });
};

// 删除角色
export const roleRemove = (params) => {
  return SystemAxios.axios({
    url: "/blade-system/role/remove",
    method: "post",
    loading: true,
    params,
  });
};

// 获取租户列表
export const listTenant = (params) => {
  return SystemAxios.axios({
    url: "/blade-system/tenant/listTenant",
    method: "get",
    loading: true,
    params,
  });
};

// 新增或编辑角色
export const roleSubmit = (data) => {
  return SystemAxios.axios({
    url: "/blade-system/role/submit",
    method: "post",
    loading: true,
    data,
  });
};

// 获取已绑定角色的账号列表
export const pageByRoleId = (params) => {
  return SystemAxios.axios({
    url: "/blade-user/pageByRoleId",
    method: "get",
    params,
  });
};

//获取组织树
export const getOrganizationTree = (data) => {
  return SystemAxios.axios({
    url: "/blade-system/dept/treeAdmin",
    method: "POST",
    loading: true,
    data,
  });
};

//根据上级获取下降部门
export const getUnderDepartments = (data) => {
  return SystemAxios.axios({
    url: "/blade-system/dept/list-table",
    method: "POST",
    data,
  });
};

//根据部门id获取部门成员
export const getStaffByDepartById = (data) => {
  return SystemAxios.axios({
    url: "/blade-user/list-dept-user",
    method: "POST",
    loading: true,
    data,
  });
};

//删除部门
export const deletDepartByIds = (data) => {
  return SystemAxios.axios({
    url: "/blade-system/dept/removeByDeptIds",
    method: "POST",
    loading: true,
    data,
  });
};

// 租户管理列表
export const clientList = (params) => {
  return SystemAxios.axios({
    url: "/blade-system/tenant/tenantList",
    method: "get",
    loading: true,
    params,
  });
};

// 租户新增
export const clientAdd = (data) => {
  return SystemAxios.axios({
    url: "/blade-system/tenant/addOrUpdate",
    method: "post",
    loading: true,
    data,
  });
};

// 添加员工
export const submitUser = (data) => {
  return SystemAxios.axios({
    url: "/blade-user/submitUser",
    method: "post",
    loading: true,
    data,
  });
};

// 员工修改
export const updateUser = (data) => {
  return SystemAxios.axios({
    url: "/blade-user/updateUser",
    method: "post",
    loading: true,
    data,
  });
};

// 删除员工
export const deleteUser = (params) => {
  return SystemAxios.axios({
    url: "/blade-user/deleteUser",
    method: "get",
    loading: true,
    params,
  });
};

// 员工列表
export const userList = (params) => {
  return SystemAxios.axios({
    url: "/blade-user/userList",
    method: "get",
    loading: true,
    params,
  });
};

// 导出员工列表
export const exportUser = (params) => {
  return SystemAxios.axios({
    url: "/blade-user/export-user",
    method: "get",
    responseType: "blob",
    loading: true,
    params,
  });
};

// 获取路由菜单
export const ownMenus = (params) => {
  return SystemAxios.axios({
    url: "/blade-system/menu/ownMenus",
    method: "get",
    params,
  });
};

//添加部门
export const addOrEditDepart = (data) => {
  return SystemAxios.axios({
    url: "/blade-system/dept/submit",
    loading: true,
    method: "POST",
    data,
  });
};

//导出部门数据
export const exportDepartData = (data) => {
  return SystemAxios.axios({
    url: "/blade-system/dept/export",
    loading: true,
    method: "POST",
    responseType: "blob",
    data,
  });
};

//修改用户
export const editUser = (data) => {
  return SystemAxios.axios({
    url: "/blade-user/update",
    loading: true,
    method: "POST",
    data,
  });
};
// 权限设置
export const grantDataScope = (data) => {
  return SystemAxios.axios({
    url: "/blade-system/role/grantDataScope",
    loading: true,
    method: "POST",
    data,
  });
};

// 获取角色已绑定的数据权限
export const getGrantedDataScope = (params) => {
  return SystemAxios.axios({
    url: "/blade-system/role/getGrantedDataScope",
    method: "get",
    loading: true,
    params,
  });
};

// 获取角色权限字段信息
export const listFieldInfo = (params) => {
  return SystemAxios.axios({
    url: "/blade-system/fieldScope/listFieldInfo",
    method: "get",
    loading: true,
    params,
  });
};

// 导出角色
export const roleExport = (params) => {
  return SystemAxios.axios({
    url: "/blade-system/role/export",
    method: "get",
    responseType: "blob",
    loading: true,
    params,
  });
};

// 查询租户下所有部门
export const deptTree = (params) => {
  return SystemAxios.axios({
    url: "/blade-system/dept/tree",
    method: "get",
    params,
  });
};
// 查询租户下所有角色
export const getRoleList = (params) => {
  return SystemAxios.axios({
    url: "/blade-user/roleList",
    method: "get",
    params,
  });
};

// 查询租户下所有角色
export const getSystemRoleList = (params) => {
  return SystemAxios.axios({
    url: "/blade-system/role/roleList",
    method: "get",
    params,
  });
};

//关联用户树
export const getRelateTreeData = (params) => {
  return SystemAxios.axios({
    url: "/blade-system/dept/deptUserTreeList",
    method: "GET",
    params,
  });
};

//添加关联用户
export const addRelateUser = (data) => {
  return SystemAxios.axios({
    url: "/blade-system/dept/associatedUser",
    method: "POST",
    data,
  });
};

// 角色绑定用户
export const roleBindUser = (data) => {
  return SystemAxios.axios({
    url: "/blade-system/role/bindUser",
    method: "POST",
    loading: true,
    data,
  });
};

// 租户删除
export const clientDel = (params) => {
  return SystemAxios.axios({
    url: "/blade-system/tenant/delete",
    method: "get",
    loading: true,
    params,
  });
};

// 查询菜单管理
export const getMenu = (params) => {
  return SystemAxios.axios({
    url: "/blade-system/menu/ylTree",
    method: "get",
    loading: true,
    params,
  });
};

// 租户重置密码
export const clientResetPassword = (params) => {
  return SystemAxios.axios({
    url: "/blade-system/tenant/update/password",
    method: "get",
    loading: true,
    params,
  });
};

// 租户菜单管理信息
export const clientTreeMenu = (params) => {
  return SystemAxios.axios({
    url: "/blade-system/role/role-tenant",
    method: "get",
    loading: true,
    params,
  });
};
// 租户菜单管理信息保存
export const saveClientTree = (data) => {
  return SystemAxios.axios({
    url: "/blade-system/role/grant/tenant",
    method: "post",
    loading: true,
    data,
  });
};

//导出租户信息
export const exportClientData = (params) => {
  return SystemAxios.axios({
    url: "/blade-system/tenant/export-tenant",
    method: "get",
    responseType: "blob",
    loading: true,
    params,
  });
};
// 租户菜单管理信息
export const getLoginLog = (params) => {
  return SystemAxios.axios({
    url: "/blade-log/logLogin/list",
    method: "get",
    loading: true,
    params,
  });
};
// 登录日志模糊查询
export const getLikeLoginLog = (params) => {
  return SystemAxios.axios({
    url: "/blade-log/logLogin/likeList",
    method: "get",
    loading: true,
    params,
  });
};
// 操作日志模糊查询
export const getOperateLog = (params) => {
  return SystemAxios.axios({
    url: "/blade-log/operate/likeList",
    method: "get",
    loading: true,
    params,
  });
};
// 登录日志删除
export const loginLogDel = (id) => {
  return SystemAxios.axios({
    url: `/blade-log/logLogin/del/${id}`,
    loading: true,
    method: "get",
  });
};
// 操作日志删除
export const operateLogDel = (id) => {
  return SystemAxios.axios({
    url: `/blade-log/operate/del/${id}`,
    loading: true,
    method: "get",
  });
};
//导出操作日志
export const exportOperateLog = (params) => {
  return SystemAxios.axios({
    url: "/blade-log/operate/export-logOperate2",
    method: "get",
    responseType: "blob",
    loading: true,
    params,
  });
};
//导出登录日志
export const exportLoginLog = (params) => {
  return SystemAxios.axios({
    url: "/blade-log/logLogin/export-LogLogin2",
    method: "get",
    responseType: "blob",
    loading: true,
    params,
  });
};

// 查询角色绑定的员工
export const listGrantedUser = (params) => {
  return SystemAxios.axios({
    url: "/blade-system/role/listGrantedUser",
    method: "get",
    loading: true,
    params,
  });
};

// 字典列表
export const dictList = (params) => {
  return SystemAxios.axios({
    url: "/blade-system/dict/pageList",
    method: "get",
    loading: true,
    params,
  });
};

// 添加/修改字典
export const dictSubmit = (data) => {
  return SystemAxios.axios({
    url: "/blade-system/dict/submit",
    method: "post",
    loading: true,
    data,
  });
};

// 删除字典
export const dictRemove = (params) => {
  return SystemAxios.axios({
    url: "/blade-system/dict/remove",
    method: "post",
    loading: true,
    params,
  });
};

//导出字典
export const exportDict = (params) => {
  return SystemAxios.axios({
    url: "/blade-system/dict/export-dict",
    method: "get",
    responseType: "blob",
    loading: true,
    params,
  });
};

// 员工批量禁用或启用
export const userEnable = (params) => {
  return SystemAxios.axios({
    url: "/blade-user/userEnable",
    method: "get",
    loading: true,
    params,
  });
};

// 登录日志删除
export const loginLogDelBatch = (id) => {
  return SystemAxios.axios({
    url: `/blade-log/logLogin/delBatch/${id}`,
    loading: true,
    method: "get",
  });
};
// 操作日志删除
export const operateLogDelBatch = (id) => {
  return SystemAxios.axios({
    url: `/blade-log/operate/delBatch/${id}`,
    loading: true,
    method: "get",
  });
};

// 获取业务员
export const getListUser = () => {
  return SystemAxios.axios({
    url: `/blade-system/tenant/listUser`,
    loading: true,
    method: "get",
  });
};

// 获取用户下属
export const getUserByCharge = () => {
  return SystemAxios.axios({
    url: `/blade-user/getUserByCharge`,
    method: "get",
  });
};

// 员工导入模板
export const downloadTemplate = () => {
  return SystemAxios.axios({
    url: `/blade-user/downloadTemplate`,
    method: "get",
    responseType: "blob",
  });
};

// 员工批量导入
export const userImport = (data) => {
  return SystemAxios.axios({
    url: `/blade-user/userImport`,
    method: "post",
    data,
  });
};

// 用户列表
export const customerPage = (data) => {
  return SystemAxios.axios({
    url: `/blade-user/customer/page`,
    method: "post",
    loading: true,
    data,
  });
};

// 用户列表-导出
export const customerExportExcel = (data) => {
  return SystemAxios.axios({
    url: `/blade-user/customer/exportExcel`,
    method: "post",
    responseType: "blob",
    loading: true,
    data,
  });
};

// 用户列表-编辑
export const customerUpdate = (data) => {
  return SystemAxios.axios({
    url: `/blade-user/customer/update`,
    method: "post",
    loading: true,
    data,
  });
};

// 用户列表-编辑
export const customerAdd = (data) => {
  return SystemAxios.axios({
    url: `/blade-user/customer/add`,
    method: "post",
    loading: true,
    data,
  });
};

// 根据证件号获取客户经理
export const getOwnBusiness = (params) => {
  return SystemAxios.axios({
    url: `/ins-policy/insPolicyArchivePlayers/getOwnBusiness`,
    method: "get",
    loading: true,
    params,
  });
};
// 根据城市获取客户经理
export const getOwnBusinessArea = (params) => {
  return SystemAxios.axios({
    url: `/ins-policy/insPolicyArchivePlayers/getOwnBusinessPC`,
    method: "get",
    loading: true,
    params,
  });
};

// 用户列表-绑定推荐人
export const customerBindReferrer = (data) => {
  return SystemAxios.axios({
    url: `/blade-user/customer/bindReferrer`,
    method: "post",
    loading: true,
    data,
  });
};

// 用户列表-删除
export const customerDelete = (params) => {
  return SystemAxios.axios({
    url: `/blade-user/customer/delete`,
    method: "get",
    loading: true,
    params,
  });
};

// 用户重置密码
export const customerResetPwd = (data) => {
  return SystemAxios.axios({
    url: `/blade-user/customer/resetPwd`,
    method: "post",
    loading: true,
    data,
  });
};

// 推荐人列表
export const customerReferrerPage = (params) => {
  return SystemAxios.axios({
    url: `/blade-user/customer/referrerPage`,
    method: "get",
    loading: true,
    params,
  });
};
// 客服专线列表
export const getServiceList = (data) => {
  return SystemAxios.axios({
    url: `/blade-system/customer/service/getPage`,
    method: "post",
    loading: true,
    data,
  });
};
// 客服专线编辑
export const saveCustomerService = (data) => {
  return SystemAxios.axios({
    url: `/blade-system/customer/service/save`,
    method: "post",
    loading: true,
    data,
  });
};
// 客服专线删除
export const deleteCustomerService = (params) => {
  return SystemAxios.axios({
    url: `/blade-system/customer/service/batchDelete`,
    method: "get",
    loading: true,
    params,
  });
};
// 新增修改理赔区域
export const addClaimArea = (data) => {
  return SystemAxios.axios({
    url: `/ins-claims/claimsResponsible/saveOrUpdate`,
    method: "post",
    loading: true,
    data,
  });
};
// 理赔区域管理列表
export const getClaimAreaList = (params) => {
  return SystemAxios.axios({
    url: `/ins-claims/claimsResponsible/page`,
    method: "get",
    loading: true,
    params,
  });
};
// 删除理赔区域
export const deleteClaimArea = (params) => {
  return SystemAxios.axios({
    url: `/ins-claims/claimsResponsible/del`,
    method: "get",
    loading: true,
    params,
  });
};

// 文章类型列表
export const articleTypePage = (data) => {
  return SystemAxios.axios({
    url: `/blade-system/articleType/getPage`,
    method: "post",
    loading: true,
    data,
  });
};

// 文章类型列表
export const articleTypeList = (params) => {
  return SystemAxios.axios({
    url: `/blade-system/articleType/getList`,
    method: "get",
    params,
  });
};

// 编辑文章类型
export const articleTypeSave = (data) => {
  return SystemAxios.axios({
    url: `/blade-system/articleType/save`,
    method: "post",
    loading: true,
    data,
  });
};

// 删除文章类型
export const articleTypeDelete = (params) => {
  return SystemAxios.axios({
    url: `/blade-system/articleType/delete`,
    method: "get",
    loading: true,
    params,
  });
};

// 资讯列表
export const articlePage = (data) => {
  return SystemAxios.axios({
    url: `/blade-system/article/getPage`,
    method: "post",
    loading: true,
    data,
  });
};

// 资讯列表 添加编辑
export const articleSave = (data) => {
  return SystemAxios.axios({
    url: `/blade-system/article/save`,
    method: "post",
    loading: true,
    data,
  });
};

// 资讯详情
export const articleInfo = (params) => {
  return SystemAxios.axios({
    url: `/blade-system/article/getArticleInfo`,
    method: "get",
    loading: true,
    params,
  });
};

// 资讯 删除
export const articleDelete = (params) => {
  return SystemAxios.axios({
    url: `/blade-system/article/delete`,
    method: "get",
    loading: true,
    params,
  });
};

// 导出客服
export const customerexportByIds = (params) => {
  return SystemAxios.axios({
    url: `/blade-system/customer/service/exportByIds`,
    method: "get",
    loading: true,
    responseType: "blob",
    params,
  });
};

// 导出客服 - 条件
export const customerServiceExport = (data) => {
  return SystemAxios.axios({
    url: `/blade-system/customer/service/export`,
    method: "post",
    loading: true,
    responseType: "blob",
    data,
  });
};

// 导出资讯
export const articleExportByIds = (params) => {
  return SystemAxios.axios({
    url: `/blade-system/article/exportByIds`,
    method: "get",
    loading: true,
    responseType: "blob",
    params,
  });
};

// 导出资讯 - 条件
export const articleExport = (data) => {
  return SystemAxios.axios({
    url: `/blade-system/article/export`,
    method: "post",
    loading: true,
    responseType: "blob",
    data,
  });
};

// 导出理赔区域
export const exportClaimArea = (params) => {
  return SystemAxios.axios({
    url: `/ins-claims/claimsResponsible/export`,
    method: "get",
    loading: true,
    responseType: "blob",
    params,
  });
};

// 代理人列表
export const agentPage = (params) => {
  return SystemAxios.axios({
    url: `/ins-policy/agent/page`,
    method: "get",
    loading: true,
    params,
  });
};

// 添加/编辑 代理人
export const agentSaveOrUpdate = (data) => {
  return SystemAxios.axios({
    url: `/ins-policy/agent/saveOrUpdate`,
    method: "post",
    loading: true,
    data,
  });
};

// 代理人删除
export const agentDel = (params) => {
  return SystemAxios.axios({
    url: `/ins-policy/agent/del`,
    method: "get",
    loading: true,
    params,
  });
};

// 代理人详情
export const agentDetail = (params) => {
  return SystemAxios.axios({
    url: `/ins-policy/agent/detail`,
    method: "get",
    loading: true,
    params,
  });
};

// 代理人  列表使用
export const agentList = (params) => {
  return SystemAxios.axios({
    url: `/ins-policy/agent/list`,
    method: "get",
    params,
  });
};

// 导出代理人
export const agentExport = (params) => {
  return SystemAxios.axios({
    url: `/ins-policy/agent/export`,
    method: "get",
    loading: true,
    responseType: "blob",
    params,
  });
};

// 保单重复审核设置详情
export const processSettingDetail = (params) => {
  return SystemAxios.axios({
    url: `/ins-policy/processSetting/detail`,
    method: "get",
    params,
  });
};

// 保单重复审核设置
export const processSettingSaveOrUpdate = (data) => {
  return SystemAxios.axios({
    url: `/ins-policy/processSetting/saveOrUpdate`,
    method: "post",
    data,
  });
};

// 数据大屏获取用户在线
export const getUserOnlineView = (params) => {
  return SystemAxios.axios({
    url: `/blade-log/logLogin/getUserOnlineView`,
    method: "get",
    params,
  });
};

// 分销中心代理人列表
export const agentUserPage = (data) => {
  return SystemAxios.axios({
    url: `/blade-user/agent/page`,
    method: "post",
    loading: true,
    data,
  });
};

// 分销中心代理人列表 - 代理人
export const agentUserPageOfDLR = (data) => {
  return SystemAxios.axios({
    url: `/blade-user/agent/pageOfDLR`,
    method: "post",
    loading: true,
    data,
  });
};

// 代理人审核列表
export const agentUserPageOfSH = (data) => {
  return SystemAxios.axios({
    url: `/blade-user/agent/pageOfSH`,
    method: "post",
    loading: true,
    data,
  });
};

// 删除代理人审核记录
export const deleteOfSH = (params) => {
  return SystemAxios.axios({
    url: `/blade-user/agent/deleteOfSH`,
    method: "get",
    loading: true,
    params,
  });
};

// 删除代理人
export const agentUserDelete = (params) => {
  return SystemAxios.axios({
    url: `/blade-user/agent/delete`,
    method: "get",
    loading: true,
    params,
  });
};

// 导出代理人审核列表
export const exportExcelOfSH = (data) => {
  return SystemAxios.axios({
    url: "/blade-user/agent/exportExcelOfSH",
    method: "post",
    responseType: "blob",
    loading: true,
    data,
  });
};

// 导出代理人列表
export const exportExcelUser = (data) => {
  return SystemAxios.axios({
    url: "/blade-user/agent/exportExcel",
    method: "post",
    responseType: "blob",
    loading: true,
    data,
  });
};

// 审核 代理人
export const agentUserApprove = (data) => {
  return SystemAxios.axios({
    url: `/blade-user/agent/approve`,
    method: "post",
    loading: true,
    data,
  });
};

// 代理人审核详情
export const agentUserDetailOfSH = (params) => {
  return SystemAxios.axios({
    url: `/blade-user/agent/detailOfSH`,
    method: "get",
    loading: true,
    params,
  });
};

// 代理人信息详情
export const agentUserDetail = (params) => {
  return SystemAxios.axios({
    url: `/blade-user/agent/detail`,
    method: "get",
    loading: true,
    params,
  });
};

// 更新代理人信息
export const agentUserUpdate = (data) => {
  return SystemAxios.axios({
    url: `/blade-user/agent/update`,
    method: "post",
    loading: true,
    data,
  });
};

// 获取代理人的客户
export const agentUserPageCustomer = (params) => {
  return SystemAxios.axios({
    url: `/blade-user/customer/pageCustomer`,
    method: "get",
    loading: true,
    params,
  });
};

// 批量操作代理人可见方案
export const batchBindProgramme = (data) => {
  return SystemAxios.axios({
    url: `/blade-user/agent/batchBindProgramme`,
    method: "post",
    loading: true,
    data,
  });
};

// 代理人统计
export const agentStatistics = (data) => {
  return SystemAxios.axios({
    url: `/blade-user/agent/statistics`,
    method: "post",
    loading: true,
    data,
  });
};

// 根据业务员ID获取代理人列表
export const getAgentBySalesmanId = (params) => {
  return SystemAxios.axios({
    url: `/blade-user/agent/getAgentBySalesmanId`,
    method: "get",
    loading: true,
    params,
  });
};
// pc端风险减量监控
export const getRiskPage = (data, isLoading = false) => {
  return RiskAxios.axios({
    url: `/basic-risk/risk/riskPage`,
    method: "post",
    data,
    loading: isLoading,
  });
};
// pc端风险减量监控
export const userProject = (data) => {
  return RiskAxios.axios({
    url: `/basic-risk/risk/userProject`,
    method: "post",
    data,
  });
};
// 数据配置保存
export const saveDataConfig = (data) => {
  return RiskAxios.axios({
    url: `/basic-risk/dataConfig/saveDataConfig`,
    method: "post",
    data,
  });
};
// 数据配置获取
export const getDataConfig = (params) => {
  return RiskAxios.axios({
    url: `/basic-risk/dataConfig/getDetail`,
    method: "GET",
    params,
  });
};
// 数据配置获取
export const getWorkSituation = (params) => {
  return RiskAxios.axios({
    url: `/basic-risk/risk/getWorkSituation`,
    method: "get",
    params,
  });
};
// 数据配置获取
export const getHourCount = (params) => {
  return RiskAxios.axios({
    url: `/basic-risk/risk/getHourCount`,
    method: "get",
    params,
  });
};

// 员工批量导入
export const jsImport = (data) => {
  return RiskAxios.axios({
    url: `/basic-risk/risk/jsAtdImport`,
    method: "post",
    data,
    loading: true,
  });
};

// 员工导入模板
export const downloadTemplatejs = () => {
  return SystemAxios.axios({
    url: `/basic-risk/risk/downloadTemplate`,
    method: "post",
    responseType: "blob",
  });
};


// 绩效 部门看板
export const deptDataBoard = (data) => {
   return SystemAxios.axios({
      url: "/blade-system/dept/deptDataBoard",
      method: "POST",
      loading: true,
      data
   });
};


// 绩效 项目看板
export const projectDataBoard = (data) => {
   return SystemAxios.axios({
      url: "/basic-data/project/projectDataBoard",
      method: "POST",
      loading: true,
      data
   });
};


// 获取所有的部门架构
export const allDeptTree = (data) => {
   return SystemAxios.axios({
      url: "/blade-system/dept/allDeptTree",
      method: "POST",
      loading: true,
      data
   });
};

// pc端风控管理-根据项目获取
export const projectCustomer = (params) => {
  return RiskAxios.axios({
    url: `/basic-risk/riskManage/projectCustomer`,
    method: "get",
    params,
  });
};

// pc端风控管理-根据项目获取
export const getRickVehicleGroup = (params) => {
  return RiskAxios.axios({
    url: `/basic-risk/riskManage/getVehicleGroup/` + params,
    method: "get",
    params,
  });
};

// pc端风控管理-日常巡检列表
export const dailyInspection = (data) => {
  return RiskAxios.axios({
    url: `/basic-risk/riskManage/dailyInspection`,
    method: "post",
    data,
  });
};

// pc端风控管理-在线记录
export const onlineRecording = (data) => {
  return RiskAxios.axios({
    url: `/basic-risk/riskManage/onlineRecording`,
    method: "post",
    data,
  });
};

// pc端风控管理-在线记录导出
export const onlineExport = (data) => {
  return RiskAxios.axios({
    url: `/basic-risk/riskManage/onlineExport`,
    method: "post",
    responseType: "blob",
    timeout: 180000000,
    loading: true,
    data,
  });
};

// pc端风控管理-处理
export const riskHandle = (data) => {
  return RiskAxios.axios({
    url: `/basic-risk/riskManage/handle`,
    method: "post",
    loading: true,
    data,
  });
};

// pc端风控管理-设备故障
export const deviceFault = (params) => {
  return RiskAxios.axios({
    url: `/basic-risk/riskManage/fault`,
    method: "get",
    params,
  });
};

// pc端风控管理-设备故障类型
export const getDeviceAlarmConf = (params) => {
  return RiskAxios.axios({
    url: `/basic-risk/riskManage/getAlarmConf`,
    method: "get",
    params,
  });
};

// pc端风控管理-历史故障
export const historyFault = (data) => {
  return RiskAxios.axios({
    url: `/basic-risk/riskManage/historyFault`,
    method: "post",
    data,
  });
};

// pc端风控管理-历史故障导出
export const historyFaultExport = (data) => {
  return RiskAxios.axios({
    url: `/basic-risk/riskManage/historyFaultExport`,
    method: "post",
    responseType: "blob",
    timeout: 180000000,
    loading: true,
    data,
  });
};

// pc端风控管理-设备位置天气
export const deviceWeather = (params) => {
  return RiskAxios.axios({
    url: `/basic-risk/riskManage/weather`,
    method: "get",
    params,
  });
};

// pc端风控管理-运行监控列表
export const operationMonitoring = (data) => {
  return RiskAxios.axios({
    url: `/basic-risk/riskManage/operationMonitoring`,
    method: "post",
    data,
  });
};

// pc端风控管理-干预记录列表
export const interventionList = (data) => {
  return RiskAxios.axios({
    url: `/basic-risk/riskManage/intervention`,
    method: "post",
    data,
  });
};

// pc端风控管理-干预记录删除
export const interventionDelete = (params) => {
  return RiskAxios.axios({
    url: `/basic-risk/riskManage/delete`,
    method: "get",
    params,
  });
};

// pc端风控管理-干预记录导出
export const interventionExport = (data) => {
  return RiskAxios.axios({
    url: `/basic-risk/riskManage/interventionExport`,
    method: "post",
    responseType: "blob",
    timeout: 180000000,
    loading: true,
    data,
  });
};


// 获取验证码
export const sendSMSCode = (params) => {
  return SystemAxios.axios({
    url: `/blade-auth/sendSMSCode`,
    method: "get",
    loading: true,
    ignoreAuth: true,
    params,
  });
};

// 二次认证登录
export const secondVerify = (data) => {
  return SystemAxios.axios({
    url: `/blade-auth/secondVerify`,
    method: "post",
    ignoreAuth: true,
    data,
  });
};

