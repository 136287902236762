// 实时定位&实时视频配置
export const realTimeConfig = {
  // 车辆更新时间, 大于15秒 (秒)
  updateVehicleTime: 15,
  // 车辆聚合最小聚合点数量 (2 ~ 1000), 默认50
  vehicleMinClusterNum: 2,
  // 视频窗口布局, 默认 4 (2x2); 可选: [1x1,2x2,3x3,4x4,5x5]
  videoGrid: 4,
  // 视频播放定时关闭时间(分钟), 默认5分钟, 0: 表示从不
  videoCloseTime: 5,
};
// 录像回放配置
export const vodConfig = {
  // 视频播放定时关闭时间(分钟), 默认5分钟, 0: 表示从不
  videoCloseTime: 5,
};
// 轨迹回放配置
export const trackConfig = {
  // 停车时间大于(分钟)
  parkingTime: 5,
  // 定位状态(数据), 可选:[ 全部, 过滤不定位轨迹, 过滤定位轨迹]
  positioningState: 1,
  // 过滤停车轨迹
  filterParkingChecked: true,
  // 默认播放速度, 可选: [一般, 快, ,慢]
  trackPlaySpeed: 1200,
};
// 视频轮询配置
export const videoPollingConfig = {
  // 轮询通道号, 多选, 可选[1,2,3,4,5,6,7,8]
  pollingChannels: [1, 2, 3, 4],
  // 视频窗口布局, 默认 4 (2x2); 可选: [1x1,2x2,3x3,4x4,5x5]
  videoGrid: 4,
  // 播放时长(秒)
  playTime: 10,
  // 总时长(分钟)
  totalPlayTime: 30,
};