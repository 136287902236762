/*
 * @Author: zhuchaowei 952698119@qq.com
 * @Date: 2022-06-27 16:07:07
 * @LastEditors: zhuchaowei 952698119@qq.com
 * @LastEditTime: 2022-06-30 08:25:14
 * @FilePath: \platform-pc-front\src\router\modules\QueryCenter.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/**
 * 查询中心路由
 */


/**
 * 报表查询
 */
export const ReportQuery = {
   path: '/ReportQuery',
   name: 'ReportQuery',
   component: () => import('@/views/ReportQuery'),
   meta: {
      label: '报表查询',
      permId: 'M13',
      icon: [
         'icon-baobiaochaxun',
         require('@/assets/images/header/报表查询.png'),
      ],
   },
};

/**
 * 工时管理
 */
export const workManagement = {
   path: '/workManagement',
   name: 'workManagement',
   component: () => import('@/views/workManagement/index.vue'),
   meta: {
      label: '工时管理',
      permId: 'M14',
      icon: [
         'icon-gongshitongji',
         require('@/assets/images/header/工时统计.png'),
      ],
   },
};

/**
 * 油耗管理
 */
export const oilManagements = {
   path: '/oilManagement',
   name: 'oilManagement',
   component: () => import('@/views/oilManagement'),
   meta: {
      label: '油耗管理',
      permId: 'M13SM06',
      icon: [
         'icon-youhao',
         require('@/assets/images/header/油耗统计.png'),
      ],
   }
};

/**
 * 油耗统计
 */
// export const OilStatistics = {
//   path: '/OilStatistics',
//   name: 'OilStatistics',
//   component: () => import('@/views/OilStatistics'),
//   meta: {
//     label: '油耗统计',
//     permId: 'M15',
//     icon: [
//       'icon-youhao',
//       require('@/assets/images/header/油耗统计.png'),
//     ],
//   }
// };
// export const WorkingTimeStatistics = {
//    path: '/WorkingTimeStatistics',
//    name: 'WorkingTimeStatistics',
//    component: () => import('@/views/WorkingTimeStatistics/index.vue'),
//    meta: {
//      label: '工时统计',
//      permId: 'M14',
//      icon: [
//        'icon-gongshitongji',
//        require('@/assets/images/header/工时统计.png'),
//      ],
//    },
//  };
