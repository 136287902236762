<template>
  <span
    :title="title"
    :style="iconStyle"
    :class="['ElIcon', name]"
    @click="$emit('click', $event)"
  ></span>
</template>

<script>

/**
 * 封装elementui的Icon
 */


export default {
  name: 'ElementIcon',
  props: {
    /**
     * 鼠标悬停提示文本
     */
    title: {
      type: String,
      default: '',
    },
    /**
     * elementui icon的名称
     */
    name: {
      type: String,
      default: ''
    },
    /**
     * 大小
     */
    size: {
      type: Number,
      default: 18
    },
    /**
     * 颜色
     */
    color: {
      type: String,
      default: null,
    }
  },
  computed: {
    /**
     * icon的样式
     */
    iconStyle() {
      const styleObj = {};
      const { size, color } = this;
      styleObj.fontSize = `${size}px`;
      if (color !== null) {
        styleObj.color = color;
      }

      return styleObj;
    }
  }
}
</script>

<style lang="scss" scoped>
.ElIcon {
  cursor: pointer;
}
</style>
