import { number } from "echarts";

/**
 * 使用类型
 * @param {(Object|string|number)} time
 * @param {string} cFormat 输出方式
 * @returns {string | null}
 */
export function parseTime(time, cFormat) {
   if (arguments.length === 0 || !time) {
      return null;
   }
   const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
   let date;
   if (typeof time === 'object') {
      date = time;
   } else {
      if ((typeof time === 'string')) {
         if ((/^[0-9]+$/.test(time))) {
            time = parseInt(time);
         } else {
            time = time.replace(new RegExp(/-/gm), '/');
         }
      }

      if ((typeof time === 'number') && (time.toString().length === 10)) {
         time = time * 1000;
      }
      date = new Date(time);
   }
   const formatObj = {
      y: date.getFullYear(),
      m: date.getMonth() + 1,
      d: date.getDate(),
      h: date.getHours(),
      i: date.getMinutes(),
      s: date.getSeconds(),
      a: date.getDay()
   };
   const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
      const value = formatObj[key];
      if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value]; }
      return value.toString().padStart(2, '0');
   });
   return time_str;
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time, option) {
   if (('' + time).length === 10) {
      time = parseInt(time) * 1000;
   } else {
      time = +time;
   }
   const d = new Date(time);
   const now = Date.now();

   const diff = (now - d) / 1000;

   if (diff < 30) {
      return '刚刚';
   } else if (diff < 3600) {
      // less 1 hour
      return Math.ceil(diff / 60) + '分钟前';
   } else if (diff < 3600 * 24) {
      return Math.ceil(diff / 3600) + '小时前';
   } else if (diff < 3600 * 24 * 2) {
      return '1天前';
   }
   if (option) {
      return parseTime(time, option);
   } else {
      return (
         d.getMonth() +
         1 +
         '月' +
         d.getDate() +
         '日' +
         d.getHours() +
         '时' +
         d.getMinutes() +
         '分'
      );
   }
}

export function formerlyTime(time) {
   const d = new Date(time).getTime();
   const now = new Date().getTime();
   let endTime = (now - d) / 1000;
   let timeDay = parseInt(endTime / 60 / 60 / 24);        //相差天数
   endTime = endTime - timeDay * 60 * 60 * 24;
   let timeHour = parseInt(endTime / 60 / 60);            //相差小时
   endTime = endTime - timeHour * 60 * 60;
   let timeMinutes = parseInt(endTime / 60);              //相差分钟
   console.log(timeDay, '---', timeHour, '---', timeMinutes);
   return `${ timeDay }天${ (timeHour + '').length > 1 ? timeHour : '0' + timeHour }小时 ${ (timeMinutes + '').length > 1 ? timeMinutes : '0' + timeMinutes }分钟`;
}

export function formerlyTime2(time1, time2) {
   const d = new Date(time1).getTime();
   const now = new Date(time2).getTime();
   let endTime = (now - d) / 1000;
   let timeDay = parseInt(endTime / 60 / 60 / 24);        //相差天数
   endTime = endTime - timeDay * 60 * 60 * 24;
   let timeHour = parseInt(endTime / 60 / 60);            //相差小时
   endTime = endTime - timeHour * 60 * 60;
   let timeMinutes = parseInt(endTime / 60);              //相差分钟
   console.log(timeDay, '---', timeHour, '---', timeMinutes);
   return `${ timeDay }天${ (timeHour + '').length > 1 ? timeHour : '0' + timeHour }小时 ${ (timeMinutes + '').length > 1 ? timeMinutes : '0' + timeMinutes }分钟`;
}

/**
 * @param {(Object|string|number)} enterTime
 */
export function getDwellTime(enterTime, dwellTime) {
   let tiem = null;
   if (!enterTime) {
      return `请传入开始时间`;
   }
   if (dwellTime) {
      tiem = new Date(dwellTime);
   } else {
      tiem = new Date();
   }
   let H = Math.floor((tiem - enterTime) / 1000 / 60 / 60);
   let I = Math.floor((tiem - enterTime) / 1000 / 60);
   let S = Math.floor((tiem - enterTime) / 1000);
   if (H > 0) {
      I -= H * 60;
      S -= H * 60 * 60;
      if (I > 0) {
         S -= I * 60;
      }
   } else if (I > 0) {
      S -= I * 60;
   }
   return `${ H }小时${ I }分钟${ S }秒`;
}

/**
 * @param {(Object|string|number)} enterTime
 */
export function getDwellTime2(enterTime) {
   let tiem = null;
   if (!enterTime) {
      return `请传入秒数`;
   }
   // if (dwellTime) {
   //    tiem = new Date(dwellTime);
   // } else {
   //    tiem = new Date();
   // }
   let H = Math.floor((enterTime) / 1000 / 60 / 60);
   let I = Math.floor((enterTime) / 1000 / 60);
   let S = Math.floor((enterTime) / 1000);
   if (H > 0) {
      I -= H * 60;
      S -= H * 60 * 60;
      if (I > 0) {
         S -= I * 60;
      }
   } else if (I > 0) {
      S -= I * 60;
   }
   return `${ H ? H+'小时':'' }${ I ? I+'分钟':'' }${ S }秒`;
}

export function getDwellTime4(enterTime) {
   let tiem = null;
   if (!enterTime) {
      return `--`;
   }
   // if (dwellTime) {
   //    tiem = new Date(dwellTime);
   // } else {
   //    tiem = new Date();
   // }
   let H = Math.floor((enterTime) / 1000 / 60 / 60);
   let I = Math.floor((enterTime) / 1000 / 60);
   let S = Math.floor((enterTime) / 1000);
   if (H > 0) {
      I -= H * 60;
      S -= H * 60 * 60;
      if (I > 0) {
         S -= I * 60;
      }
   } else if (I > 0) {
      S -= I * 60;
   }
   return `${ H ? H+'时':'' }${ I ? I+'分':'' }`;
}

/**
 * @param {(Object|string|number)} enterTime
 */
export function getDwellTime3(enterTime, length = 3) {
   let tiem = null;
   if (!enterTime) {
      return [0,0,0];
   }
   let H = Math.floor((enterTime) / 1000 / 60 / 60);
   let I = Math.floor((enterTime) / 1000 / 60);
   let S = Math.floor((enterTime) / 1000);
   if (H > 0 && length > 2) {
      I -= H * 60;
      S -= H * 60 * 60;
      if (I > 0) {
         S -= I * 60;
      }
   } else if (I > 0 && length > 1) {
      S -= I * 60;
   }
   return length == 3 ? [H, I, S] : length == 2 ? [H, I] : [I];
}

export function getDwellTime5(enterTime, type = 'day', length = 3) {
   if (!enterTime) {
      return '--';
   }
   let timeName = '';
   let dSeconds = 1000 * 60 * 60 * 24;
   let hSeconds = 1000 * 60 * 60;
   let iSeconds = 1000 * 60;
   let sSeconds = 1000;
   let D = '';
   let H = '';
   let I = '';
   let S = '';
   switch (type) {
      case 'day':
         D = Math.floor((enterTime) / dSeconds);
         H = Math.floor((Number(enterTime) % dSeconds) / hSeconds);
         I = Math.floor((Number(enterTime) % hSeconds) / iSeconds);
         S = Math.floor((Number(enterTime) % iSeconds) / sSeconds);
         timeName = `${ D && D > 0 ? D + '天' : '' }${ (D && D > 0) || (H && H > 0) ? H + '时' : '' }${ (D && D > 0) || (H && H > 0) || (I && I > 0) ? I + '分' : '' }${ ((D && D > 0) || (H && H > 0) || (I && I > 0) || (S && S > 0)) && length > 3 ? S + '秒' : '' }`
         break;
      case 'h':
         H = Math.floor(Number(enterTime) / hSeconds);
         I = Math.floor((Number(enterTime) % hSeconds) / iSeconds);
         S = Math.floor((Number(enterTime) % iSeconds) / sSeconds);
         timeName = `${ (H && H > 0) ? H + '时' : '' }${ (H && H > 0) || (I && I > 0) ? I + '分' : '' }${ ((H && H > 0) || (I && I > 0) || (S && S > 0)) ? S + '秒' : '' }`
         break;
   }
   return timeName;
}

export function getDwellTime6(enterTime, index) {
   if (!enterTime) {
      return '--';
   }
   let dSeconds = 1000 * 60 * 60 * 24;
   let hSeconds = 1000 * 60 * 60;
   let iSeconds = 1000 * 60;
   let sSeconds = 1000;
   let D = '';
   let H = '';
   let I = '';
   let S = '';
   D = Math.floor((enterTime) / dSeconds);
   H = Math.floor((Number(enterTime) % dSeconds) / hSeconds);
   I = Math.floor((Number(enterTime) % hSeconds) / iSeconds);
   S = Math.floor((Number(enterTime) % iSeconds) / sSeconds);
   return [D, H, I, S][index];
}

/**
 * @param {string} input value
 * @returns {number} output value
 */
export function byteLength(str) {
   // returns the byte length of an utf8 string
   let s = str.length;
   for (var i = str.length - 1; i >= 0; i--) {
      const code = str.charCodeAt(i);
      if (code > 0x7f && code <= 0x7ff) s++;
      else if (code > 0x7ff && code <= 0xffff) s += 2;
      if (code >= 0xDC00 && code <= 0xDFFF) i--;
   }
   return s;
}

/**
 * @param {Array} actual
 * @returns {Array}
 */
export function cleanArray(actual) {
   const newArray = [];
   for (let i = 0; i < actual.length; i++) {
      if (actual[i]) {
         newArray.push(actual[i]);
      }
   }
   return newArray;
}


/**
 * @param {string} type
 * @returns {Date}
 */
export function getTime(type) {
   if (type === 'start') {
      return new Date().getTime() - 3600 * 1000 * 24 * 90;
   } else {
      return new Date(new Date().toDateString());
   }
}
export function getTimeState() {
   // 获取当前时间
   let timeNow = new Date();
   // 获取当前小时
   let hours = timeNow.getHours();
   // 设置默认文字
   let text = ``;
   // 判断当前时间段
   if (hours >= 0 && hours <= 10) {
      text = `早上好`;
   } else if (hours > 10 && hours <= 14) {
      text = `中午好`;
   } else if (hours > 14 && hours <= 18) {
      text = `下午好`;
   } else if (hours > 18 && hours <= 24) {
      text = `晚上好`;
   }
   // 返回当前时间段对应的状态
   return text;
}