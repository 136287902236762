/*
 * @Date: 2023-11-01 14:07:07
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/**
 * 
 */

/**
 * 风控管理
 */
export const riskControlManagement = {
  path: "/riskControlManagement",
  name: "riskControlManagement",
  component: () =>
    import("@/views/riskControlManagement/index.vue"),
  meta: {
    label: "风控管理",
    permId: "newFKGL",
    icon: ["icon-dapingzhanshi", require("@/assets/images/newRiskControl/router-icon.png")],
    affix: true,
  },
};

export const monitorDetails = {
  path: "/monitorDetails",
  name: "monitorDetails",
  ignoreKeepAlive: true,
  component: () =>
    import(
      "@/views/riskControlManagement/monitorDetails.vue"
    ),
  meta: {
    label: "风控管理-驾驶行为",
    permId: "newFKGL",
    icon: ["icon-dapingzhanshi", require("@/assets/images/header/风控.png")],
  },
};

export const alarmMonitoring = {
  path: "/alarmMonitoring",
  name: "alarmMonitoring",
  ignoreKeepAlive: true,
  component: () =>
    import(
      "@/views/riskControlManagement/alarmMonitoring.vue"
    ),
  meta: {
    label: "风控管理-报警监控",
    permId: "newFKGL",
    icon: ["icon-dapingzhanshi", require("@/assets/images/header/风控.png")],
  },
};

export const interventionRecord = {
  path: "/interventionRecord",
  name: "interventionRecord",
  ignoreKeepAlive: true,
  component: () =>
    import(
      "@/views/riskControlManagement/interventionRecord.vue"
    ),
  meta: {
    label: "风控管理-干预记录",
    permId: "newFKGL-record",
    icon: ["icon-dapingzhanshi", require("@/assets/images/header/风控.png")],
  },
};

export const riskControlReport = {
  path: "/riskControlReport",
  name: "riskControlReport",
  ignoreKeepAlive: true,
  component: () =>
    import(
      "@/views/riskControlManagement/riskControlReport.vue"
    ),
  meta: {
    label: "风控管理-风控报告",
    permId: "newFKGL-report",
    icon: ["icon-dapingzhanshi", require("@/assets/images/header/风控.png")],
  },
};
