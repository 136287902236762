/**
 * WebSoket命令链路, 用于指令下发
 */
import CmdRequest from './cmdRequest';

/**
 * 初始化命令链路
 */
function initCmdRequest(config) {

  // username: this.user.name,
  // userkey: this.user.sid,
  // baseURL: appConfig.base2GCmdUrl,
  cmdWsObj.request = new CmdRequest(config);
}

/**
 * 销毁
 */
function destroy() {
  if (cmdWsObj.request) {
    cmdWsObj.request.clearMsg();
    cmdWsObj.request.destroy();
  }
}


/**
 * 命令状态
 */
const CmdStatus = {
  0: '响应失败',
  1: '设备命令成功',
  2: '设备在线，命令下发成功',
  102: '响应超时',
  1001: '没有注册',
  1002: '设备不在线',
  1003: '设备响应超时',
  1005: '设备不支持该功能'
};

/**
 * 命令类型
 */
const _CmdType = {
  devParamQuery: '终端参数查询',
  terminalCtrl: '终端控制',
  intervalPhoto: '区间拍照',
  phoneMonitor: '电话回拨(监听)',
  internetParamSet: '网络配置',
  backParamSet: '回传配置',
  travelParamSet: '行驶参数配置',
  vehicleOdometerReadSet: '车辆里程表读数设置',
  textDownloadSet: '文本下发', // 调度下发
  subiaoActiveParamQuery: '主动安全参数查询',
  subiaoAdasParamSet: '高级驾驶辅助系统参数设置(ADAS)',
  subiaoDsmParamSet: '驾驶员状态监测系统参数设置(DSM)',
  ICCardParamSet: 'IC卡参数设置',
  ICCardParamQuery: 'IC卡参数查询',
  terminalParamSet: '其他参数设置',
  setContacts: '电话号码参数设置',
  searchMediaDataStorage: '存储多媒体数据检索',
  saveMediaDataUploadCommand: '存储多媒体数据上传',
  gbTerminalParamSet: '过检终端参数设置',
  appointDevParamQuery: '指定终端参数查询',
  dataThroughDownlink: '数据下行透传',
  queryRegionRoute: '查询区域或路线数据',
  upgradestatus: '升级结果通知',
  blindSpotDetectionParam: '盲区检测系统请求参数',
  notInAccordanceProvisionsAndOverCrowdingParam: '不按规定上下客及超员检测系统参数',
  monitorPhoneSet: '监听电话号码',
  illegalDrivingSectionSet: '违规行驶时间段',
  fatigueThresholdDiffSet: '疲劳驾驶预警差值',
  vehiclePlateColorSet: '车牌颜色',
  timingPhotoSet: '定时拍照',
  slagCarElectronicWaybillSet: '下发电子运单',
  slagCarElectronicWaybillDelete: '撤销电子运单',
  slagCarElectronicWaybillQuery: '查询电子运单',
  nmeaDataPassThroughOpen: 'NMEA透传数据',
  driverIdentityDiscriminateParamSet: '驾驶员身份识别',
  vehicleOperationMonitoringParamSet: '车辆运行监测参数',
  driverBehaviorMonitoringSystemParamSet: '驾驶员驾驶行为参数',
  equipmentFailureMonitoringParamSet: '设备失效监测系统参数',
  platformInspectionInformation: '平台巡检监测参数',
  platformPatrolDriverInformation: '平台巡检驾驶员身份信息检测',
  positionTracking: '位置跟踪',
  adasAlarmProcess: 'adas报警处理',
  generalAlarmProcess: '常规报警处理'
};

export const CmdType = Object.keys(_CmdType).reduce((obj, key) => {
  obj[key] = key;
  return obj;
}, {});


/**
 * 获取命令状态文本
 */
export function getCmdStatusLabel(status) {
  return CmdStatus[status * 1];
}

/**
 * 获取命令类型文本
 */
export function getCmdTypeLabel(cmdType) {
  return _CmdType[cmdType];
}

/**
 * 链路存储对象
 */
const cmdWsObj = {
  init: initCmdRequest,
  request: null,
  destroy,
};

export default cmdWsObj;