/*
 * @Author: zhuchaowei
 * @Date: 2024-05-11 13:06:18
 * @email: 952698119@qq.com
 * @LastEditors: zhuchaowei
 * @LastEditTime: 2024-05-17 10:13:42
 * @Description:
 */
//数据看板
export const safeDutyDataBoard = {
  path: "/safeDutyDataBoard",
  name: "safeDutyDataBoard",
  component: () => import("@/views/safeDutyInsuredManagement/sdDataBoard.vue"),
  meta: {
    label: "数据看板",
    permId: "M20-sd-index",
    icon: require("@/assets/images/safeDuty/menu/数据看板.png"),
  },
};
//企业数据看板
export const sdDataBoardEnterprise = {
  path: "/sdDataBoardEnterprise",
  name: "sdDataBoardEnterprise",
  component: () =>
    import(
      "@/views/safeDutyInsuredManagement/subPage/sdDataBoardEnterprise.vue"
    ),
  meta: {
    label: "企业数据看板",
    permId: "M20-sd-index",
    icon: require("@/assets/images/safeDuty/menu/数据看板.png"),
  },
};
//保司数据看板
export const sdDataBoardCompany = {
  path: "/sdDataBoardCompany",
  name: "sdDataBoardCompany",
  component: () =>
    import("@/views/safeDutyInsuredManagement/subPage/sdDataBoardCompany.vue"),
  meta: {
    label: "保司数据看板",
    permId: "M20-sd-index",
    icon: require("@/assets/images/safeDuty/menu/数据看板.png"),
  },
};
//服务数据看板
export const sdDataBoardService = {
  path: "/sdDataBoardService",
  name: "sdDataBoardService",
  component: () =>
    import("@/views/safeDutyInsuredManagement/subPage/sdDataBoardService.vue"),
  meta: {
    label: "服务数据看板",
    permId: "M20-sd-index",
    icon: require("@/assets/images/safeDuty/menu/数据看板.png"),
  },
};
