// 引入重置样式  https://github.com/necolas/normalize.css
import "normalize.css";
import "animate.css";
import "@/style/Base.scss"; // 全局样式
import "@/assets/font/font.css"; // 字体引入样式
// import 'vue-draggable-resizable/dist/VueDraggableResizable.css'
import umtrack from "./utils/umtrack.js";
import numberInput from "./utils/numberInput";
import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";

import VueClipBoard from "vue-clipboard2";

import Element from "element-ui";

// 引入Icon组件
import { Iconfont, ElIcon } from "@/components/Icon";
import { useUTable } from "@/components/VirtualTable";

import VueDND from "awe-dnd";

import VDistpicker from "v-distpicker";

// import 'froala-editor/css/froala_editor.pkgd.min.css'
// import 'froala-editor/css/froala_style.min.css'
// import 'froala-editor/js/froala_editor.pkgd.min.js'
// import 'froala-editor/js/languages/zh_cn.js'
// import 'froala-editor/js/plugins.pkgd.min.js'
// import VueFroala from 'vue-froala-wysiwyg'

// Vue.use(VueFroala)
import VueQuillEditor from "vue-quill-editor";

// require styles
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

Vue.use(VueQuillEditor /* { default global options } */);

Vue.component("v-distpicker", VDistpicker);

Vue.use(VueClipBoard);

Vue.use(VueDND);

Vue.config.productionTip = false;

Vue.directive("number-input", numberInput);
// 注册全局组件
useIconfont();
useElementUI();
useElIcon();
useUTable();

/**
 * 启动
 */
async function startApp() {
  // TODO: 这里提前处理必要的工作
  // 挂载友盟描点方法
  Vue.prototype.$umaApi = new umtrack();

  new Vue({
    store,
    router,
    render: (h) => h(App),
  }).$mount("#app");
}

// 开始渲染实例
startApp();

function useElementUI() {
  Vue.use(Element, { size: "small" });
}

// Iconfont组件, 注册全局组件
function useIconfont() {
  // NOTE: svg可以通过`font-size`改变图标大小
  // NOTE: svg通过`color`改变图标颜色, 需要在iconfont.cn上拷贝一个副本, 并去色, 建议副本命名: 图标名称-0
  // 去色方法: iconfont.cn -> [批量操作] -> [选择图标] -> [批量去色] -> [确认]
  Vue.component("Iconfont", Iconfont);
}

// elementui icon
function useElIcon() {
  Vue.component("ElIcon", ElIcon);
}

// DPR 设备像素比，指CSS逻辑像素对于物理像素的倍数
// 辨率要除以DPI比例，才是逻辑分辨率。
// console.log(window.devicePixelRatio);
// console.log(screen.width, screen.height);
// console.log(screen.availWidth, screen.availHeight);
// console.log(document.documentElement.clientWidth, document.documentElement.clientHeight);
