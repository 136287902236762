/**
 * 规则管理路由
 */

/**
 * 区域配置
 */
export const AreaConfiguration = {
  path: '/AreaConfiguration',
  name: 'AreaConfiguration',
  component: () => import('@/views/AreaConfiguration'),
  meta: {
    label: '区域配置',
    permId: 'M11SM01',
    icon: 'icon-quyupeizhi1',
  }
};

/**
 * 线路配置
 */
export const LineConfiguration = {
  path: '/LineConfiguration',
  name: 'LineConfiguration',
  component: () => import('@/views/LineConfiguration'),
  meta: {
    label: '线路配置',
    permId: 'M11SM02',
    icon: 'icon-xianlupeizhi',
  }
};


/**
 * 位置点
 */
// export const LocationPoint = {
//   path: '/LocationPoint',
//   name: 'LocationPoint',
//   component: () => import('@/views/LocationPoint'),
//   meta: {
//     label: '位置点',
//   }
// };


/**
 * 终端控制
 */
export const TerminalControl = {
  path: '/TerminalControl',
  name: 'TerminalControl',
  component: () => import('@/views/TerminalControl'),
  meta: {
    label: '终端控制',
    permId: 'M11SM03',
    icon: 'icon-zhongduankongzhi',
  }
};
