/**
 * 路由处理
 */

// 默认路由
import { defaultRouteMeta } from '@/router/props';
import { RootRoute } from './root';
import { NotFound } from '../modules/404';
import { Login, foreignVideoPolling, foreignBigScreenDisplayVideoPolling } from '../modules/Login';
import { Help } from '../modules/Help';
import { Recharge } from '../modules/Recharge';
import { forEachRoutes, mergeRoutes } from '@/router/helper';

// 路由列表
const routes = [
  NotFound,
  Login,
  Help,
  RootRoute,
  Recharge,
  // --------------------------外链--------------------------------------
  foreignVideoPolling,
  foreignBigScreenDisplayVideoPolling,
];

// 合并默认RouteMeta
forEachRoutes(
  routes,
  (route, parent) => {
    route.meta = {
      ...defaultRouteMeta,
      ...(route.meta || {}),
    };

    // 拼接父路由的 `path`
    if (parent) {
      // 将多个`//` 替换为 `/`
      route.path = (parent.path + route.path).replace(/\/+/, '/');
    }
  });

export default routes;