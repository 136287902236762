export const uuid = () => {
  let r = ["xxxxxxxx", "xxxx", "4xxx", "yxxx", "xxxxxxxxxxxx"];
  let r2 = [];
  let p = "";
  let len = 16;
  while (p.length < len * 2) {
    let j = Math.floor(Math.random() * r.length);
    j = j > r.length - 1 ? 0 : j;
    p += r[j];
    r2.push(r.splice(j, 1));
    if (r.length === 0) {
      r = r2;
      r2 = [];
    }
  }
  let d = new Date().getTime();
  let s = p.replace(/[xy]/g, function (c) {
    let r = (d + Math.random() * len) % len | 0;
    d = Math.floor(d / len);
    return (c == "x" ? r : (r & 0x3) | 0x8).toString(len);
  });
  return s;
};