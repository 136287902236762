<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  watch: {
    $route: {
      handler() {
        this.$umaApi.umaEnterTime();
      },
      immediate: true
    }
  },
}
</script>

<style lang="scss">
html,
body {
  width: 100vw;
  height: 100vh;
  // user-select: none;
}
// span,
// input,
// label {
//   user-select: text;
// }
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
</style>
