/**
 * 路由配置目录
 */


import Vue from 'vue';
import Router from 'vue-router';
import routes from './route';
import { setupPermissionGuard } from './guard';

function useRouter() {
  const routePush = Router.prototype.push;
  // 处理 `Router.push` 相同导航异常
  Router.prototype.push = function push(...option) {
    return routePush.call(this, ...option).catch(err => { });
  };
  Vue.use(Router);
};

useRouter();

const router = new Router({
  routes: routes,
});

setupPermissionGuard(router);

export default router;