/**
 * 删除字符中的控制字符
 */
export function rmCtrlChar(val) {
  const ctrlCodeArr = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9,
    10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
    20, 21, 22, 23, 24, 25, 26, 27, 28, 29,
    30, 31, 127
  ];

  let strArr = val.trim().split('');
  let i = strArr.length;
  while (i--) {
    if (ctrlCodeArr.includes(strArr[i].charCodeAt(0))) {
      strArr.splice(i, 1);
    }
  }
  return strArr.join('').trim();
};