<template>
  <div>
    <div class="header">
        <img :src="alarmImg" />
        <span>{{ title }}</span>
      <!-- 关闭 -->
      <!-- <span class="exit-icon" title="关闭" @click="onClose">
            <ElIcon name="el-icon-close"></ElIcon>
      </span>-->
    </div>
    <div class="item-body">
      <div class="itemPane" v-for="(item,ind) in message" :key="ind">
        <label class="pointRed">
          <span></span>
        </label>
        <label class="itemTitle">{{ item.name }}</label>
        <label class="itemText">{{ item.value }}</label>
      </div>
    </div>
    <div class="footer">
      <span></span>
      <el-button type="primary" @click="handleEmit('operate')" style="background: #9E0000;border-color: #9E0000;">立即处理</el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    message: {
      type: Array,
      default: [],
    },
    viceTitle: {
      type: String,
      default: "更新内容：",
    },
  },
  data(){
    return {
      alarmImg: require("@/assets/screen/alarm.png")
    }
  },
  methods: {
    handleEmit(type) {
      this.$emit(type);
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  height: 54px;
  background-color: rgba(158, 0, 0, 1);
  span {
    font-size: 22px;
    color: #fff;
  }
  img {
    // height: 30px;
    width: 30px;
    margin: 0 16px 0 22px;
  }
}
.MainPane {
  width: 100%;
  height: auto;
  padding: 0 10px;
  box-sizing: border-box;
}
.itemPane {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #fff;
  & > label {
    margin: 0 6px;
    display: block;
  }
}
.pointRed {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px #9e0000 solid;
  & > span {
    display: block;
    width: 6px;
    height: 6px;
    background: #9e0000;
    border-radius: 50%;
    margin: 2px auto;
  }
}
.itemTitle {
  color: #333333;
  font-weight: bold;
  font-size: 16px;
  line-height: 34px;
  width: 88px;
  text-align: right;
}
.itemText {
  flex: 1;
  line-height: 24px;
}
.footer{
  display: flex;
  justify-content: space-between;
  padding: 12px;
  background-color: #fff;
}
.item-body {
  padding: 20px;
  background-color: #fff;
}
</style>