import { appConfig } from "@/config/appConfig";
import { Message, Loading } from "element-ui";
// DOCS: https://axios-http.com/zh/docs/intro
import Axios from "axios";
import { MIME } from "@/api/MIME";
import { merge } from "lodash";
import store from "@/store";
import router from "@/router";
import { isPlainObject } from "lodash";
import qs from "qs";
import { parseTime } from "@/utils/formatDate";
import { blobType } from "@/config/fileType";

const CancelToken = Axios.CancelToken;

// 存Axios实例
const axiosList = [];
let loadingNum = 0;
let loadingObj = null;

export class HttpHelper {
   // Axios实例
   axios = null;

   baseReuestId = 0;
   cancelMap = new Map();

   constructor(baseURL, config = {}) {
      const axios = Axios.create({
         baseURL,
         // 自定义属性 是否需要登录验证
         ignoreAuth: false,
         ...config,
      });

      axios.interceptors.request.use(
         this.axiosRequest.bind(this),
         this.requestError.bind(this)
      );
      axios.interceptors.response.use(
         this.axiosReponse.bind(this),
         this.responseError.bind(this)
      );

      this.axios = axios;
      axiosList.push(this);
   }

   /**
    * 取消请求
    * 已经响应了的请求, 无效
    * @param {*} requests
    * @param {*} msg
    * @returns
    */
   cancel(requests, msg = "") {
      // 取消所有
      if (!requests) {
         [...this.cancelMap.values()].forEach((cancel) => cancel && cancel(msg));
         return;
      }

      if (!Array.isArray(requests)) {
         requests = [requests];
      }

      // 取消指定请求
      requests.forEach((request) => {
         const cancel = this.cancelMap.get(request.requestId);
         this.cancelMap.delete(request);
         cancel && cancel(msg);
      });
   }

   get(url, config = {}) {
      // 取消请求 function
      let cancel;
      const requestId = this.genReuestId();

      const request = this.axios
         .get(
            url,
            merge(
               {
                  requestId, // 自定义请求ID
                  cancelToken: new CancelToken((c) => (cancel = c)),
               },
               config
            )
         )
         .catch((thrown) => {
            // 请求取消
            if (Axios.isCancel(thrown)) {
               console.warn(thrown.message);
               return;
            }

            throw thrown;
         });

      request.requestId = requestId;
      this.cancelMap.set(requestId, cancel);

      return request;
   }

   post(url, data = {}, config = {}) {
      // 取消请求 function
      let cancel;
      const requestId = this.genReuestId();

      config = merge(
         {
            requestId, // 自定义请求ID
            cancelToken: new CancelToken((c) => (cancel = c)),
            headers: {
               "Content-Type": MIME.FormUrlencoded,
            },
         },
         config
      );

      // 判断是对象&x-www-form-urlencoded
      // Object -> FormData
      if (
         isPlainObject(data) &&
         config.headers["Content-Type"].includes("x-www-form-urlencoded")
      ) {
         data = qs.stringify(data);
      }

      const request = this.axios.post(url, data, config).catch((thrown) => {
         // 请求取消
         if (Axios.isCancel(thrown)) {
            console.warn(thrown.message);
            return;
         }

         throw thrown;
      });

      request.requestId = requestId;
      this.cancelMap.set(requestId, cancel);

      return request;
   }

   // 拦截请求
   axiosRequest(config) {
      // 登录token
      const token = store.state.appToken;
      const BladeAuth = store.state.BladeAuth;
      if (loadingObj == null && config.loading) {
         loadingObj = Loading.service({
            lock: true,
            text: '加载中...',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
         });
      }
      if (config.loading) {
         loadingNum += 1;
      }
      if (config.params == null) {
         config.params = {};
      }
      config.headers.Authorization = 'Basic c2FiZXI6c2FiZXJfc2VjcmV0';

      // 登录验证
      if (!config.ignoreAuth) {
         // 未登录或登录过期
         if (!token) {
            console.log(config);
            throw new Error("未登录或登录过期");
         }

         // TODO: 设置token
         // config.headers.token = token;
         config.params.token = token;
         config.headers.lgact = toUTF8(store.state.userInfo?.name);
         config.headers["Blade-Auth"] = BladeAuth;
      }

      // 设置平台类型
      config.params.platform = appConfig.platformType || "PC";
      console.log(router);
      // config.params.platform = appConfig.platformType || "PC";

      return config;
   }

   // 拦截响应
   axiosReponse(response) {
      const { data, config, headers } = response;
      if (config.loading) {
         loadingNum -= 1;
      }
      if (loadingNum <= 0 && config.loading) {
         loadingObj.close();
         loadingObj = null;
      }
      if (
         response?.config?.responseType === "blob" &&
         headers &&
         headers["content-type"] &&
         headers["content-type"].indexOf(
            "application/vnd.openxmlformats-officedocument") > -1
      ) {
         const blob = new Blob([data], {
            type: blobType[getFilename(headers["content-disposition"]).filetype],
         }); // res就是接口返回的文件流了
         let filename = headers["content-disposition"]
            ? getFilename(headers["content-disposition"]).filename
            : (config.fileName || '文件') + parseTime(new Date(), '{y}-{m}-{d}') + '.xls';
         // const objectUrl = URL.createObjectURL(blob)
         // window.location.href = objectUrl
         const link = document.createElement("a"); // 生成一个a标签。
         link.href = window.URL.createObjectURL(blob); // href属性指定下载链接
         if (filename) {
            link.download = filename;
            // (res.config.fileName || filename) + parseTime(new Date(), '{y}-{m}-{d}'); // dowload属性指定文件名
         }
         link.click(); // click()事件触发下载
      }
      // if (headers && headers['content-type'] && headers['content-type'].indexOf('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') > -1) {
      //   let blob = new Blob([data], {type: "application/vnd.ms-excel"});  // res就是接口返回的文件流了
      //   let objectUrl = URL.createObjectURL(blob);
      //   window.location.href = objectUrl;
      //   // let blob = new Blob([res2.data], { type: res2.data.type });
      //   // const url = window.URL || window.webkitURL || window.moxURL;
      //   // _this.urls = url.createObjectURL(blob);
      //   // const content = data;
      //   // const blob = new Blob([content]); //构造一个blob对象来处理数据
      //   // const fileName = "安全管理.xls";//excel使用 "test.xls"、zip使用test.zip
      //   // if ("download" in document.createElement("a")) {
      //   //   //支持a标签download的浏览器
      //   //   const link = document.createElement("a"); //创建a标签
      //   //   link.download = fileName; //a标签添加属性
      //   //   link.style.display = "none";
      //   //   link.href = URL.createObjectURL(blob);
      //   //   document.body.appendChild(link);
      //   //   link.click(); //执行下载
      //   //   URL.revokeObjectURL(link.href); //释放url
      //   //   document.body.removeChild(link); //释放标签
      //   // } else {
      //   //   //其他浏览器
      //   //   navigator.msSaveBlob(blob, fileName);
      //   // }
      // }
      // TODO: 处理 登录超时, 未登录
      if ([].includes(data.flag)) {
         // 超时, 清空登录token
         store.state.appToken = "";
         cancelAllRequest();
         // TODO: 弹框提示
         return;
      }

      this.cancelMap.delete(config.requestId);

      return data;
   }

   requestError(error) {
      if (error?.response?.config?.loading) {
         loadingNum -= 1;
      }
      if (loadingNum <= 0 && error?.response?.config?.loading) {
         loadingObj.close();
         loadingObj = null;
      }
      console.log(error);
   }

   responseError(error) {
      console.log(error, '22');
   }

   urlParamFormat(data, headers) {
      if (
         isPlainObject(data) &&
         headers &&
         headers["Conent-Type"].includes("x-www-form-urlencoded")
      ) {
         return qs.stringify(data);
      }
      return data;
   }

   genReuestId() {
      return this.baseReuestId++;
   }
}

export class MJRequest {
   // Axios实例
   axios = null;

   baseReuestId = 0;
   cancelMap = new Map();

   constructor(baseURL, config = {}) {
      const axios = Axios.create({
         baseURL,
         // 自定义属性 是否需要登录验证
         ignoreAuth: false,
         ...config,
      });

      axios.interceptors.request.use(
         this.axiosRequest.bind(this),
         this.requestError.bind(this)
      );
      axios.interceptors.response.use(
         this.axiosReponse.bind(this),
         this.responseError.bind(this)
      );

      this.axios = axios;
      axiosList.push(this);
   }

   /**
    * 取消请求
    * 已经响应了的请求, 无效
    * @param {*} requests
    * @param {*} msg
    * @returns
    */
   cancel(requests, msg = "") {
      // 取消所有
      if (!requests) {
         [...this.cancelMap.values()].forEach((cancel) => cancel && cancel(msg));
         return;
      }

      if (!Array.isArray(requests)) {
         requests = [requests];
      }

      // 取消指定请求
      requests.forEach((request) => {
         const cancel = this.cancelMap.get(request.requestId);
         this.cancelMap.delete(request);
         cancel && cancel(msg);
      });
   }

   get(url, config = {}) {
      // 取消请求 function
      let cancel;
      const requestId = this.genReuestId();

      const request = this.axios
         .get(
            url,
            merge(
               {
                  requestId, // 自定义请求ID
                  cancelToken: new CancelToken((c) => (cancel = c)),
               },
               config
            )
         )
         .catch((thrown) => {
            // 请求取消
            if (Axios.isCancel(thrown)) {
               console.warn(thrown.message);
               return;
            }

            throw thrown;
         });

      request.requestId = requestId;
      this.cancelMap.set(requestId, cancel);

      return request;
   }

   post(url, data = {}, config = {}) {
      // 取消请求 function
      let cancel;
      const requestId = this.genReuestId();

      config = merge(
         {
            requestId, // 自定义请求ID
            cancelToken: new CancelToken((c) => (cancel = c)),
            headers: {
               "Content-Type": MIME.FormUrlencoded,
            },
         },
         config
      );
      // 判断是对象&x-www-form-urlencoded
      // Object -> FormData
      if (
         isPlainObject(data) &&
         config.headers["Content-Type"].includes("x-www-form-urlencoded")
      ) {
         data = qs.stringify(data);
      }

      const request = this.axios.post(url, data, config).catch((thrown) => {
         // 请求取消
         if (Axios.isCancel(thrown)) {
            console.warn(thrown.message);
            return;
         }

         throw thrown;
      });

      request.requestId = requestId;
      this.cancelMap.set(requestId, cancel);

      return request;
   }

   // 拦截请求
   axiosRequest(config) {
      // 登录token
      const token = store.state.appToken;
      const BladeAuth = store.state.BladeAuth;

      if (loadingObj == null && config.loading) {
         loadingObj = Loading.service({
            lock: true,
            text: '加载中...',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
         });
      }
      if (config.loading) {
         loadingNum += 1;
      }
      if (config.params == null) {
         config.params = {};
      }
      config.headers.Authorization = 'Basic c2FiZXI6c2FiZXJfc2VjcmV0';
      // 登录验证
      if (!config.ignoreAuth) {
         // 未登录或登录过期
         if (!token) {
            throw new Error("未登录或登录过期");
         }
         // TODO: 设置token
         config.headers.token = token;
         config.headers.userId = store.state.userInfo.userId;
         config.headers.lgact = toUTF8(store.state.userInfo?.name);
         // config.params.token = token;
         config.headers["Blade-Auth"] = BladeAuth;
      }
      // 设置平台类型
      router.app._route.meta.policyType ? config.params.rcs = router.app._route.meta.policyType : '';

      return config;
   }

   // 拦截响应
   axiosReponse(res) {
      const { data, config, headers } = res;

      if (config.loading) {
         loadingNum -= 1;
      }
      if (loadingNum <= 0 && config.loading) {
         loadingObj.close();
         loadingObj = null;
      }
      if (res.config.responseType === "blob") {
         const blob = new Blob([data], {
            type: blobType[getFilename(headers["content-disposition"]).filetype],
         }); // res就是接口返回的文件流了
         let filename = headers["content-disposition"]
            ? getFilename(headers["content-disposition"]).filename
            : (config.fileName || '文件') + parseTime(new Date(), '{y}-{m}-{d}') + '.' + (config.filetype || 'xls');
         // const objectUrl = URL.createObjectURL(blob)
         // window.location.href = objectUrl
         const link = document.createElement("a"); // 生成一个a标签。
         link.href = window.URL.createObjectURL(blob); // href属性指定下载链接
         if (filename) {
            link.download = filename;
            // (res.config.fileName || filename) + parseTime(new Date(), '{y}-{m}-{d}'); // dowload属性指定文件名
         }
         link.click(); // click()事件触发下载
      }
      if (res.config.responseType === "arraybuffer") {
         return Promise.resolve(data);
      }
      let code = Number(res.data.code);
      // Do something with response data
      if (code == "401") {
         // 超时, 清空登录token
         store.state.appToken = "";
         router.push({
            path: "/login",
         });
         cancelAllRequest();
         // TODO: 弹框提示
         Message({
            message: res.data.msg || res.data.message || "操作失败",
            type: "error",
            duration: 5 * 1000,
         });
         return Promise.reject(new Error(res.data.msg || res.data.message || "操作失败"));
      } else if (
         code == "400" &&
         (res.data.msg == "用户名或密码错误" || res.data.msg == "登录失败" || res.data.message == "用户名或密码错误" || res.data.message == "登录失败")
      ) {
         router.push({
            path: "/login",
         });
         // 超时, 清空登录token
         store.state.appToken = "";
         cancelAllRequest();
         // TODO: 弹框提示
         Message({
            message: res.data.msg || res.data.message || "操作失败",
            type: "error",
            duration: 5 * 1000,
         });
         return Promise.reject(new Error(res.data.msg || res.data.message || "操作失败"));
      } else if (code == "401" || code == "500" || code == "400") {
         Message({
            message: res.data.msg || res.data.message || "操作失败",
            type: "error",
            duration: 5 * 1000,
         });
         return Promise.reject(new Error(res.data.msg || res.data.message || "操作失败"));
      } else if (code == "200" || code == "1") {
         this.cancelMap.delete(config.requestId);
         return data;
      } else if (res.data && res.config.responseType === "blob") {
         this.cancelMap.delete(config.requestId);
         return data;
      } else {
         Message({
            message: res.data.msg || res.data.message || "操作失败",
            type: "error",
            duration: 5 * 1000,
         });
         return Promise.reject(new Error(res.data.msg || res.data.message || "操作失败"));
      }
      // TODO: 处理 登录超时, 未登录
      if ([].includes(data.flag)) {
         // 超时, 清空登录token
         store.state.appToken = "";
         cancelAllRequest();
         // TODO: 弹框提示
         return;
      }

      this.cancelMap.delete(config.requestId);

      return data;
   }

   requestError(error) {
      Message({
         message: error,
         type: "error",
         duration: 5 * 1000,
      });
   }

   responseError(error, eee) {
      if (!error.response) {
         loadingNum = 0;
         loadingObj.close();
         loadingObj = null;
      }
      if (error.response?.config?.loading) {
         loadingNum -= 1;
      }
      if (loadingNum <= 0 && error.response?.config?.loading) {
         loadingObj.close();
         loadingObj = null;
      }
      if (error.response?.data?.type == "application/json") {
         var blob = new Blob([error.response.data], {
            type: 'application/vnd.ms-excel'
         });
         //通过FileReader读取数据
         var reader = new FileReader();
         //以下这两种方式我都可以解析出来，因为Blob对象的数据可以按文本或二进制的格式进行读取
         //reader.readAsBinaryString(blob);
         reader.readAsText(blob, 'utf8');
         reader.onload = function () {
            var content = this.result; //这个就是解析出来的数据
            console.log(JSON.parse(content));
            if (
               JSON.parse(content).code == 400 &&
               (JSON.parse(content).msg == "用户名或密码错误" ||
                  JSON.parse(content).msg == "登录失败")
            ) {
               router.push({
                  path: "/login",
               });
               // 超时, 清空登录token
               store.state.appToken = "";
               cancelAllRequest();
            }else if (JSON.parse(content).code == 401) {
               router.push({
                  path: "/login",
               });
               // 超时, 清空登录token
               store.state.appToken = "";
               cancelAllRequest();
            }
            Message({
               message:
                  JSON.parse(content) && JSON.parse(content).msg
                     ? JSON.parse(content).msg
                     : "系统异常",
               type: "error",
               duration: 5 * 1000,
            });
         };
      } else {
         if (
            error.response.data.code == 400 &&
            (error.response.data.msg == "用户名或密码错误" ||
               error.response.data.msg == "登录失败")
         ) {
            router.push({
               path: "/login",
            });
            // 超时, 清空登录token
            store.state.appToken = "";
            cancelAllRequest();
         }else if (error.response.data.code == 401) {
            router.push({
               path: "/login",
            });
            // 超时, 清空登录token
            store.state.appToken = "";
            cancelAllRequest();
         }
         Message({
            message:
               error.response.data && error.response.data.msg
                  ? error.response.data.msg
                  : "系统异常",
            type: "error",
            duration: 5 * 1000,
         });
         return Promise.reject(new Error(error));
      }
      return Promise.reject(new Error(error));
      // if (
      //    error.response.data.code == 400 &&
      //    (error.response.data.msg == "用户名或密码错误" ||
      //       error.response.data.msg == "登录失败")
      // ) {
      //    router.push({
      //       path: "/login",
      //    });
      //    // 超时, 清空登录token
      //    store.state.appToken = "";
      //    cancelAllRequest();
      // }
      // Message({
      //    message:
      //       error.response.data && error.response.data.msg
      //          ? error.response.data.msg
      //          : "系统异常",
      //    type: "error",
      //    duration: 5 * 1000,
      // });
      // return Promise.reject(new Error(error));
   }

   urlParamFormat(data, headers) {
      if (
         isPlainObject(data) &&
         headers &&
         headers["Conent-Type"].includes("x-www-form-urlencoded")
      ) {
         return qs.stringify(data);
      }
      return data;
   }

   genReuestId() {
      return this.baseReuestId++;
   }
}
/**
 * 取消所有Axios实例的所有请求
 */
export function cancelAllRequest() {
   axiosList.forEach((axios) => {
      axios.cancel();
   });
}

export function createMJHttp(baseURL, config = {}) {
   return new MJRequest(baseURL, config);
}

function getFilename(params) {
   let filename = "";
   var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
   var matches = filenameRegex.exec(params);
   if (matches != null && matches[1]) {
      filename = decodeURIComponent(matches[1].replace(/['"]/g, ""));
   }
   return {
      filename: filename.split(".")[0] + parseTime(new Date(), "{y}-{m}-{d}"),
      filetype: filename.split(".")[1],
   };
}


function toUTF8(str) {
   return encodeURIComponent(str).replace(/%(?![\da-fA-F]{2})/g, function(_) {
       // 将非ASCII字符转换为其对应的UTF-8编码
       return '%EF%BF%BD';
   });
 }