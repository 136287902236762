/**
 * 系统中心路由
 */

/**
 * 个人信息
 */
export const PersonalInformation = {
  path: '/PersonalInformation',
  name: 'PersonalInformation',
  component: () => import('@/views/PersonalInformation'),
  meta: {
    label: '个人信息',
    permId: 'M15',
    icon: [
      'icon-gerenxinxi',
      require('@/assets/images/header/个人信息.png'),
    ],
  }
};

/**
 * 安全设置
 */
export const SecuritySettings = {
  path: '/SecuritySettings',
  name: 'SecuritySettings',
  component: () => import('@/views/SecuritySettings'),
  meta: {
    label: '安全设置',
    permId: 'M16',
    icon: [
      'icon-anquanshezhi',
      require('@/assets/images/header/安全设置.png'),
    ],
  }
};


/**
 * 系统设置
 */
export const SystemSettings = {
  path: '/SystemSettings',
  name: 'SystemSettings',
  component: () => import('@/views/SystemSettings'),
  meta: {
    label: '系统配置',
    permId: 'M17',
    icon: [
      'icon-xitongshezhi',
      require('@/assets/images/header/系统设置.png'),
    ],
  }
};


/**
 * 系统管理
 */
export const SystemManagement = {
  path: '/SystemManagement',
  name: 'SystemManagement',
  component: () => import('@/views/SystemManagement'),
  meta: {
    label: '系统管理',
    permId: 'M22',
    icon: [
      require('@/assets/images/systemManagement.png'),
      require('@/assets/images/header/systemHead.png'),
    ],
  }
};
