import Vue from 'vue';
import Main from './main.vue';
import merge from 'element-ui/src/utils/merge';
import { PopupManager } from 'element-ui/src/utils/popup';
import { isVNode } from 'element-ui/src/utils/vdom';
const NotificationConstructor = Vue.extend(Main);

let instance, currentMsg;
let instances = [];

const Notification = async function(options) {
  if (Vue.prototype.$isServer) return;

  options = merge({}, options);

  if (typeof Promise !== 'undefined') {
    const winObj = {}
    winObj.close = function(el) {
      if ( document.body !== el && document.body.contains(el)) {
        instances.splice(instances.findIndex(vall=> vall.$dom === el) ,1)
        document.body.removeChild(el)
      }
    }
    // winObj.operate = function(back){
    //   console.log(back);
    // }
    winObj.Promise = new Promise((resolve, reject) => {
      currentMsg = {
        resolve,
        reject
      };
      instance = new NotificationConstructor({
        data: options
      });
      // instance.operate = winObj.operate;
      instance.callback = defaultCallback;
      if (isVNode(options.message)) {
        instance.$slots.default = [options.message];
        options.message = 'REPLACED_BY_VNODE';
      }
      instance.$mount();
      document.body.appendChild(instance.$el);
      if (instances.length) {
        instances.map(val=> {
          val.dom.style.backgroundColor = 'transparent'
        })
      }
      instance.visible = true;
      instance.dom = instance.$el;
      instance.dom.style.zIndex = PopupManager.nextZIndex();
      instances.push(instance);
    });
    winObj.$el = instance.$el
    
    return winObj
  } else {
    instance = new NotificationConstructor({
      data: options
    });
    instance.callback = defaultCallback;
    if (isVNode(options.message)) {
      instance.$slots.default = [options.message];
      options.message = 'REPLACED_BY_VNODE';
    }
    instance.$mount();
    document.body.appendChild(instance.$el);
    instance.visible = true;
    instance.dom = instance.$el;
    instance.dom.style.zIndex = PopupManager.nextZIndex();
    instances.push(instance);
  }
};
Notification.close = function() {
  // instance.visible = false;
  console.log(instance.$el);
  document.body.removeChild(instance.$el)
}
const defaultCallback = function (params) {
  currentMsg.resolve(params);
}


export default Notification;
