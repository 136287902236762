/*
 * @Date: 2023-11-01 14:07:07
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/**
 * 外接页面展示中心路由
 */

/**
* 项目列表页
*/
// export const projectManagementList = {
//   path: '/projectManagementList',
//   name: 'projectManagementList',
//   component: () => import('@/views/routerProjectManagement/components/ProjectList'),
//   meta: {
//      label: '签约项目',
//      name: 'projectManagementList',
//      permId: 'project-list',
//      // FXJLJK MTJGL
//      icon: require('@/assets/images/navigation/rickProject.png'),
//   }
// };

/**
* 添加项目
*/
export const addProject = {
  path: '/addProject',
  name: 'addProject',
  component: () => import('@/views/routerProjectManagement/components/ProjectList/addProject'),
  meta: {
     label: '添加项目',
     name: 'addProject',
     hideMenu: true,
     permId: 'project-add',
     // FXJLJK MTJGL
     icon: require('@/assets/images/header/零配件检索.png'),
  }
};

/**
* 项目详情
*/
export const projectDetails = {
  path: '/projectDetails',
  name: 'projectDetails',
  component: () => import('@/views/routerProjectManagement/components/ProjectList/projectDetails'),
  meta: {
     label: '项目详情',
     name: 'projectDetails',
     permId: 'project-details',
     hideMenu: true,
     // FXJLJK MTJGL
     icon: require('@/assets/images/header/零配件检索.png'),
  }
};

/**
* 添加子项目
*/
export const addSubProject = {
  path: '/addSubProject',
  name: 'addSubProject',
  component: () => import('@/views/routerProjectManagement/components/ProjectChildrenList/addProject'),
  meta: {
     label: '添加子项目',
     name: 'addSubProject',
     hideMenu: true,
     permId: 'project-sub-add',
     // FXJLJK MTJGL
     icon: require('@/assets/images/header/零配件检索.png'),
  }
};


/**
* 子项目详情
*/
export const SubprojectDetails = {
  path: '/projectChildrenDetails',
  name: 'projectChildrenDetails',
  component: () => import('@/views/routerProjectManagement/components/ProjectChildrenList/projectDetails'),
  meta: {
     label: '子项目详情',
     name: 'SubprojectDetails',
     hideMenu: true,
     permId: 'project-sub-details',
     // FXJLJK MTJGL
     icon: require('@/assets/images/header/零配件检索.png'),
  }
};


/**
* 主项目
*/
export const traditionalProject = {
  path: '/traditionalProject',
  name: 'traditionalProject',
  component: () => import('@/views/routerProjectManagement/components/traditionalProject/index'),
  meta: {
     label: '项目管理',
     name: 'traditionalProject',
     permId: 'traditionalProject',
     redirect: true,
     // FXJLJK MTJGL
     icon: require('@/assets/images/navigation/traditionalProject.png'),
  }
};