//咨询管理
export const consulting = {
  path: "/consulting",
  name: "consulting",
  component: () =>
    import("@/views/safeDutyInsuredManagement/traffic/consulting.vue"),
  meta: {
    label: "资讯管理",
    permId: "M20-sd-index",
    icon: require("@/assets/images/safeDuty/menu/交通安责.png"),
  },
};
//预约管理
export const appointment = {
  path: "/appointment",
  name: "appointment",
  component: () =>
    import("@/views/safeDutyInsuredManagement/traffic/appointment.vue"),
  meta: {
    label: "预约管理",
    permId: "M20-sd-index",
    icon: require("@/assets/images/safeDuty/menu/交通安责.png"),
  },
};
//注册企业
export const enterpriseInformation = {
  path: "/enterpriseInformation",
  name: "enterpriseInformation",
  component: () =>
    import(
      "@/views/safeDutyInsuredManagement/traffic/enterpriseInformation.vue"
    ),
  meta: {
    label: "注册企业",
    permId: "M20-sd-index",
    icon: require("@/assets/images/safeDuty/menu/交通安责.png"),
  },
};
