/**
 * 监控中心路由
 */

/**
 * 实时定位
 */
export const RealTimePositioning = {
   path: '/RealTimePositioning',
   name: 'RealTimePositioning',
   component: () => import('@/views/RealTimePositioning'),
   meta: {
      label: '设备管理',
      permId: 'M01',
      icon: [
         'icon-shishidingwei',
         require('@/assets/images/header/实时定位.png')
      ],
      affix: true,
   }
};
/**
 * 商车详情
 */
export const RealTimePositioningOld = {
   path: '/RealTimePositioningOld',
   name: 'RealTimePositioningOld',
   component: () => import('@/views/RealTimePositioning/RealTimePositioningOld'),
   meta: {
      label: '22222',
      permId: 'M01',
      icon: [
         'icon-shishidingwei',
         require('@/assets/images/header/实时定位.png')
      ],
      hideMenu: true,
      affix: false,
   }
};
/**
 * 商车详情
 */
export const SCDetails = {
   path: '/SCDetails',
   name: 'SCDetails',
   component: () => import('@/views/RealTimePositioning/SCDetails'),
   meta: {
      label: '商车详情',
      permId: 'M01',
      icon: [
         'icon-shishidingwei',
         require('@/assets/images/header/实时定位.png')
      ],
      hideMenu: true,
      affix: false,
   }
};

/**
 * 轨迹回放
 */
export const TrackPlayback = {
   path: '/TrackPlayback',
   name: 'TrackPlayback',
   component: () => import('@/views/TrackPlayback'),
   meta: {
      label: '轨迹回放',
      permId: 'M02',
      icon: [
         'icon-guijihuifang',
         require('@/assets/images/header/轨迹回放.png'),
      ],
      // affix: true,
   }
};


/**
 * 多车轨迹
 */
export const MultiVehicleTrack = {
   path: '/MultiVehicleTrack',
   name: 'MultiVehicleTrack',
   component: () => import('@/views/MultiVehicleTrack'),
   meta: {
      label: '多车轨迹',
      permId: 'M03',
      icon: [
         'icon-duocheguiji',
         require('@/assets/images/header/多车轨迹.png')
      ],
   }
};

/**
 * 分组监控
 */
export const GroupMonitoring = {
   path: '/GroupMonitoring',
   name: 'GroupMonitoring',
   component: () => import('@/views/GroupMonitoring'),
   meta: {
      label: '分组监控',
      permId: 'M04',
      icon: [
         'icon-fenzujiankong',
         require('@/assets/images/header/分组监控.png')
      ],
   }
};


/**
 * 区域监控
 */
export const AreaMonitoring = {
   path: '/AreaMonitoring',
   name: 'AreaMonitoring',
   component: () => import('@/views/AreaMonitoring'),
   meta: {
      label: '区域监控',
      permId: 'M05',
      icon: [
         'icon-quyujiankong',
         require('@/assets/images/header/区域监控.png')
      ],
   }
};


/**
 * 实时视频
 */
export const RealTimeVideo = {
   path: '/RealTimeVideo',
   name: 'RealTimeVideo',
   component: () => import('@/views/RealTimeVideo'),
   meta: {
      label: '实时视频',
      permId: 'M06',
      icon: [
         'icon-shishishipin-daohang',
         require('@/assets/images/header/实时视频.png'),
      ],
      // affix: true,
   }
};


/**
 * 视频轮询
 */
export const VideoPolling = {
   path: '/VideoPolling',
   name: 'VideoPolling',
   component: () => import('@/views/VideoPolling'),
   meta: {
      label: '视频轮询',
      permId: 'M07',
      icon: [
         'icon-shipinlunxun',
         require('@/assets/images/header/视频轮询.png')
      ],
   }
};


/**
 * 电子围栏
 */
export const ElectronicFence = {
   path: '/ElectronicFence',
   name: 'ElectronicFence',
   component: () => import('@/views/ElectronicFence'),
   meta: {
      label: '电子围栏',
      permId: 'M08',
      icon: [
         'icon-dianziweilan',
         require('@/assets/images/header/电子围栏.png'),
      ],
   }
};


/**
 * 安全监控
 */
export const SecurityMonitoring = {
   path: '/SecurityMonitoring',
   name: 'SecurityMonitoring',
   component: () => import('@/views/SecurityMonitoring'),
   meta: {
      label: '安全监控',
      permId: 'M09',
      icon: [
         'icon-anquanjiankong',
         require('@/assets/images/header/安全监控.png')
      ],
   }
};


/**
 * 录像回放
 */
export const VideoPlayback = {
   path: '/VideoPlayback',
   name: 'VideoPlayback',
   component: () => import('@/views/VideoPlayback'),
   meta: {
      label: '录像回放',
      permId: 'M10',
      icon: [
         'icon-luxianghuifang',
         require('@/assets/images/header/录像回放.png'),
      ],
   }
};

/**
 * 调度系统
 */
export const DispatchingManagement = {
   path: '/DispatchingManagement',
   name: 'DispatchingManagement',
   component: () => import('@/views/ExternalManagement/DispatchingManagement'),
   meta: {
      label: '调度系统',
      permId: 'DDXT',
      icon: [
         require('@/assets/images/header/dispatching.png'),
         require('@/assets/images/header/dispatching.png')
      ],
      affix: false,
   }
};
/**
 * 排队系统
 */
export const lineUpManagement = {
   path: '/lineUpManagement',
   name: 'lineUpManagement',
   component: () => import('@/views/ExternalManagement/lineUpManagement'),
   meta: {
      label: '排队系统',
      permId: 'PDXT',
      icon: [
         require('@/assets/images/header/lineUp.png'),
         require('@/assets/images/header/lineUp.png')
      ],
      affix: false,
   }
};