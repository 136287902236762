import store from "@/store";
import { hasPerms } from "@/utils/auth";
import watermark from "watermark-package";
import { Message } from "element-ui";

const LOGIN_PATH = "/login";

/** 权限守卫，这个判断页面权限 */
export function setupPermissionGuard(router) {
  router.beforeEach(async (to, from, next) => {
    const { state, commit, dispatch } = store;
    // TODO: 404 的处理

    // 不需要登录,可访问
    if (to.meta.ignoreAuth === true) {
      next();
      return;
    }

    // TODO: 白名单

    // 刷新重新登录
    if (!state.appToken) {
      await dispatch("relogin");
    }

    // 处理token
    const hasToken = !!state.appToken;

    if (!hasToken) {
      // 未登陆，或过期
      // 重定向到登录页
      const redirectData = {
        path: LOGIN_PATH,
        replace: true,
      };

      next(redirectData);
      return;
    }
    // 判断是否有权限
    const hasAuthority = to.meta.permId && hasPerms(to.meta.permId);

    if (hasAuthority) {
      // 权限验证
      // if (to.meta.ignoreKeepAlive !== true) {
      //   // 页面需要缓存, 添加到缓存
      //   const { cachePages } = state;
      //   const { matched } = to;
      //   commit("setCachePages", [...cachePages, ...matched.slice(1)]);
      //   // console.log(cachePages, matched.slice(1), cachePages.push(...matched.slice(1)));
      //   // commit("setCachePages", JSON.parse(JSON.stringify(cachePages.push(...matched.slice(1)))));
      // }
      //中国石油判断
      if (
        state.userRouter?.children?.find((r) => r.code === "zgsy") &&
        state.userInfo.name != "广东一立科技" &&
        to.name == "BigScreenDisplay"
      ) {
        // console.log(state.userInfo, 111111);
        next("BigScreenDisplayEquipment");
        return false;
      }
      next();
      return;
    } else {
      console.log(to);
      console.warn(`没有***${to.meta.label}***页面权限！`);
      if (to.meta.hideMenu) {
        Message({
          message: `没有${to.meta.label}页面权限！`,
          type: "warning",
        });
      }
      next(from.path);
    }

    // next();
  });

  router.afterEach((to) => {
    // 水印处理
    if (
      to.path.includes("routerClaimManagement") ||
      to.path.includes("routerPolicyManagement") ||
      to.path.includes("routerCalculateManagement") ||
      to.path.includes("SystemManagement")
    ) {
      watermark.setWaterMark({
        w_texts: [store.state.userInfo.name],
        w_options: {
          w_font: "18px",
          w_opacity: "0.4",
        },
      });
    } else {
      watermark.removeWatermark();
    }
    if (to.meta?.label) {
      // 设置页面`title`
      document.title = `一立科技 - ${to.meta.label}`;
    }
  });
}
