/**
 * 默认头部导航路由项
 */

// 导入固定Route
import {
   RealTimePositioning, // 实时定位
   TrackPlayback, // 轨迹回放
   RealTimeVideo, // 实时视频
   VideoPlayback, // 录像回放
   ElectronicFence, // 电子围栏


   MultiVehicleTrack,
   GroupMonitoring,
   AreaMonitoring,
   VideoPolling,
   SecurityMonitoring,
} from '@/router/modules/MonitoringCenter';
import { claimManagement, policyManagement, securityMonitoring, PartsManagement, ProjectManagement, PerformanceManagement } from "@/router/modules/ManagementCenter";

import { BigScreenDisplay } from '@/router/modules/BigDataCenter';
import { HangingManagement, DispatchingManagementXY } from '@/router/modules/ExternalManagement';
import { riskHomeManagement } from '@/router/modules/RiskDecrement';
import { riskControlManagement } from '@/router/modules/RiskControlDecrement';
import { partsHomeManagement } from '@/router/modules/PartsRetrieval';
import { ReportQuery, workManagement, oilManagements } from '@/router/modules/QueryCenter';

export const defaultHeaderMenus = [
   BigScreenDisplay,//大屏展示
   RealTimePositioning, // 实时定位
   securityMonitoring,//安全管理
   policyManagement,//保单管理
   claimManagement,//案件管理
   HangingManagement, //塔机管理系统
   DispatchingManagementXY, //调度系统
   riskHomeManagement, //风险减量监控
   riskControlManagement, // 风控管理
   PartsManagement, //零配件检索
   // ProjectManagement, //项目管理
   // PerformanceManagement, //绩效管理
   // TrackPlayback, // 轨迹回放
   // ElectronicFence, // 电子围栏
   // RealTimeVideo, // 实时视频
   //   workManagement,//工时管理
   //   oilManagements,//油耗管理
   //   VideoPlayback, //录像回放
   //ReportQuery, // 报表查询

   // MultiVehicleTrack,
   // GroupMonitoring,
   // AreaMonitoring,
   // VideoPolling,
   // SecurityMonitoring,
];