/**
 * 处理监控中心, 子路由
 */

import { addSubRoutes } from '@/router/helper';
import * as SecurityChildren from '../modules/SecurityMonitoring';


/**
 * 
 * @param {}} MonitoringCenter 监控中心子路由
 */
export default function handle(MonitoringCenter) {
  // 给安全监控添加子路由
  addSubRoutes(MonitoringCenter.SecurityMonitoring, Object.values(SecurityChildren));
}