<template>
  <div class="win-box">
    <div class="main">
      <div class="title">{{ title }}</div>
      <div class="vice_title">{{ viceTitle }}</div>
      <div class="massage" v-for="(item,index) in message" :key="index">{{ index + 1 }}. {{ item }}</div>
    </div>
    <div class="footer">
      <el-button @click="handleEmit('cancel')" type="primary" plain
        >关闭</el-button
      >
      <el-button type="primary" @click="handleEmit('save')">我知道了</el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    message: {
      type: Array,
      default: [],
    },
    viceTitle: {
      type: String,
      default: "更新内容：",
    },
  },
  methods: {
    handleEmit(type) {
      this.$emit(type);
    },
  },
};
</script>

<style lang="scss" scoped>
.win-box {
  background-color: #fff;
  .main {
    width: 540px;
    // height: 335px;
    background-image: url("../../../assets/screen/version.png");
    background-repeat: no-repeat;
    background-size: 540px 335px;
    padding: 24px;
    padding-bottom: 0;
    .title {
      color: #ffffff;
      font-size: 24px;
      margin-bottom: 18px;
    }
    .vice_title {
      font-size: 20px;
      color: #333333;
      padding: 26px 0;
    }
    .massage {
      font-size: 16px;
      color: #333333;
      margin-bottom: 18px;
    }
  }
  .footer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 80px;
    margin: 0 140px;
  }
}
</style>